import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./boltmanagement.scss"
import ReactPaginate from "react-paginate";

import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
const Boltmanagement = () => {
    const token = localStorage.getItem('mytoken')
    const [open, setOpen] = useState(false);
    const [myFiles, setMyFiles] = useState([]);

         // pagination ============

         const [limit] = useState(10);
         const [page, setPage] = useState(0);
         const [pageCount, setPageCount] = useState([]);
       
         const handlePageChange = (e) => {
           const selectedPage = e.selected;
           setPage(selectedPage);
         };
       
         // pagination ============

    const getXblue = () => {
        setOpen(true);
        axios.get(
            Environment.backendUrl + `/api1/admin/get/AllXblue?limit=${limit}&offset=${page*limit}`, 
            { headers: { "Authorization": `Bearer ${token}` } }
        )
        .then((response) => {
            setOpen(false);
            setMyFiles(response?.data?.data);
            setPageCount(response?.data?.count);
       

        })
        .catch((err) => {
            setOpen(false);
            toast.error(err.response?.data.msg, {
                position: "top-center",
                autoClose: 2000,
            });
        });
    };
    
     

    useEffect(() => {
        getXblue()
    }, [page])


    return (
        <>
          

            <div className="content">
            <div className="maintableauser warrrrty">
            <section className="boltmanagement">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Reward GoldVerified</th>
                                        <th>Reward Verified</th>

                                    <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                             
                                {myFiles?.map((item, index) => {
                            return (
                              <>
                                            <tr>
                                                <td>
                                                    <p className='parara eleipiess' > {item?.description}</p>
                                                </td>
                                                <td>
                                                {item?.xblueRewardGold} Bolts
                                                </td>
                                                
                                                <td>
                                                {item?.xblueRewardVerified} Bolts
                                                </td>
                                            <td>
                                                    <Link to={"/admin/addboltcategory?id=" + item?.id}>
                                                        <img  src="\dashboard-assets\edit.svg" alt="editimg" className="edit" />
                                                    </Link>
                                                </td>
                                             
                                                {/* bolt edit modal */}
                                                {/* <div class="modal fade modal-zzz" id={`boltEditMOdal${item?.id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered daily-profile-modal">
                                                        <div class="modal-content  daily-profile-modal-inner">
                                                            <div class="modal-body modal-body-main">
                                                                <div className="main-outter text-center">
                                                                    <div className="row main-cardssss">
                                                                        <div className="col-md-12 col-12 boltcategories boltcategoriesModal">
                                                                            <div className="flux-b pt-3">
                                                                                <h3>Edit Your Bolt Reward</h3>
                                                                            </div>
                                                                            <p className='text-left mt-5'>Description</p>
                                                                            <input type="text" readOnly value={item?.description} onChange={(e) => setTask(e.target.value)} placeholder='Verified Referral' />
                                                                            <p className='text-left'>Bolts Reward</p>
                                                                            <input type="number" value={bolt ? bolt : item?.boltReward } onChange={(e) => parseFloat(e.target.value) > 0 ? setBolt(e.target.value) : setBolt(item?.boltReward)} placeholder='10' />

                                                                        </div>

                                                                        <div className="col-md-12 col-12 ptb20">
                                                                            <div className="button-modal-daily button-modal-daily55">
                                                                                window.$('#boltEditMOdal').modal('hide')
                                                                                <button type="button" className="button-main-daily " onClick={() => { addBolt();window.$(`#boltEditMOdal${item?.id}`).modal('hide')  }} >Edit</button>
                                                                                <button type="button" className="button-main-dailys" data-dismiss="modal" aria-label="Close"  >Cancel</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </tr>
                                            </>
                            );
                          })}

                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''}
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
                </section>
            </div>
               
            </div>
        </>
    )
}

export default Boltmanagement