import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import "./faq.scss";
// reactstrap components
function Faq() {
  return (
    <>
      <div className="content">
        <section className="videos faq notifications card">
          <div className="container-fluid">
            <div class="table-responsive">
              <table class="table ">
                <thead>
                  <tr>
                    <th>Questions</th>
                    <th>Answers</th>
                    <th>Date</th>
                    <th>Edit</th>
                    <th>
                    Remove
                    </th>
                  </tr>
                </thead>
                <tbody className="main-t-body-text">
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <Link to={"/admin/AddFaq/"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4_30609)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                              fill="#D6D6D6"
                            />
                            <path
                              d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                              fill="#D6D6D6"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4_30609">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <Link to={"/admin/AddFaq/"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4_30609)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                              fill="#D6D6D6"
                            />
                            <path
                              d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                              fill="#D6D6D6"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4_30609">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <Link to={"/admin/AddFaq/"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4_30609)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                              fill="#D6D6D6"
                            />
                            <path
                              d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                              fill="#D6D6D6"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4_30609">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4_30609)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4_30609">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4_30609)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4_30609">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4_30609)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4_30609">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4_30609)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4_30609">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td className="passs2 bbbhyyyy">
                      Lorem ipsum dolor sit amet...
                    </td>
                    <td className="passs1">Share Our Post About Synergy</td>
                    <td className="passs1">25-Oct-2021</td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_4_30609)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4_30609">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M17.7526 6.14014H4.24964C3.84102 6.14014 3.50977 6.47136 3.50977 6.87994V18.4375C3.50977 20.4019 5.10803 22.0001 7.07236 22.0001H14.9296C16.894 22.0001 18.4922 20.4019 18.4922 18.4375V6.87994C18.4923 6.47139 18.1612 6.14014 17.7526 6.14014ZM9.22253 17.5442C9.22253 18.0461 8.81561 18.4531 8.31356 18.4531C7.81169 18.4531 7.40459 18.0461 7.40459 17.5442V10.5961C7.40459 10.0941 7.81162 9.68717 8.31356 9.68717C8.81557 9.68717 9.22253 10.0941 9.22253 10.5961V17.5442ZM14.5975 17.5442C14.5975 18.0461 14.1905 18.4531 13.6885 18.4531C13.1866 18.4531 12.7795 18.0461 12.7795 17.5442V10.5961C12.7795 10.0941 13.1866 9.68717 13.6885 9.68717C14.1905 9.68717 14.5975 10.0941 14.5975 10.5961V17.5442Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0106 1.15112H13.7878V0.871624C13.7878 0.391003 13.3969 0 12.9163 0H9.08457C8.60395 0 8.21295 0.391003 8.21295 0.871624V1.15112H4.99009C3.75601 1.15112 2.75195 2.1551 2.75195 3.38922V4.8244C2.75195 5.08107 2.96002 5.28917 3.21679 5.28917H18.7841C19.0407 5.28917 19.2488 5.08107 19.2488 4.8244V3.38922C19.2488 2.15514 18.2448 1.15112 17.0106 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <ToastContainer style={{ fontSize: 20 }} /> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Faq;
