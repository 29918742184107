import "./raffles.scss";
import React, { useState } from "react";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Environment from "utils/Environment";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Category from "views/userflow/ListitemArray";
const AddCategoryRaffle = () => {
  return (
    <>
      <div className="content raflesdivvv">
        <div className="addraffles">
          <div className="option-field">
            <label>Category</label>
            <input className='inputyyyy' type="text" placeholder='Category' />
            
          </div>
          <div className="option-field mb-0">
            <label>Total Items</label>
            <input className='inputyyyy' type="text" placeholder='Total Items' />
           
          </div>
          <div className="bottom-bttttn">
            <button
          
              className="red-bybuy"
            >
              Publish
            </button>
            <button
           
              className="red-wy-y"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryRaffle;
