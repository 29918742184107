import { Backdrop, CircularProgress } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactPaginate from "react-paginate";
import { Modal } from "react-bootstrap";
import Environment from 'utils/Environment'
import moment from "moment/moment";
import './newcategory.scss'

const Newcategory = () => {
    const token = localStorage.getItem('mytoken')
    const [open, setOpen] = useState(false);
    const [myFiles, setMyFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

           // pagination ============

  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  // pagination ============

    const getCategory = () => {
        setOpen(true);
        axios.get(
            Environment.backendUrl + `/api1/admin/all/productCategory?limit=${limit}&offset=${page*limit}`,
            { headers: { "Authorization": `Bearer ${token}` } }
        )
            .then((response) => {
                setOpen(false);
                setMyFiles(response?.data?.productCategory);
                setPageCount(response?.data?.count);

            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };



    useEffect(() => {
        getCategory()
    }, [page])

    const deleteTask = () => {
        axios
          .get(
            Environment.backendUrl + `/api1/admin/delete/productCategory?id=${deleteId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            toast.success("Category Deleted Successfully!");
            handleClose();
            getCategory();
          })
          .catch((err) => {
            setOpen(false);
            toast.error(err.response?.data.msg, {
              position: "top-center",
              autoClose: 2000,
            });
          });
      };


    return (
        <>
            <div className="content">
            <div className="maintableauser warrrrty">
                <section className="products myproductss">
                    <div className="productstable">
                        <table>
                            <thead className='trrrdata'>
                                <th className='headoftabb'>Category</th>
                                <th className='headoftabb'>Creation Date</th>
                                <th className='headoftabb'>Total Products</th>
                                <th className='headoftabb'>Actions</th>
                            </thead>
                            <tbody>
                                {myFiles?.map((item, index) => {
                                    return (
                                        <>
                                            <tr  className='trrrdata '>
                                                <td>
                                                    <p className="namedivvv eleipiess">{item?.categoryName}</p>
                                                </td>
                                                <td>
                                                    <p className="namedivvv">                   {moment(item?.createdAt).format(
                                                        "DD-MMM-YYYY"
                                                    )}</p>
                                                </td>
                                                <td>
                                                    <p className="namedivvv"> {item?.totalProducts} Products</p>
                                                </td>
                                                <td>
                                                    <Link to={`/admin/addnewcategory/:id=` + item?.id}>
                                                        <button className="bluebtnnnbyn">Edit</button>
                                                    </Link>
                                                    <span
                                            className="trash"
                                            onClick={() => {
                                              handleShow();
                                              setDeleteId(item?.id);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.7506 6.13965H4.24769C3.83907 6.13965 3.50781 6.47087 3.50781 6.87945V18.437C3.50781 20.4014 5.10608 21.9996 7.07041 21.9996H14.9276C16.892 21.9996 18.4903 20.4014 18.4903 18.437V6.87945C18.4903 6.47091 18.1592 6.13965 17.7506 6.13965ZM9.22058 17.5437C9.22058 18.0456 8.81365 18.4526 8.31161 18.4526C7.80974 18.4526 7.40263 18.0456 7.40263 17.5437V10.5956C7.40263 10.0936 7.80967 9.68668 8.31161 9.68668C8.81362 9.68668 9.22058 10.0936 9.22058 10.5956V17.5437ZM14.5955 17.5437C14.5955 18.0456 14.1886 18.4526 13.6865 18.4526C13.1847 18.4526 12.7776 18.0456 12.7776 17.5437V10.5956C12.7776 10.0936 13.1846 9.68668 13.6865 9.68668C14.1885 9.68668 14.5955 10.0936 14.5955 10.5956V17.5437Z"
                                                fill="#D6D6D6"
                                              />
                                              <path
                                                d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                                                fill="#D6D6D6"
                                              />
                                            </svg>
                                          </span>
                                                </td>

                                            </tr>
                                        </>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''}
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
                </section>
                </div>
                <Modal
          className="ambmodalmain"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              delete Task
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z"
                  fill="white"
                />
              </svg>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mod_bodydivxxs my-3 maydivmod">
              <img src="\dashboard-assets\bin.png" />
              <p className="para4489">
                Are you sure you want to delete this Category?
              </p>
              {/* <p className="para44800">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto
              </p> */}

              <div className="videopagerewards pt-5 myyybutmod">
                <button onClick={handleClose} className="red-bnewnyyy">
                  Cancel
                </button>
                <button onClick={deleteTask} className="red-wnewwnyy">
                  Yes’ I am sure
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
            </div>
        </>
    )
}

export default Newcategory
