import { Backdrop, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Environment from "utils/Environment";
import "./addnewcategory.scss";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Addnewcategorry = (props) => {
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const location = useLocation();
  const [currentId, setCurrentId] = useState(null);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const idParam = pathParts[pathParts.length - 1];
    const idValue = idParam.split("=")[1];

    if (idValue) {
      setCurrentId(idValue);
      localStorage.setItem("currentId", idValue);
      getTaskDetail(idValue);
    }
  }, [location]);

  const getTaskDetail = (idValue) => {
    axios
      .get(
        Environment.backendUrl +
          `/api1/admin/find/productCategorybyId?id=${idValue}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setName(response?.data?.productCategory?.categoryName);
        setAmount(response?.data?.productCategory?.totalProducts);
      })
      .catch((err) => {
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const editCategory = async (e) => {
    e.preventDefault();
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/edit/productCategory`,
      data: {
        categoryName: name,
        totalProducts: amount,
        id: currentId,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/newcategory");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };

  const addCategory = async (e) => {
    e.preventDefault();
    if (!name || !amount) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/add/productCategory`,
      data: {
        categoryName: name,
        totalProducts: amount,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/newcategory");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };
  return (
    <>
      <div className="content">
        <section className="addnewcategory">
          <div className="row">
            <div className="col-lg-8">
              <p>Category</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Name"
              />
              <p>Total Products</p>
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                placeholder="Amount"
              />
              {/* <p className="notetextt">Note: if no limit keep space empty
              </p> */}
              <div className="btnslasts">
                <Link to={"/admin/newcategory"}>
                  <button className="red-wy-y">Cancel</button>
                </Link>

                <button
                  onClick={
                    currentId ? (e) => editCategory(e) : (e) => addCategory(e)
                  }
                  className="red-bybuy"
                >
                  {currentId ? "Update" : "Publish"}
                </button>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Addnewcategorry;
