import React, { useEffect, useState } from "react";
// import './banner.scss';
// reactstrap components
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
function CreateFaq(props) {
  return (
    <>
      <div className="content">
        <section className="addbanner1234 send-notifications card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-lg-7">
                  <div className="row">
                    <div className="col-12 main-send-message">
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Question
                        </label>
                        <p>
                        <input
                          type="text"
                          name="question"
                          class="form-controlbbb"
                          id="exampleFormControlTextarea1"
                          placeholder="Enter The  Question"
                          rows="2"
                        ></input>
                        </p>
                    
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 main-send-message">
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Answer
                        </label>
                        <p>
                          {" "}
                          <input
                            type="text"
                            name="answer"
                            class="form-controllll"
                            id="exampleFormControlTextarea1"
                            placeholder="Enter The Answer"
                            rows="4"
                          ></input>
                        </p>
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div class="form-group">
                      <div className="videopage">
                        <button className="red-bybuy">Publish</button>
                        {/* <button className="red-b" onClick={FAQ}>Publish</button> */}
                        <Link to="/admin/Faq">
                          <button className="red-wy-y">Cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}

export default CreateFaq;
