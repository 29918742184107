import React, { useEffect, useState } from "react";
import "./rewardlea.scss";
import axios from "axios";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
// reactstrap components
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import ReactPaginate from "react-paginate";
function Task() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeTab, setActiveTab] = useState("MainTask");
  const token = localStorage.getItem("mytoken");
  const [deleteId, setDeleteId] = useState(null);

  // pagination ============

  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  // pagination ============
  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [activeTab1, setActiveTab1] = useState("true");

  const handleSelect1 = (eventKey) => {
    setActiveTab1(eventKey);
  };
  const [activeTab11, setActiveTab11] = useState("link-1");

  const handleSelect11 = (eventKey) => {
    setActiveTab11(eventKey);
  };
  const [activeTabiner, setActiveTabiner] = useState("link-1");

  const handleSelectiner = (eventKey) => {
    setActiveTabiner(eventKey);
  };

  const deleteTask = () => {
    axios
      .get(
        Environment.backendUrl + `/api1/admin/deactive/task?id=${deleteId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        toast.success("Task Ended Successfully!");
        handleClose();
        getTask();
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const getTask = () => {
    setOpen(true);
    axios
      .get(
        Environment.backendUrl +
        `/api1/admin/get/tasks?limit=${limit}&offset=${page*limit}&taskType=${activeTab}&published=${activeTab1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setOpen(false);
        setMyFiles(response?.data?.task);
        setPageCount(response?.data?.count);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getTask();
  }, [activeTab, activeTab1,page]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="content">
        <section className="main-tasks user-details maintablea_leader mysectttt">
          <div className="maintablepillsty">
            <Nav
              variant="pills"
              activeKey={activeTab}
              onSelect={handleSelect}
              className="amberpillsouter_leader"
            >
              <Nav.Item className="amberitempils">
                <Nav.Link className="ineramb" eventKey="MainTask">
                  Main Tasks
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="amberitempils">
                <Nav.Link className="ineramb" eventKey="DailyTask">
                  Daily Tasks
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          {activeTab === "MainTask" && (
            <>
              <>
                <div className="lowertabssst classsloww">
                  <Nav
                    variant="pills"
                    activeKey={activeTab1}
                    onSelect={handleSelect1}
                    className="amberpillsoutersssyyyt  newtabsstyl"
                  >
                    <Nav.Item className="amberitempilsss  inertabtask">
                      <Nav.Link className="inerambss" eventKey="true">
                        Live
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="amberitempilsss inertabtask">
                      <Nav.Link className="inerambss" eventKey="false">
                        Ended
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                {activeTab1 === "true" && (
                  <>
                    <div className="maintablea_task">
                      <div className="maintableauser">
                        <div className="innertable">
                          <table className="tabbbbble">
                            <thead>
                              <th>Tasks Image</th>
                              <th> Title / Description </th>
                              <th>Task Completion</th>
                              <th>Rewards</th>
                              <th>Date Started </th>
                              <th>Date Ended </th>
                              <th>Action </th>
                            </thead>
                            <tbody>
                              {myFiles?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="inerimgddd_task_table">
                                          <Link to={"/admin/Adddstasks?id=" + item?.id}>
                                            <img
                                              src={item?.image}
                                              className="taskimg"
                                            ></img>
                                          </Link>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="taskinertablesecrow">
                                          <h6 className="boldstory eleipiess ">
                                            {item?.name}
                                          </h6>
                                          <p className="Synergy eleipiess">
                                            {item?.description}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="eleipiess">
                                          {item?.XBlueReward?.xblueRewardGold}
                                        </span>
                                      </td>
                                      <td>
                                        {item?.XBlueReward?.xblueRewardVerified}
                                      </td>
                                      <td>
                                        {moment(item?.createdAt).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(item?.updatedAt).format(
                                          "DD-MMM-YYYY"
                                        )}{" "}
                                      </td>
                                      <td>
                                        <div className="tasktablelast">
                                          <Link
                                            to={"/admin/addtask?id=" + item?.id}
                                          >
                                            <span className="edit">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_277_135658)">
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                                    fill="#D6D6D6"
                                                  />
                                                  <path
                                                    d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                                    fill="#D6D6D6"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_277_135658">
                                                    <rect
                                                      width="22"
                                                      height="22"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </span>
                                          </Link>
                                          <span
                                            className="trash"
                                            onClick={() => {
                                              handleShow();
                                              setDeleteId(item?.id);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.7506 6.13965H4.24769C3.83907 6.13965 3.50781 6.47087 3.50781 6.87945V18.437C3.50781 20.4014 5.10608 21.9996 7.07041 21.9996H14.9276C16.892 21.9996 18.4903 20.4014 18.4903 18.437V6.87945C18.4903 6.47091 18.1592 6.13965 17.7506 6.13965ZM9.22058 17.5437C9.22058 18.0456 8.81365 18.4526 8.31161 18.4526C7.80974 18.4526 7.40263 18.0456 7.40263 17.5437V10.5956C7.40263 10.0936 7.80967 9.68668 8.31161 9.68668C8.81362 9.68668 9.22058 10.0936 9.22058 10.5956V17.5437ZM14.5955 17.5437C14.5955 18.0456 14.1886 18.4526 13.6865 18.4526C13.1847 18.4526 12.7776 18.0456 12.7776 17.5437V10.5956C12.7776 10.0936 13.1846 9.68668 13.6865 9.68668C14.1885 9.68668 14.5955 10.0936 14.5955 10.5956V17.5437Z"
                                                fill="#D6D6D6"
                                              />
                                              <path
                                                d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                                                fill="#D6D6D6"
                                              />
                                            </svg>
                                          </span>

                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className='Paginationlattable'>
                          {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
                          {page >= 0 ?
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={Math.ceil(pageCount / limit)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              forcePage={page}
                            />
                            : ''}
                          {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
                        </div>
                      </div>

                    </div>
                  </>
                )}
                {activeTab1 === "false" && (
                  <>
                    <div className="maintablea_task">
                      <div className="maintableauser">
                        <div className="innertable">
                          <table>
                            <thead>
                              <th>Tasks Image</th>
                              <th> Title / Description </th>
                              <th>Task Completion</th>
                              <th>Rewards</th>
                              <th>Date Started </th>
                              <th>Date Ended </th>
                              <th>Action </th>
                            </thead>
                            <tbody>
                              {myFiles?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="inerimgddd_task_table">
                                          <Link to={"/admin/Adddstasks?id=" + item?.id}>
                                            <img
                                              src={item?.image}
                                              className="taskimg"
                                            ></img>
                                          </Link>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="taskinertablesecrow">
                                          <h6 className="boldstory eleipiess ">
                                            {item?.name}
                                          </h6>
                                          <p className="Synergy eleipiess">
                                            {item?.description}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="eleipiess">
                                          {item?.XBlueReward?.xblueRewardGold}
                                        </span>
                                      </td>
                                      <td>
                                        {item?.XBlueReward?.xblueRewardVerified}
                                      </td>
                                      <td>
                                        {moment(item?.createdAt).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(item?.updatedAt).format(
                                          "DD-MMM-YYYY"
                                        )}{" "}
                                      </td>
                                      <td>
                                        <div className="tasktablelast">
                                          <Link
                                            to={"/admin/addtask?id=" + item?.id}
                                          >
                                            <span className="edit">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_277_135658)">
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                                    fill="#D6D6D6"
                                                  />
                                                  <path
                                                    d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                                    fill="#D6D6D6"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_277_135658">
                                                    <rect
                                                      width="22"
                                                      height="22"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </span>
                                          </Link>
                                          {/* <span
                                        className="trash"
                                        onClick={() => {
                                          handleShow();
                                          setDeleteId(item?.id);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          viewBox="0 0 22 22"
                                          fill="none"
                                        >
                                          <path
                                            d="M17.7506 6.13965H4.24769C3.83907 6.13965 3.50781 6.47087 3.50781 6.87945V18.437C3.50781 20.4014 5.10608 21.9996 7.07041 21.9996H14.9276C16.892 21.9996 18.4903 20.4014 18.4903 18.437V6.87945C18.4903 6.47091 18.1592 6.13965 17.7506 6.13965ZM9.22058 17.5437C9.22058 18.0456 8.81365 18.4526 8.31161 18.4526C7.80974 18.4526 7.40263 18.0456 7.40263 17.5437V10.5956C7.40263 10.0936 7.80967 9.68668 8.31161 9.68668C8.81362 9.68668 9.22058 10.0936 9.22058 10.5956V17.5437ZM14.5955 17.5437C14.5955 18.0456 14.1886 18.4526 13.6865 18.4526C13.1847 18.4526 12.7776 18.0456 12.7776 17.5437V10.5956C12.7776 10.0936 13.1846 9.68668 13.6865 9.68668C14.1885 9.68668 14.5955 10.0936 14.5955 10.5956V17.5437Z"
                                            fill="#D6D6D6"
                                          />
                                          <path
                                            d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                                            fill="#D6D6D6"
                                          />
                                        </svg>
                                      </span> */}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className='Paginationlattable'>
                          {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
                          {page >= 0 ?
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={Math.ceil(pageCount / limit)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              forcePage={page}
                            />
                            : ''}
                          {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
                        </div>
                      </div>

                    </div>
                  </>
                )}
              </>
            </>
          )}
          {activeTab === "DailyTask" && (
            <>
              <>
                <div className="lowertabssst">
                  <Nav
                    variant="pills"
                    activeKey={activeTab1}
                    onSelect={handleSelect1}
                    className="amberpillsoutersssyyyt  newtabsstyl"
                  >
                    <Nav.Item className="amberitempilsss  inertabtask">
                      <Nav.Link className="inerambss" eventKey="true">
                        Live
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="amberitempilsss inertabtask">
                      <Nav.Link className="inerambss" eventKey="false">
                        Ended
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                {activeTab1 === "true" && (
                  <>
                    <div className="maintablea_task">
                      <div className="maintableauser">
                        <div className="innertable">
                          <table>
                            <thead>
                              <th>Tasks Image</th>
                              <th> Title / Description </th>
                              <th>Task Completion</th>
                              <th>Rewards</th>
                              <th>Date Started </th>
                              <th>Date Ended </th>
                              <th>Action </th>
                            </thead>
                            <tbody>
                              {myFiles?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="inerimgddd_task_table">
                                          <Link to={"/admin/Adddstasks?id=" + item?.id}>
                                            <img
                                              src={item?.image}
                                              className="taskimg"
                                            ></img>
                                          </Link>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="taskinertablesecrow">
                                          <h6 className="boldstory eleipiess ">
                                            {item?.name}
                                          </h6>
                                          <p className="Synergy eleipiess">
                                            {item?.description}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="eleipiess">
                                          {item?.XBlueReward?.xblueRewardGold}
                                        </span>
                                      </td>
                                      <td>
                                        {item?.XBlueReward?.xblueRewardVerified}
                                      </td>
                                      <td>
                                        {moment(item?.createdAt).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(item?.updatedAt).format(
                                          "DD-MMM-YYYY"
                                        )}{" "}
                                      </td>
                                      <td>
                                        <div className="tasktablelast">
                                          <Link
                                            to={"/admin/addtask?id=" + item?.id}
                                          >
                                            <span className="edit">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_277_135658)">
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                                    fill="#D6D6D6"
                                                  />
                                                  <path
                                                    d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                                    fill="#D6D6D6"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_277_135658">
                                                    <rect
                                                      width="22"
                                                      height="22"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </span>
                                          </Link>
                                          <span
                                            className="trash"
                                            onClick={() => {
                                              handleShow();
                                              setDeleteId(item?.id);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="22"
                                              viewBox="0 0 22 22"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.7506 6.13965H4.24769C3.83907 6.13965 3.50781 6.47087 3.50781 6.87945V18.437C3.50781 20.4014 5.10608 21.9996 7.07041 21.9996H14.9276C16.892 21.9996 18.4903 20.4014 18.4903 18.437V6.87945C18.4903 6.47091 18.1592 6.13965 17.7506 6.13965ZM9.22058 17.5437C9.22058 18.0456 8.81365 18.4526 8.31161 18.4526C7.80974 18.4526 7.40263 18.0456 7.40263 17.5437V10.5956C7.40263 10.0936 7.80967 9.68668 8.31161 9.68668C8.81362 9.68668 9.22058 10.0936 9.22058 10.5956V17.5437ZM14.5955 17.5437C14.5955 18.0456 14.1886 18.4526 13.6865 18.4526C13.1847 18.4526 12.7776 18.0456 12.7776 17.5437V10.5956C12.7776 10.0936 13.1846 9.68668 13.6865 9.68668C14.1885 9.68668 14.5955 10.0936 14.5955 10.5956V17.5437Z"
                                                fill="#D6D6D6"
                                              />
                                              <path
                                                d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                                                fill="#D6D6D6"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className='Paginationlattable'>
                          {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
                          {page >= 0 ?
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={Math.ceil(pageCount / limit)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              forcePage={page}
                            />
                            : ''}
                          {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
                        </div>
                      </div>

                    </div>
                  </>
                )}
                {activeTab1 === "false" && (
                  <>
                    <div className="maintablea_task">
                      <div className="maintableauser">
                        <div className="innertable">
                          <table>
                            <thead>
                              <th>Tasks Image</th>
                              <th> Title / Description </th>
                              <th>Task Completion</th>
                              <th>Rewards</th>
                              <th>Date Started </th>
                              <th>Date Ended </th>
                              <th>Action </th>
                            </thead>
                            <tbody>
                              {myFiles?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={index}>
                                      <td>
                                        <div className="inerimgddd_task_table">
                                          <Link to={"/admin/Adddstasks?id=" + item?.id}>
                                            <img
                                              src={item?.image}
                                              className="taskimg"
                                            ></img>
                                          </Link>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="taskinertablesecrow">
                                          <h6 className="boldstory eleipiess ">
                                            {item?.name}
                                          </h6>
                                          <p className="Synergy eleipiess">
                                            {item?.description}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="eleipiess">
                                          {item?.XBlueReward?.xblueRewardGold}
                                        </span>
                                      </td>
                                      <td>
                                        {item?.XBlueReward?.xblueRewardVerified}
                                      </td>
                                      <td>
                                        {moment(item?.createdAt).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(item?.updatedAt).format(
                                          "DD-MMM-YYYY"
                                        )}{" "}
                                      </td>
                                      <td>
                                        <div className="tasktablelast">
                                          <Link
                                            to={"/admin/addtask?id=" + item?.id}
                                          >
                                            <span className="edit">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                              >
                                                <g clip-path="url(#clip0_277_135658)">
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                                    fill="#D6D6D6"
                                                  />
                                                  <path
                                                    d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                                    fill="#D6D6D6"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_277_135658">
                                                    <rect
                                                      width="22"
                                                      height="22"
                                                      fill="white"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </span>
                                          </Link>
                                          {/* <span
                                      className="trash"
                                      onClick={() => {
                                        handleShow();
                                        setDeleteId(item?.id);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.7506 6.13965H4.24769C3.83907 6.13965 3.50781 6.47087 3.50781 6.87945V18.437C3.50781 20.4014 5.10608 21.9996 7.07041 21.9996H14.9276C16.892 21.9996 18.4903 20.4014 18.4903 18.437V6.87945C18.4903 6.47091 18.1592 6.13965 17.7506 6.13965ZM9.22058 17.5437C9.22058 18.0456 8.81365 18.4526 8.31161 18.4526C7.80974 18.4526 7.40263 18.0456 7.40263 17.5437V10.5956C7.40263 10.0936 7.80967 9.68668 8.31161 9.68668C8.81362 9.68668 9.22058 10.0936 9.22058 10.5956V17.5437ZM14.5955 17.5437C14.5955 18.0456 14.1886 18.4526 13.6865 18.4526C13.1847 18.4526 12.7776 18.0456 12.7776 17.5437V10.5956C12.7776 10.0936 13.1846 9.68668 13.6865 9.68668C14.1885 9.68668 14.5955 10.0936 14.5955 10.5956V17.5437Z"
                                          fill="#D6D6D6"
                                        />
                                        <path
                                          d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                                          fill="#D6D6D6"
                                        />
                                      </svg>
                                    </span> */}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className='Paginationlattable'>
                          {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
                          {page >= 0 ?
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={Math.ceil(pageCount / limit)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              forcePage={page}
                            />
                            : ''}
                          {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
                        </div>
                      </div>

                    </div>
                  </>
                )}
              </>
            </>
          )}
        </section>
        <Modal
          className="ambmodalmain"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              delete Task
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z"
                  fill="white"
                />
              </svg>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mod_bodydivxxs my-3 maydivmod">
              <img src="\dashboard-assets\bin.png" />
              <p className="para4489">
                Are you sure you want to delete this task?
              </p>
              {/* <p className="para44800">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto
              </p> */}

              <div className="videopagerewards pt-5 myyybutmod">
                <button onClick={handleClose} className="red-bnewnyyy">
                  Cancel
                </button>
                <button onClick={deleteTask} className="red-wnewwnyy">
                  Yes’ I am sure
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Task;
