
import React, { useEffect, useState } from "react";
import './rewardlea.scss';
// reactstrap components
import { Link } from "react-router-dom";
import user5 from "assets/img/userflow/copy 1.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import user17 from "assets/img/userflow/copy-icon.svg";
import Carousel from 'react-bootstrap/Carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Environment from "utils/Environment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

function LeaderBoardDetails(props) {

    return (
        <>
            {/* <Backdrop className="loader" sx={{ color: '#fff' }} open={open1}><CircularProgress color="inherit" /></Backdrop> */}

            <div className="content">
                <section className="leaderdetails">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="upper-detail-page card">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="detail-card">
                                                {/* <img src={user.profile_image} alt="" /> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="row pt-4 pt-lg-0">
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>User Name</label>
                                                        <p>User Name</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Full Name</label>
                                                        <p>User Name</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Wallet Address</label>
                                                        {/* <p>{account == "" ? "" : `${account.substring(0, 6)}...${account.substring(
                                                            account.length - 4
                                                        )}`}
                                                            <span>
                                                                {
                                                                    copied ? <img src={user17} className="img-fluid pl-3" alt="" />
                                                                        :
                                                                        <CopyToClipboard text={account} onCopy={() => setCopied(true)}>
                                                                            <img src={user5} className="img-fluid pl-3" alt="" />
                                                                        </CopyToClipboard>
                                                                }
                                                            </span>
                                                            </p> */}
                                                            <p className="eleipiess">
ksadj;lsadl;sjlclkasnclaskjclascjalskjlkasjfl
                                                            </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Email Address</label>
                                                        <p>Email</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="feildss">
                                                        <label>Contact No</label>
                                                        <p>02333221445555</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-div-drop">
                                <div class="dropdown main-drop-down">
                                    <button class=" dropdown-toggless" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        This Week
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <Link class="dropdown-item" >12</Link>
                                        <Link class="dropdown-item" >12</Link>
                                        <Link class="dropdown-item" >100</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="lower-upper-detail card">
                                    <div class="table-responsive">
                                        <table class="table ">
                                            <thead>
                                                <tr>
                                                    <th>Reward Image</th>
                                                    <th> Details</th>
                                                    <th> Status</th>
                                                    <th>Submitted Proof</th>
                                                    <th> Verifie</th>
                                                </tr>
                                            </thead>
                                            <tbody className="main-t-body-text" >
                                                {/* {News ? News : ''} */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-modal-one">
                        <Modal className="register-modal collection-modal modal-collection-show">
                            <ModalHeader >
                                <button type="button" class="close" data-dismiss="modal"  aria-label="Close">
                           
                                </button>
                            </ModalHeader>
                            <ModalBody className="modal-body">
                                <div className="main-outter-caresoual">
                                    <Carousel>
                                        {/* {Images()}                         */}
                                    </Carousel>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </section>
            </div>


            
        </>
    );
}

export default LeaderBoardDetails;
