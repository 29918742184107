import React, { useEffect, useState } from "react";
import "./banner.scss";
import Environment from "utils/Environment";
import { Nav } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import moment from "moment/moment";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";


function NewsBanner() {

  const [selectedDate, setSelectedDate] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const token = localStorage.getItem("mytoken");
  const [deleteId, setDeleteId] = useState(null);

  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [activeTab1, setActiveTab1] = useState("true");
  const [activeTab11, setActiveTab11] = useState("link-1");
  const [activeTabiner, setActiveTabiner] = useState("link-1");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

          // pagination ============

          const [limit] = useState(10);
          const [page, setPage] = useState(0);
          const [pageCount, setPageCount] = useState([]);
        
          const handlePageChange = (e) => {
            const selectedPage = e.selected;
            setPage(selectedPage);
          };
        
          // pagination ============
 
   const getProducts = () => {
     setOpen(true);
     axios.get(
         Environment.backendUrl + `/api1/admin/get/AllImagesByType?limit=${limit}&offset=${page*limit}&type=${activeTab}`,
         { headers: { "Authorization": `Bearer ${token}` } }
     )
         .then((response) => {
             setOpen(false);
             console.log('image', response?.data);
             setMyFiles(response?.data?.imagesData);
             setPageCount(response?.data?.count);
 
         })
         .catch((err) => {
             setOpen(false);
             toast.error(err.response?.data.msg, {
                 position: "top-center",
                 autoClose: 2000,
             });
         });
 };
 
 
 
 useEffect(() => {
   getProducts()
 }, [page,activeTab])

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const handleSelect1 = (eventKey) => {
    setActiveTab1(eventKey);
  };

  const handleSelect11 = (eventKey) => {
    setActiveTab11(eventKey);
  };

  const handleSelectiner = (eventKey) => {
    setActiveTabiner(eventKey);
  };
























  return (
    <>



      {/* new bannar with  tabs  */}
      <>
        <div className="content">
          <section className="main-tasks user-details maintablea_leader mysectttt">
            <div className="maintablepillsty">
              <Nav
                variant="pills"
                activeKey={activeTab}
                onSelect={handleSelect}
                className="amberpillsouter_leader"
              >
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="profile">
                    Profile images
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="amberitempils">
                  <Nav.Link className="ineramb" eventKey="banner">
                    Banner images
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {activeTab === "profile" && (
              <>
                <div className="maintableauser ">
                  <div className="innertable_user_bannar table-responsive">
                    <table>
                      <thead>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Created Date</th>

                        <th>Edit </th>
                      </thead>
                      <tbody>
                      {myFiles?.map((item, index) => {
                                    return (
                                        <>
                        <tr key={index}>
                          <td>
                            <div className="mainimgdiv">
                              <div className="inerimgd">
                                <img
                                  src={item?.link}
                                  className="tableimgginer"
                                ></img>
                              </div>
                              {/* <p className="onlyboldtext">New App Release</p> */}
                            </div>
                          </td>
                          {/* <td className="onlyboldtext">New App Release</td> */}
                          <td>{item?.name}</td>
                          {/* <td className="">
                    <span className="eleipiess">https://balance.ex...</span>
                  </td> */}
                          <td>{moment(item?.createdAt).format(
                                                        "DD-MMM-YYYY"
                                                    )}</td>
                          {/* <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_277_135448)">
                        <path
                          d="M17.7506 6.14062H4.24769C3.83907 6.14062 3.50781 6.47185 3.50781 6.88043V18.4379C3.50781 20.4024 5.10608 22.0006 7.07041 22.0006H14.9276C16.892 22.0006 18.4903 20.4024 18.4903 18.4379V6.88043C18.4903 6.47188 18.1592 6.14062 17.7506 6.14062ZM9.22058 17.5446C9.22058 18.0466 8.81365 18.4535 8.31161 18.4535C7.80974 18.4535 7.40263 18.0466 7.40263 17.5446V10.5966C7.40263 10.0946 7.80967 9.68766 8.31161 9.68766C8.81362 9.68766 9.22058 10.0946 9.22058 10.5966V17.5446ZM14.5955 17.5446C14.5955 18.0466 14.1886 18.4535 13.6865 18.4535C13.1847 18.4535 12.7776 18.0466 12.7776 17.5446V10.5966C12.7776 10.0946 13.1846 9.68766 13.6865 9.68766C14.1885 9.68766 14.5955 10.0946 14.5955 10.5966V17.5446Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_277_135448">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td> */}
                          <td>
                            <Link to={"/admin/addBanners/news?id=" + item?.id}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_277_135465)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                  fill="#D6D6D6"
                                />
                                <path
                                  d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                  fill="#D6D6D6"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_277_135465">
                                  <rect width="22" height="22" fill="white" />
                                </clipPath>
                              </defs>
                            </svg></Link>
                          </td>
                          {/* <td><span className="greyish">
                                    Complete </span></td>
                                <td>
                                    <Link to="/admin/userdetail">
                                        <button className="detailbtn" >Detail</button>
                                    </Link>
                                </td> */}
                        </tr>
                        </>
                                    );
                                })}
                        
                      </tbody>
                    </table>
                  </div>
                  <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''}
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
                </div>
                {/* <h1 className="para44 newdiv11 ">Home page banners history</h1> */}
              </>
            )}
            {activeTab === "banner" && (
              <>
                <div className="maintableauser ">
                  <div className="innertable_user_bannar table-responsive">
                    <table>
                      <thead>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Created Date</th>

                        <th>Edit </th>
                      </thead>
                      <tbody>
                      {myFiles?.map((item, index) => {
                                    return (
                                        <>
                        <tr key={index}>
                          <td>
                            <div className="mainimgdiv">
                              <div className="inerimgd">
                                <img
                                  src={item?.link}
                                  className="tableimgginer"
                                ></img>
                              </div>
                              {/* <p className="onlyboldtext">New App Release</p> */}
                            </div>
                          </td>
                          {/* <td className="onlyboldtext">New App Release</td> */}
                          <td>{item?.name}</td>
                          {/* <td className="">
                    <span className="eleipiess">https://balance.ex...</span>
                  </td> */}
                          <td>{moment(item?.createdAt).format(
                                                        "DD-MMM-YYYY"
                                                    )}</td>
                          {/* <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_277_135448)">
                        <path
                          d="M17.7506 6.14062H4.24769C3.83907 6.14062 3.50781 6.47185 3.50781 6.88043V18.4379C3.50781 20.4024 5.10608 22.0006 7.07041 22.0006H14.9276C16.892 22.0006 18.4903 20.4024 18.4903 18.4379V6.88043C18.4903 6.47188 18.1592 6.14062 17.7506 6.14062ZM9.22058 17.5446C9.22058 18.0466 8.81365 18.4535 8.31161 18.4535C7.80974 18.4535 7.40263 18.0466 7.40263 17.5446V10.5966C7.40263 10.0946 7.80967 9.68766 8.31161 9.68766C8.81362 9.68766 9.22058 10.0946 9.22058 10.5966V17.5446ZM14.5955 17.5446C14.5955 18.0466 14.1886 18.4535 13.6865 18.4535C13.1847 18.4535 12.7776 18.0466 12.7776 17.5446V10.5966C12.7776 10.0946 13.1846 9.68766 13.6865 9.68766C14.1885 9.68766 14.5955 10.0946 14.5955 10.5966V17.5446Z"
                          fill="#D6D6D6"
                        />
                        <path
                          d="M17.0087 1.15112H13.7858V0.871624C13.7858 0.391003 13.3949 0 12.9143 0H9.08262C8.602 0 8.211 0.391003 8.211 0.871624V1.15112H4.98814C3.75406 1.15112 2.75 2.1551 2.75 3.38922V4.8244C2.75 5.08107 2.95807 5.28917 3.21484 5.28917H18.7821C19.0387 5.28917 19.2468 5.08107 19.2468 4.8244V3.38922C19.2468 2.15514 18.2429 1.15112 17.0087 1.15112Z"
                          fill="#D6D6D6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_277_135448">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </td> */}
                          <td>
                            <Link to={"/admin/addBanners/news?id=" + item?.id}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_277_135465)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                                  fill="#D6D6D6"
                                />
                                <path
                                  d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                                  fill="#D6D6D6"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_277_135465">
                                  <rect width="22" height="22" fill="white" />
                                </clipPath>
                              </defs>
                            </svg></Link>
                          </td>
                          {/* <td><span className="greyish">
                                    Complete </span></td>
                                <td>
                                    <Link to="/admin/userdetail">
                                        <button className="detailbtn" >Detail</button>
                                    </Link>
                                </td> */}
                        </tr>
                        </>
                                    );
                                })}
                        
                      </tbody>
                    </table>
                  </div>
                  <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''}
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
                </div>
              </>
            )}
          </section>

        </div>
      </>




















      <div
        class="modal fade modal-zzz"
        id="exampleModal346"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered daily-profile-modal">
          <div class="modal-content  daily-profile-modal-inner">
            <div class="modal-body modal-body-main">
              <div className="main-outter text-center">
                <div className="row main-cardssss">
                  <div className="col-md-12 col-12">
                    <div className="awesm">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <div className="flux-b pt-3">
                      <h3>Are You Sure You Want to Delete This Banner</h3>
                    </div>
                  </div>

                  <div className="col-md-12 col-12 ptb20">
                    <div className="button-modal-daily">
                      <button type="button" className="button-main-daily ">
                        Yes
                      </button>
                      <button
                        type="button"
                        className="button-main-dailys"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default NewsBanner;
