
import React, { useState, useEffect } from 'react';
import './edit.scss';

import 'react-toastify/dist/ReactToastify.css';

import { Link } from "react-router-dom";


function EditProfiles() {

    return (
        <>
         
            <div className="content">
                <section className="leaderdetails edit-page-profile">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="upper-detail-page ">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-12">

                                        <div className="detail-card">
                                            <div className='imgedit'>
                                                <img src="\users-assets\profile.svg" alt="" className='ineredit' />
                                            </div>

                                            <h3>Easin Arafat</h3>
                                            <p>Admin</p>
                                            <button type="button" className="change-password" data-toggle="modal" data-target="#exampleModal" >Change Password</button>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 edit-cards">
                                        <div className="upper-edit">
                                            <h5>Edit Profile</h5>
                                            {/* <hr className="main-line"></hr> */}
                                        </div>
                                        <div className="row pt-4 pt-lg-0">
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label for="example">Username</label>
                                                    <input type="text" class="form-control" id="example" aria-describedby="text" placeholder="Enter your username" />

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label for="example">Full name</label>
                                                    <input type="text" name='full_name'  class="form-control" placeholder="Enter your full name" />
                                                    {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label for="example">Email Adress</label>
                                                    <input  class="form-control" id="example" aria-describedby="text" placeholder="Enter your email address" readonly />
                                                    {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label for="example">Email Adress</label>
                                                    <input  class="form-control" id="example" aria-describedby="text" placeholder="Enter your email address" readonly />
                                                    {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div class="form-group">
                                                <div className="videopage">
                                                    <button className="red-bybuy nynever" >Update Profile</button>
                                                    <Link to="/admin/dashboard">
                                                        <button className="red-wy-y">Cancel</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-modal-one">
                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog daily-profile-modal">
                                            <div class="modal-content daily-profile-modal-inner">
                                                <div class="modal-body modal-body-main">
                                                    <div className="main-outter">
                                                        <div className="row main-cardssss">
                                                            <div className="col-md-12 col-12">
                                                                <div className="flux-b">
                                                                    <h3>Change Password</h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div class="form-group">
                                                                    <label for="example">Old Password</label>
                                                                    <input type="password" name="oldpassword"  class="form-control" placeholder="Enter Your old password" />
                                                              
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div class="form-group">
                                                                    <label for="example">New Password</label>
                                                                    <input type="password" name="newPassword"  class="form-control" placeholder="Enter Your new password" />
                                                             
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div class="form-group">
                                                                    <label for="example">Confirm Password</label>
                                                                    <input type="password" name="cnfrmpassword"  class="form-control" placeholder="Enter Your new password" />
                                                               
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-12">
                                                                <div className="button-modal-daily">
                                                                    <button type="button" className="button-main-daily"   >Save</button>
                                                                    <button type="button" className="button-main-dailys" data-dismiss="modal" aria-label="Close" >Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default EditProfiles;
