import React, { useEffect, useState } from "react";
import "./coin1.scss";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import { Link } from "react-router-dom";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

function Rolemanagement(props) {
  return (
    <>
      <div className="content">
        <section className="banner card">
          <div className="container-fluid">
            <div class="table-responsive">
              <table class="table ">
                <thead>
                  <tr>
                    <th>Role Name</th>
                    <th>Discription</th>
                    <th>Add Date</th>
                    <th>PUBLISH</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="main-t-body-text">
                  <tr>
                    <td className="tdclasss">Course Manager</td>
                    <td className="tdclasss">
                      Sed ut perspiciatis unde omnis iste natus error sit
                    </td>
                    <td className="tdclasss">08-Apr-2022</td>
                    <td className="tdclasss">
                      <div className="main-switch-nn dfgsgdfsgdddsfsggsd">
                        <div class="custom-control custom-switch dfgsgdfsgdsfsggsd">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customSwitches"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitches"
                          ></label>
                        </div>
                      </div>
                    </td>
                    <td className="tdclasss">
                      {" "}
                      <Link to={"/admin/AddAdminUser"}>
                        <button className="edittttbut">Edit</button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="modal fade modal-zzz"
              id="exampleModal345"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog daily-profile-modal">
                <div class="modal-content daily-profile-modal-inner">
                  <div class="modal-body modal-body-main">
                    <div className="main-outter text-center">
                      <div className="row main-cardssss">
                        <div className="col-md-12 col-12">
                          <div className="awesm">
                            <i class="fas fa-exclamation-triangle"></i>
                          </div>
                          <div className="flux-b pt-3">
                            <h3>Are You Sure You Want to Delete This Role</h3>
                          </div>
                        </div>
                        <div className="col-md-12 col-12 ptb20">
                          <div className="button-modal-daily">
                            <button
                              type="button"
                              className="button-main-daily "
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="button-main-dailys"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Rolemanagement;
