

import React, { useEffect, useState } from "react";
import './setting.scss';

import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import { Link } from "react-router-dom";
function Onboarding() {


    return (
        <>
           
            <div className="content">
                <section className="tasks ">
                    <div className="container-fluid">
                        <div class="table-responsive overflow-responce">
                            <table class="table ">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Edit</th>
                                        {/* <th>Delete</th> */}
                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                    <tr>
                                        <td className='main-image'>
                                            <img src="\setting-assets\Onboard1.png" />
                                        </td>
                                        <td className='para90'>Legion Wallet</td>
                                        <td className='para90 '>Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing.</td>
                                        <td> <Link to={`/admin/Addonboarding/?socialid=$`} className=''>

                                            <button className="btn-commonnnnnn px-3  py-2">Edit</button>


                                            {/* <i className="fas fa-edit cPointer"></i> */}

                                        </Link></td>
                                        {/* <td> <i onClick={() => deleteModal(item?.id)} className="far fa-trash-alt cPointer"></i></td> */}
                                    </tr>
                                    <tr>
                                        <td className='main-image'>
                                            <img src="\setting-assets\Onboard1.png" />
                                        </td>
                                        <td className='para90'>Legion Wallet</td>
                                        <td className='para90'>Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing.</td>
                                        <td> <Link to={`/admin/Addonboarding/?socialid=$`} className=''>

                                            <button className="btn-commonnnnnn  px-3  py-2">Edit</button>


                                            {/* <i className="fas fa-edit cPointer"></i> */}

                                        </Link></td>
                                        {/* <td> <i onClick={() => deleteModal(item?.id)} className="far fa-trash-alt cPointer"></i></td> */}
                                    </tr>
                                    <tr>
                                        <td className='main-image'>
                                            <img src="\setting-assets\Onboard1.png" />
                                        </td>
                                        <td className='para90'>Legion Wallet</td>
                                        <td className='para90'>Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing.</td>
                                        <td> <Link to={`/admin/Addonboarding/?socialid=$`} className=''>

                                            <button className="btn-commonnnnnn  px-3  py-2">Edit</button>


                                            {/* <i className="fas fa-edit cPointer"></i> */}

                                        </Link></td>
                                        {/* <td> <i onClick={() => deleteModal(item?.id)} className="far fa-trash-alt cPointer"></i></td> */}
                                    </tr>
                                    <tr>
                                        <td className='main-image'>
                                            <img src="\setting-assets\Onboard1.png" />
                                        </td>
                                        <td className='para90'>Legion Wallet</td>
                                        <td className='para90'>Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing.</td>
                                        <td> <Link to={`/admin/Addonboarding/?socialid=$`} className=''>

                                            <button className="btn-commonnnnnn  px-3  py-2">Edit</button>


                                            {/* <i className="fas fa-edit cPointer"></i> */}

                                        </Link></td>
                                        {/* <td> <i onClick={() => deleteModal(item?.id)} className="far fa-trash-alt cPointer"></i></td> */}
                                    </tr>
                                    <tr>
                                        <td className='main-image'>
                                            <img src="\setting-assets\Onboard1.png" />
                                        </td>
                                        <td className='para90'>Legion Wallet</td>
                                        <td className='para90'>Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing.</td>
                                        <td> <Link to={`/admin/Addonboarding/?socialid=$`} className=''>

                                            <button className="btn-commonnnnnn  px-3  py-2">Edit</button>


                                            {/* <i className="fas fa-edit cPointer"></i> */}

                                        </Link></td>
                                        {/* <td> <i onClick={() => deleteModal(item?.id)} className="far fa-trash-alt cPointer"></i></td> */}
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal fade modal-zzz" id="exampleModal346" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog daily-profile-modal modal-dialog-centered">
                    <div class="modal-content daily-profile-modal-inner">
                        <div class="modal-body modal-body-main">
                            <div className="main-outter text-center">
                                <div className="row main-cardssss">
                                    <div className="col-md-12 col-12">
                                        <div className="awesm">
                                            <i class="fas fa-exclamation-triangle"></i>
                                        </div>
                                        <div className="flux-b pt-3">
                                            <h3>Are You Sure You Want to Delete</h3>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12 ptb20">
                                        <div className="button-modal-daily">
                                            <button type="button" className="button-main-daily ">Yes</button>
                                            <button type="button" className="button-main-dailys" data-dismiss="modal" aria-label="Close"  >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Onboarding