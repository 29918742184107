import React, { useState } from 'react'
import { Modal, Nav, Pagination } from 'react-bootstrap'
import './randomizer.scss'
import { Link } from 'react-router-dom';

const Randomizer = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='content'>
                <div className='maintablea_leader'>
                    <div className="innertable table-responsive">
                        <table>
                            <thead>
                                <th>Gifts </th>
                                <th>Packs</th>
                                <th>Chance Percentage </th>

                            </thead>
                            <tbody>
                                <tr>

                                    <td>10 Referral Tickets </td>
                                    <td>
                                        <span className="eleipiess">
                                            Silver
                                        </span>
                                    </td>

                                    <td>
                                        <p className='gapsflow'
                                            onClick={handleShow}>50%
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                <path d="M4.35934 13.0137C3.95267 13.0137 3.57267 12.8737 3.29934 12.6137C2.95267 12.287 2.78601 11.7937 2.84601 11.2604L3.09267 9.10036C3.13934 8.69369 3.38601 8.15369 3.67267 7.86036L9.14601 2.06702C10.5127 0.620358 11.9393 0.580358 13.386 1.94703C14.8327 3.31369 14.8727 4.74036 13.506 6.18703L8.03267 11.9804C7.75267 12.2804 7.23267 12.5604 6.82601 12.627L4.67934 12.9937C4.56601 13.0004 4.46601 13.0137 4.35934 13.0137ZM11.286 1.94036C10.7727 1.94036 10.326 2.26036 9.87267 2.74036L4.39934 8.54036C4.26601 8.68036 4.11267 9.01369 4.08601 9.20703L3.83934 11.367C3.81267 11.587 3.86601 11.767 3.98601 11.8804C4.10601 11.9937 4.28601 12.0337 4.50601 12.0004L6.65267 11.6337C6.84601 11.6004 7.16601 11.427 7.29934 11.287L12.7727 5.49369C13.5993 4.61369 13.8993 3.80036 12.6927 2.66702C12.1593 2.15369 11.6993 1.94036 11.286 1.94036Z" fill="#0F7EFE" />
                                                <path d="M12.227 7.29925C12.2136 7.29925 12.1936 7.29925 12.1803 7.29925C10.1003 7.09258 8.42696 5.51258 8.10696 3.44592C8.06696 3.17258 8.25362 2.91925 8.52696 2.87258C8.80029 2.83258 9.05362 3.01925 9.10029 3.29258C9.35362 4.90592 10.6603 6.14592 12.287 6.30592C12.5603 6.33258 12.7603 6.57925 12.7336 6.85258C12.7003 7.10592 12.4803 7.29925 12.227 7.29925Z" fill="#0F7EFE" />
                                                <path d="M14.666 15.166H2.66602C2.39268 15.166 2.16602 14.9393 2.16602 14.666C2.16602 14.3927 2.39268 14.166 2.66602 14.166H14.666C14.9393 14.166 15.166 14.3927 15.166 14.666C15.166 14.9393 14.9393 15.166 14.666 15.166Z" fill="#0F7EFE" />
                                            </svg>
                                        </p>


                                    </td>
                                </tr>



                            </tbody>

                        </table>

                    </div>


                    <div className='Paginationlattable'>
                        <button className='leftpigbtn' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            Prev
                        </button>
                        <Pagination>
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Ellipsis />
                            <Pagination.Item active>{5}</Pagination.Item>
                            <Pagination.Item>{6}</Pagination.Item>
                        </Pagination>
                        <button className='leftpigbtn' >
                            Next
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                            </svg>

                        </button>
                    </div>



                </div>
            </div>

            <Modal className='ambmodalmain' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>

                        Edit Chance Percentage
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z" fill="white" />
                        </svg>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mod_bodydivxxs my-3 '>
                    <div class="form-group">
                                        <label className="rewardslable" for="example">Chance Percentage</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Enter Chance Percentage" />

                                    </div>
                   
                        <div className="videopagerewards pt-5">
                            <button className="red-bnew" >Save</button>
                            <button className="red-wneww">Cancel</button>
                        </div>
                    </div>



                </Modal.Body>
            </Modal >


        </>
    )
}

export default Randomizer
