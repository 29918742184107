
import React, { useState, useEffect } from "react";
import './rewardlea.scss';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Environment from 'utils/Environment';
import ReactPaginate from 'react-paginate';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Modal } from "react-bootstrap";
// reactstrap components
import { Link } from "react-router-dom";
import { Nav, Pagination } from "react-bootstrap";
function LeaderBoard() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [selectedDate, setSelectedDate] = useState(null);
    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    }
    const [activeTab11, setActiveTab11] = useState('link-1');

    const handleSelect11 = (eventKey) => {
        setActiveTab11(eventKey);
    }




  
    return (
        <>



            {/* Bluemoon new table design */}





            <div className="content">
                <section className="main-tasks user-details">
                    {activeTab === 'link-1' && (
                        <>
                            <div className="lowertabsss minetabs22">
                                <Nav variant="pills" activeKey={activeTab1} onSelect={handleSelect1} className='amberpillsoutersss  newtabsstyl mineclasstabs'>
                                    <Nav.Item className='amberitempilsss  inertabtask'>
                                        <Nav.Link className='inerambss' eventKey="link-1">Referrals</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss inertabtask'>
                                        <Nav.Link className='inerambss' eventKey="link-2222">Followers</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss inertabtask'>
                                        <Nav.Link className='inerambss' eventKey="link-1111">Streak</Nav.Link>
                                    </Nav.Item>


                                </Nav>

                            </div>
                            {activeTab1 === 'link-1' && (
                                <>
                                    <div className="onlyrewardstableouterdiv">
                                        <h5 className="boldstory py-3">
                                            Verified Referrals
                                        </h5>
                                        <div className='maintablea_task'>
                                            <div className="innertable">
                                                <table>
                                                    <thead>
                                                        <th>
                                                            Referrers Landmark Bonus
                                                        </th>
                                                        <th> xBlue </th>
                                                        <th>Verified </th>
                                                        <th>Unverified </th>
                                                        <th>Edit</th>
                                                        {/* <th>Date </th>
                                                    <th>Action </th> */}
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className='vvvvvv'><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className='vvvvvv'><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className='vvvvvv'><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className='vvvvvv'><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>


                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="onlyrewardstableouterdiv mt-3">
                                        <h5 className="boldstory py-3">
                                            Active Referrals
                                        </h5>
                                        <div className='maintablea_task'>
                                            <div className="innertable">
                                                <table>
                                                    <thead>
                                                        <th>
                                                            Referrers Landmark Bonus
                                                        </th>
                                                        <th> xBlue </th>
                                                        <th>Verified </th>
                                                        <th>Unverified </th>
                                                        <th>Edit</th>
                                                        {/* <th>Date </th>
                                                    <th>Action </th> */}
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                            <td>
                                                                $2.00
                                                            </td>
                                                            <td className=''><svg onClick={handleShow} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>


                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab1 === 'link-2222' && (
                                <>
                             <div className="onlyrewardstableouterdiv">
                                        <h5 className="boldstory py-3">
                                        Verified Followers
                                        </h5>
                                        <div className='maintablea_task'>
                                            <div className="innertable">
                                                <table>
                                                    <thead>
                                                        <th>
                                                        Followers Landmark Bonus
                                                        </th>
                                                        <th> xBlue </th>
                                                        <th>Verified </th>
                                                        {/* <th>Unverified </th> */}
                                                        <th>Edit</th>
                                                        {/* <th>Date </th>
                                                    <th>Action </th> */}
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow1} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow1} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow1} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow1} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow1} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                            


                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab1 === 'link-1111' && (
                                <>
                                <div className="onlyrewardstableouterdiv">
                                        <h5 className="boldstory py-3">
                                        Bonus on days
                                        </h5>
                                        <div className='maintablea_task'>
                                            <div className="innertable">
                                                <table>
                                                    <thead>
                                                        <th>
                                                        Daily Streak
                                                        </th>
                                                        <th> xBlue </th>
                                                        <th>Verified </th>
                                                        {/* <th>Unverified </th> */}
                                                        <th>Edit</th>
                                                        {/* <th>Date </th>
                                                    <th>Action </th> */}
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                                20 Verified Referrals

                                                            </td>
                                                            <td>


                                                                10,000



                                                            </td>
                                                            <td>
                                                                <span className="eleipiess">
                                                                    $10.00
                                                                </span>
                                                            </td>
                                                         
                                                            <td className=''><svg onClick={handleShow2} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                <g clip-path="url(#clip0_277_141271)">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z" fill="#D6D6D6" />
                                                                    <path d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z" fill="#D6D6D6" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_277_141271">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></td>
                                                
                                                        </tr>

                                            


                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}

                            <Modal className='ambmodalmain userlastmodal  addrewads' show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Referrers Landmark Bonus

                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='userlastmodrewards'>
                              
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Referrers Landmark Bonus</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Prize Name" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">xBlue</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Verified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Unverified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                            
                           
                                    <div className="videopagerewards">
                                        <button className="red-bnew" >Save</button>
                                      <button className="red-wneww">Cancel</button>
                                    </div>

                                </Modal.Body>

                            </Modal>
                            
                            <Modal className='ambmodalmain userlastmodal  addrewads' show={show1} onHide={handleClose1} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Followers Landmark bonus

                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='userlastmodrewards'>
                              
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Referrers Landmark Bonus</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Prize Name" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">xBlue</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Verified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Unverified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                            
                           
                                    <div className="videopagerewards">
                                        <button className="red-bnew" >Save</button>
                                    <button className="red-wneww">Cancel</button>
                                    </div>

                                </Modal.Body>

                            </Modal>
                            
                            <Modal className='ambmodalmain userlastmodal  addrewads' show={show2} onHide={handleClose2} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Followers Landmark bonus

                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='userlastmodrewards'>
                              
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Referrers Landmark Bonus</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Prize Name" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">xBlue</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Verified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                                    <div class="form-group">
                                        <label className="rewardslable" for="example">Unverified</label>
                                        <input type="text" name="rewards" className="rewardinput" placeholder="Price" />

                                    </div>
                            
                           
                                    <div className="videopagerewards">
                                        <button className="red-bnew" >Save</button>
                                       <button className="red-wneww">Cancel</button>
                                    </div>

                                </Modal.Body>

                            </Modal>
                        </>
                    )}

                </section>
            </div>


        </>
    );
}

export default LeaderBoard;
