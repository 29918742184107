import React, { useEffect, useState } from "react";
import "./coin1.scss";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import { Link } from "react-router-dom";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { Modal } from "react-bootstrap";

function AdminUser(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="content">
        <section className="banner card">
          <div className="container-fluid">
            <div class="table-responsive">
              <table class="table ">
                <thead>
                  <tr>
                    <th>Users Name</th>
                    <th>Email</th>
                    <th>Role </th>
                    <th>Joining Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="main-t-body-text">
                  <tr>
                    <td className="  dflexfortableonlyss">
                      <div className="mainyhead">
                        <div className="productimgsss">
                          <img
                            src="\dashboard-assets\admin-img.png"
                            className=" innerimgsss"
                            alt=""
                          />
                        </div>
                        <p className="sddsdsdsdsd">Rare Box</p>
                      </div>
                    </td>

                    <td className="sddsdsdsdsd">carolyn@gmail.com</td>
                    <td className="sddsdsdsdsd">Course Manager</td>
                    <td className="sddsdsdsdsd">Aug 25, 2022</td>
                    <td>
                      {/* <div class="dropdown">
                                                <button
                                                    class="btn btn-secondary dropdown-toggle drpbuttt"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="25"
                                                        height="24"
                                                        viewBox="0 0 25 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M10.5 19C10.5 20.1 11.4 21 12.5 21C13.6 21 14.5 20.1 14.5 19C14.5 17.9 13.6 17 12.5 17C11.4 17 10.5 17.9 10.5 19Z"
                                                            fill="#3553D5"
                                                        />
                                                        <path
                                                            d="M10.5 5C10.5 6.1 11.4 7 12.5 7C13.6 7 14.5 6.1 14.5 5C14.5 3.9 13.6 3 12.5 3C11.4 3 10.5 3.9 10.5 5Z"
                                                            fill="#3553D5"
                                                        />
                                                        <path
                                                            d="M10.5 12C10.5 13.1 11.4 14 12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10C11.4 10 10.5 10.9 10.5 12Z"
                                                            fill="#3553D5"
                                                        />
                                                    </svg>
                                                </button>


                                            </div> */}

                      <div class="dropdown droped">
                        <button
                          class="dropbtn "
                          type="button"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M10.5 19C10.5 20.1 11.4 21 12.5 21C13.6 21 14.5 20.1 14.5 19C14.5 17.9 13.6 17 12.5 17C11.4 17 10.5 17.9 10.5 19Z"
                              fill="#0F7EFE"
                            />
                            <path
                              d="M10.5 5C10.5 6.1 11.4 7 12.5 7C13.6 7 14.5 6.1 14.5 5C14.5 3.9 13.6 3 12.5 3C11.4 3 10.5 3.9 10.5 5Z"
                              fill="#0F7EFE"
                            />
                            <path
                              d="M10.5 12C10.5 13.1 11.4 14 12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10C11.4 10 10.5 10.9 10.5 12Z"
                              fill="#0F7EFE"
                            />
                          </svg>
                        </button>
                        <div class="dropdown-menu dropmain dropheaddd">
                          <Link to={"/admin/Addrolemanagement/"}>
                            <p className="dropdown-item drop middddiv">
                              <i class="fas fa-edit editt ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M14 14.668H2C1.72667 14.668 1.5 14.4413 1.5 14.168C1.5 13.8946 1.72667 13.668 2 13.668H14C14.2733 13.668 14.5 13.8946 14.5 14.168C14.5 14.4413 14.2733 14.668 14 14.668Z"
                                    fill="#3553D5"
                                  />
                                  <path
                                    d="M12.6804 2.32043C11.387 1.0271 10.1204 0.993764 8.79371 2.32043L7.98704 3.1271C7.92037 3.19376 7.89371 3.30043 7.92038 3.39376C8.42704 5.16043 9.84037 6.57376 11.607 7.08043C11.6337 7.0871 11.6604 7.09376 11.687 7.09376C11.7604 7.09376 11.827 7.0671 11.8804 7.01376L12.6804 6.2071C13.3404 5.55376 13.6604 4.92043 13.6604 4.28043C13.667 3.62043 13.347 2.98043 12.6804 2.32043Z"
                                    fill="#3553D5"
                                  />
                                  <path
                                    d="M10.4056 7.68654C10.2122 7.5932 10.0256 7.49987 9.84558 7.3932C9.69891 7.30654 9.55891 7.2132 9.41891 7.1132C9.30558 7.03987 9.17224 6.9332 9.04558 6.82654C9.03224 6.81987 8.98558 6.77987 8.93224 6.72654C8.71224 6.53987 8.46558 6.29987 8.24558 6.0332C8.22558 6.01987 8.19224 5.9732 8.14558 5.9132C8.07891 5.8332 7.96558 5.69987 7.86558 5.54654C7.78558 5.44654 7.69224 5.29987 7.60558 5.1532C7.49891 4.9732 7.40558 4.7932 7.31224 4.60654C7.18987 4.34431 6.8457 4.26641 6.64108 4.47103L2.89224 8.21987C2.80558 8.30654 2.72558 8.4732 2.70558 8.58654L2.34558 11.1399C2.27891 11.5932 2.40558 12.0199 2.68558 12.3065C2.92558 12.5399 3.25891 12.6665 3.61891 12.6665C3.69891 12.6665 3.77891 12.6599 3.85891 12.6465L6.41891 12.2865C6.53891 12.2665 6.70558 12.1865 6.78558 12.0999L10.5406 8.34484C10.7411 8.14432 10.6658 7.79929 10.4056 7.68654Z"
                                    fill="#3553D5"
                                  />
                                </svg>{" "}
                                Edit{" "}
                              </i>
                            </p>
                          </Link>
                          <p className="dropdown-item drop middddiv">
                            <button
                              onClick={handleShow}
                              className="buttons-remove editt"
                              type="button"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M14.0491 3.48536C12.9757 3.3787 11.9024 3.2987 10.8224 3.2387V3.23203L10.6757 2.36536C10.5757 1.75203 10.4291 0.832031 8.86905 0.832031H7.12238C5.56905 0.832031 5.42238 1.71203 5.31572 2.3587L5.17572 3.21203C4.55572 3.25203 3.93572 3.29203 3.31572 3.35203L1.95572 3.48536C1.67572 3.51203 1.47572 3.7587 1.50238 4.03203C1.52905 4.30536 1.76905 4.50536 2.04905 4.4787L3.40905 4.34536C6.90238 3.9987 10.4224 4.13203 13.9557 4.48536C13.9757 4.48536 13.9891 4.48536 14.0091 4.48536C14.2624 4.48536 14.4824 4.29203 14.5091 4.03203C14.5291 3.7587 14.3291 3.51203 14.0491 3.48536Z"
                                  fill="#F04914"
                                />
                                <path
                                  d="M12.8211 5.42797C12.6611 5.2613 12.4411 5.16797 12.2145 5.16797H3.7878C3.56114 5.16797 3.33447 5.2613 3.18114 5.42797C3.0278 5.59464 2.94114 5.8213 2.95447 6.05464L3.3678 12.8946C3.44114 13.908 3.53447 15.1746 5.86114 15.1746H10.1411C12.4678 15.1746 12.5611 13.9146 12.6345 12.8946L13.0478 6.0613C13.0611 5.8213 12.9745 5.59464 12.8211 5.42797ZM9.1078 11.8346H6.8878C6.61447 11.8346 6.3878 11.608 6.3878 11.3346C6.3878 11.0613 6.61447 10.8346 6.8878 10.8346H9.1078C9.38113 10.8346 9.6078 11.0613 9.6078 11.3346C9.6078 11.608 9.38113 11.8346 9.1078 11.8346ZM9.6678 9.16797H6.33447C6.06114 9.16797 5.83447 8.9413 5.83447 8.66797C5.83447 8.39464 6.06114 8.16797 6.33447 8.16797H9.6678C9.94113 8.16797 10.1678 8.39464 10.1678 8.66797C10.1678 8.9413 9.94113 9.16797 9.6678 9.16797Z"
                                  fill="#F04914"
                                />
                              </svg>{" "}
                              Delete{" "}
                            </button>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="modal fade modal-zzz"
              id="exampleModal345"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog daily-profile-modal">
                <div class="modal-content daily-profile-modal-inner">
                  <div class="modal-body modal-body-main">
                    <div className="main-outter text-center">
                      <div className="row main-cardssss">
                        <div className="col-md-12 col-12">
                          <div className="awesm">
                            <i class="fas fa-exclamation-triangle"></i>
                          </div>
                          <div className="flux-b pt-3">
                            <h3>
                              Are You Sure You Want to Delete This Sub Admin
                            </h3>
                          </div>
                        </div>

                        <div className="col-md-12 col-12 ptb20">
                          <div className="button-modal-daily">
                            <button
                              type="button"
                              className="button-main-daily "
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="button-main-dailys"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          className="ambmodalmain"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              delete user
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z"
                  fill="white"
                />
              </svg>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mod_bodydivxxs my-3 maydivmod">
              <img src="\dashboard-assets\bin.png" />
              <p className="para4489">Are you sure you want to delete this user?</p>
              <p className="para44800">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto
              </p>

              <div className="videopagerewards pt-5 myyybutmod">
                <button className="red-bnewnyyy">Cancel</button>
                <button className="red-wnewwnyy">Yes’ I am sure</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default AdminUser;
