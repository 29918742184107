import React, { useEffect, useRef, useState } from "react";
import "./productstype.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { useHistory } from "react-router-dom";

const Addproductstype = (props) => {
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const [name, setName] = useState("");
  const [discription, setDescription] = useState("");
  const [total, setTotal] = useState("");
  const [left, setLeft] = useState("");
  const [myFiles, setMyFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [banner, setBanner] = useState("");
  const [profile, setProfile] = useState("");
  const [xBlue, setXblue] = useState([]);
  const [image, setImage] = useState([]);
  const [categories, setCategories] = useState([]);
  const [catName, setCatName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [id, setId] = useState("");
  const [selectedId2, setSelectedId2] = useState("");
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getTaskDetail(id);
    }
  }, [id]);

  const getTaskDetail = (id) => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + `/api1/admin/get/productTypeById?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setName(response.data?.data?.productName);
        setTotal(response.data?.data?.productQuantity);
        setLeft(response.data?.data?.quantityLeft);
        setType(response.data?.data?.productType);
        setCatName(response.data?.data?.ProductCategory?.categoryName);
        setBanner(response.data?.data?.avatar_banner?.name);
        setProfile(response.data?.data?.avatar_image?.name);
        setCategoryId(response.data?.data?.ProductCategory?.id);
        setSelectedId(response.data?.data?.avatar_banner?.id);
        setSelectedId2(response.data?.data?.avatar_image?.id);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };


  const getProductType = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/get/productTypeListForProduct", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setMyFiles(response.data);

      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const getImage = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/get/profileAndBanners", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setXblue(response.data?.banner);
        setImage(response.data?.profile);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const getCategory = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/all/productCategory?limit=1000&offset=0", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setCategories(response?.data?.productCategory || []);
        console.log(response?.data?.productCategory, "ll");
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getProductType();
    getImage();
    getCategory();

  }, []);

  const addProductType = async (e) => {
    e.preventDefault();
    if (!name || !total || !left || !type) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }

    let data;
    if (selectedId2 && selectedId) {
      data = {
        productName: name,
        productQuantity: total,
        quantityLeft: left,
        productType: type,
        banner_id: selectedId,
        image_id: selectedId2,
        category_id: categoryId,

      };
    } else {
      data = {
        productName: name,
        productQuantity: total,
        quantityLeft: left,
        productType: type,
        category_id: categoryId,

      };
    }
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/add/productType`,
      data,
      // if (image_id) {

      // }
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/product-type");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };

  const editProductType = async (e) => {
    e.preventDefault();
    if (!name || !total || !left || !type) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }

    let data;
    if (selectedId2 && selectedId) {
      data = {
        productName: name,
        productQuantity: total,
        quantityLeft: left,
        productType: type,
        banner_id: selectedId,
        image_id: selectedId2,
        category_id: categoryId,
        id: id,
      };
    } else {
      data = {
        productName: name,
        productQuantity: total,
        quantityLeft: left,
        productType: type,
        category_id: categoryId,
        id: id,
      };
    }

    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/edit/productType`,
      data,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/product-type");
      })
      .catch((err) => {
        if (err?.response?.statusCode === 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };


  return (
    <>
      <div className="content">
        <section className="addproductform">
          <div className="row">
            <div className="col-sm-8 p-0">
              <p className="headyyyy">Product name</p>
              <input

                value={name}
                onChange={(e) => setName(e.target.value)}

                type="text"
                placeholder="Name"
              />
              {/* <p className="headyyyy">Product description</p>
              <input

                value={discription}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                placeholder="Description"
                className="desssssss"
              /> */}
              <p className="headyyyy">Total quantity</p>
              <input
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                type="number"
                min={0}
                placeholder="10"
              />
              <p className="headyyyy">Quantity left</p>
              <input
                value={left}
                onChange={(e) => setLeft(e.target.value)}
                type="number"
                min={0}
                placeholder="6"
              />


              <div class="form-group">
                <label className="padd-top headyyyy" for="example">
                  Category{" "}
                </label>
                <div className="dropdown buttons-list-all cshbshjbvch">
                  <button
                    className="button-listing"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      catName ? <><h6 style={{ color: "black", fontWeight: '400' }}>{catName}</h6></> : "Select Category"}
                    {/* <p className="manddiv">
                              {type ? type : "Select Type"}
                            </p> */}
                    <i class="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                        <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                      </svg>
                    </i>
                  </button>

                  <div
                    className="dropdown-menu zscscsac"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {categories?.map((item, index) => {
                      return (
                        <>
                          <a
                            onClick={() => {
                              setCatName(item?.categoryName); setCategoryId(item?.id); setType(item?.categoryName);
                            }}
                            className="dropdown-item taskdiv mainydiskkk"
                          >
                            {item?.categoryName}
                          </a>

                        </>
                      );
                    })}


                    {/* <a className="dropdown-item taskdiv">Invite Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group </a>
                              <a className="dropdown-item taskdiv">legion Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group</a>
                              <a className="dropdown-item taskdiv">Submit Group</a> */}
                  </div>
                </div>
              </div>
              <div>
                {(type === "3D Avatars" || type === "Profile Covers")&&
                  <div class="form-group">
                    <label className="padd-top headyyyy" for="example">
                      Banner Image{" "}
                    </label>
                    <div className="dropdown buttons-list-all cshbshjbvch">
                      <button
                        className="button-listing"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {
                          banner ? <><h6 style={{ color: "black", fontWeight: '400' }}>{banner}</h6></> : "Select Banner Image"}
                        {/* <p className="manddiv">
                              {type ? type : "Select Type"}
                            </p> */}
                        <i class="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                          </svg>
                        </i>
                      </button>

                      <div
                        className="dropdown-menu zscscsac"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {xBlue?.map((item, index) => {
                          return (
                            <>
                              <a
                                onClick={() => {
                                  setBanner(item?.name); setSelectedId(item?.id);
                                }}
                                className="dropdown-item taskdiv mainydiskkk"
                              >
                                {item?.name}
                              </a>

                            </>
                          );
                        })}


                        {/* <a className="dropdown-item taskdiv">Invite Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group </a>
                              <a className="dropdown-item taskdiv">legion Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group</a>
                              <a className="dropdown-item taskdiv">Submit Group</a> */}
                      </div>
                    </div>
                  </div>
                }
                {(type === "3D Avatars" || type === "Profile Images")&&
                  <div class="form-group">
                    <label className="padd-top headyyyy" for="example">
                      Profile Image{" "}
                    </label>
                    <div className="dropdown buttons-list-all cshbshjbvch">
                      <button
                        className="button-listing"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {
                          profile ? <><h6 style={{ color: "black", fontWeight: '400' }}>{profile}</h6></> : "Select Profile Image"}
                        {/* <p className="manddiv">
                              {type ? type : "Select Type"}
                            </p> */}
                        <i class="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                          </svg>
                        </i>
                      </button>

                      <div
                        className="dropdown-menu zscscsac"
                        aria-labelledby="dropdownMenuButton"
                      >

                        {image?.map((item, index) => {
                          return (
                            <>
                              <a
                                onClick={() => {
                                  setProfile(item?.name); setSelectedId2(item?.id)
                                }}
                                className="dropdown-item taskdiv mainydiskkk"
                              >
                                {item?.name}
                              </a>
                            </>
                          );
                        })}

                        {/* <a className="dropdown-item taskdiv">Invite Group</a>
                            }
                              <a className="dropdown-item taskdiv">Instagram Group </a>
                              <a className="dropdown-item taskdiv">legion Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group</a>
                              <a className="dropdown-item taskdiv">Submit Group</a> */}
                      </div>
                    </div>
                  </div>
                }
              </div>

              {/* <p className="head">Price in USDT</p>
                            <input  type="number" placeholder='100 USDT' /> */}
              {/* <p className="head">What is it?</p> */}

              {/* <JoditEditor
                ref={editor2}
                value={htmlContent2}
                tabIndex={1} // tabIndex of textarea
                onChange={handleChange2}
              />
              <p className="head">What is inside?</p>

              <JoditEditor
                ref={editor}
                value={htmlContent}
                tabIndex={1} // tabIndex of textarea
                onChange={handleChange}
              /> */}
              <div className="lastbtns pt-5 mybutttts">
                <Link to="/admin/product-type">
                  <button className="red-wy-y">Cancel</button>
                </Link>
                <button
                  onClick={
                    id ? (e) => editProductType(e) : (e) => addProductType(e)
                  }
                  className="red-bybuy"
                >
                  {id ? "Update" : "Publish"}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Addproductstype;
