import React, { useState, useEffect } from "react";
// import './banner.scss';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
// import Chain from "./bannerarray";
import { Link } from "react-router-dom";
// reactstrap components
const Addgroup = (props) => {
  const token = localStorage.getItem('mytoken')
  const history = useHistory();
const [name,setName] = useState("");
const [discription,setDescription] = useState("");
const [image, setImage] = useState("");
const [baseImage, setBaseImage] = useState("");
const location = useLocation();
const [currentId, setCurrentId] = useState(null);
const [open, setOpen] = useState(false);
const [myFiles, setMyFiles] = useState([]);
const uploadImages = async (e) => {
  const file = e.target.files[0];
  const base64 = await convertBase645(file);
  setBaseImage(base64);
  setImage(file);
};



const convertBase645 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

useEffect(() => {
  const pathParts = location.pathname.split('/');
  const idParam = pathParts[pathParts.length - 1];
  const idValue = idParam.split('=')[1];

  if (idValue) {
    setCurrentId(idValue);
    localStorage.setItem('currentId', idValue);
    getTaskDetail(idValue);
  }
}, [location]);


const addGroup = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !discription ||
      !image 
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
  const formData = new FormData();
  if (name) {
    formData.append("name", name);
  }
  if (discription) {
    formData.append("description", discription);
  }
 
  if (image) {
    formData.append("image", image);
  }
  const config = {
    method: "post",
    url:  Environment.backendUrl+ "/api1/admin/create/group",
    data: formData,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await axios(config)
    .then((res) => {
      console.log(res,"mmm");
      toast.success(res?.data?.msg);
      setBaseImage();
      setImage();
      // if (image) {
      //   localStorage.setItem("profilePicture", baseImage);
      //   setAccountImage(baseImage);
      // }
      history.push("/admin/tasksgroup");
      // handleClose();
    })
    .catch((err) => {
      if (err?.response?.statusCode == 501) {
        history.push("/");
      } else {
        console.log("error meessage: ", err?.response?.data?.message);

        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    });
  // }
};



const editGroup = async (e) => {
  // validateUserName();
  // if (!errors) {
    e.preventDefault();

  const formData = new FormData();
  if (name) {
    formData.append("name", name);
  }
  if (discription) {
    formData.append("description", discription);
  }
 
  if (image) {
    formData.append("image", image);
  }
  if (currentId) {
    formData.append("groupId", currentId);
  }
  const config = {
    method: "post",
    url:  Environment.backendUrl+ "/api1/admin/edit/group",
    data: formData,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await axios(config)
    .then((res) => {
      console.log(res,"mmm");
      toast.success(res?.data?.msg);
      setBaseImage();
      setImage();
      // if (image) {
      //   localStorage.setItem("profilePicture", baseImage);
      //   setAccountImage(baseImage);
      // }
      history.push("/admin/tasksgroup");
      // handleClose();
    })
    .catch((err) => {
      if (err?.response?.statusCode == 501) {
        history.push("/");
      } else {
        console.log("error meessage: ", err?.response?.data?.message);

        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
      }
    });
  // }
};



const getTaskDetail = (idValue) => {
  setOpen(true);
  axios.get(
      Environment.backendUrl + `/api1/admin/get/groupById?id=${idValue}`, 
      { headers: { "Authorization": `Bearer ${token}` } }
  )
  .then((response) => {
      setOpen(false);
      setDescription(response.data?.description);
      setName(response.data?.name);
      setBaseImage(response.data?.image);

      console.log(response.data,"bbb");

  })
  .catch((err) => {
      setOpen(false);
      toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
      });
  });
};



  return (
    <>
      {/* <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop> */}
      <div className="content myyyyyy">
        <section className="addbanner12345 card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-8 order-md-0 order-1">
                  <div className="row col-12">
                    <div className="row col-12">
                      <div className="col-12">
                        <div class="form-group">
                          <label for="example">Group Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control myinputdivr"
                            placeholder="Enter Group name"
                            value={name} onChange={(e) => setName(e.target.value)}
                          />
                          <p className="inputErrors"></p>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div class="form-group">
                          <label className="padd-top " for="example">
                            {" "}
                            Group description
                          </label>
                          <textarea
                            name="description"
                            className="form-control myinputdivr newnewpad"
                            placeholder="Enter description of the game"
                            rows="5"
                            value={discription} onChange={(e) => setDescription(e.target.value)}
                          ></textarea>

                          <p className="inputErrors"></p>
                        </div>
                      </div>

                      {/* <div className="col-12 ">
                                                    <div class="form-group">
                                                        <label for="example">Category</label>
                                                        <input type="text" name="category"  className='form-control myinputdivr'  placeholder="Enter title of the game" />
                                                        
                                                            return <p className="inputErrors">error</p>
                                                  
                                                    </div>
                                                </div> */}

                      <div className="col col-12"></div>
                    </div>

                    <div className="col-12">
                      <div class="form-group">
                        <div className="videopage">
                          <button onClick={currentId?(e) => editGroup(e): (e) => addGroup(e)} className="red-bybuy">{currentId?"Update":"Publish"}</button>
                          {/* <button className="red-b" onClick={sendVideo}>Publish</button> */}
                          <Link to="/admin/tasksgroup">
                            <button className="red-wy-y">Cancel</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 order-md-1 order-0">
                                <div className="upload-img ">
                                <p className=" manydivbart">Upload Group Task Image</p>
                      <p className="imgsize">Image size: 381x138</p>
                      <div className="upload sidedotsaa ">
                        <img src="" className="imgProfile" alt="" />
                        <label htmlFor="upload">
                        {baseImage ? (
                          <img
                            src={baseImage}
                            alt="img"
                            className="img-fluid dragimg"
                          />
                        ) : (
                          <>
                          <img
                            src="\dashboard-assets\upload.svg"
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          <p className="paraa1">Drag & drop or <span className="paraa2"> Browse</span></p>
                          </>
                          )}
                        </label>
                      </div>

                      {/* <p className="text-danger">Image is missing!</p> */}
                      <input
                        type="file"
                        name="image"
                        className="d-none"
                        id="upload"
                        onChange={uploadImages}
                      />
                    </div>
                                   
                                </div>
              </div>
            </div>
            {/* <ToastContainer style={{ fontSize: 20 }} /> */}
          </form>
        </section>
      </div>
    </>
  );
};

export default Addgroup;
