import React, { useEffect, useState } from "react";
import "./success.css";
import { toast } from "react-toastify";
import axios from "axios";
import Environment from 'utils/Environment'


const EmailSuccess = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true); // Added loading state
  const [tokenState, setTokenState] = useState("");

  const EmailVerify = (token) => {
    axios
      .get(
        Environment.backendUrl +`/api1/user/LinkVerification/?token=${token}`
      )
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error verifying email:", error);
        if (
          error.response &&
          (error.response.status === 409 || error.response.status === 400)
        ) {
          toast.error(error.response.data.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    setTokenState(token);
  }, []);
  useEffect(() => {
    if (tokenState) {
      EmailVerify(tokenState);
    }
  }, [tokenState]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      {success && (
        <div className="mainsection">
          <div className="textparent">
            <img
              src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714638604/Bluemoon%20CloudData/2-updated_pzkreb.png"
              alt="img"
              className="img-fluid images"
            />
            <h2 className="heads">Congratulations!</h2>
            <h3 className="innerhead">Your account has been verified</h3>
            <p className="para">Please login to the Mobile App Now.</p>
            <p className="para1">Click the links below to download:</p>
            <div className="parent">
              <a href="https://play.google.com/store/apps/details?id=com.legion.production&hl=en&gl=US">
                <img
                  src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714638955/Bluemoon%20CloudData/App_Store_-_eng_hvr32k.png"
                  alt="img"
                  className="img-fluid"
                />
              </a>
              <a href="https://apps.apple.com/gb/app/legion-network-crypto-superapp/id1602921528">
                <img
                  src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714638996/Bluemoon%20CloudData/Google_Play_-_eng_ipcsxy.png"
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailSuccess;
