import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import user4 from "assets/img/userflow/add1.png";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
// import { inputAction } from '../../redux/action/action';
// import { useDispatch } from 'react-redux'
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  // InputGroup,
  // InputGroupText,
  // InputGroupAddon,
  // Input,
} from "reactstrap";

import routes from "routes.js";
import { Dropdown, FormLabel, InputGroup } from "react-bootstrap";

const Header = (props) => {
  const [showcalendar4, setShowCalendar4] = useState(false);
  const [showw, setShoww] = useState(false);
  const handleClose = () => setShoww(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedImg, setSelectedImg] = useState();
  const [myFiles, setMyFiles] = useState({});
  const token = localStorage.getItem("mytoken");
  const Acls = JSON.parse(localStorage.getItem("acls"));
  const role = localStorage.getItem("myrole");
  const [open, setOpen] = useState(false);
  const [brandName, setbrandName] = React.useState();
  // const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };

  // console.log("brandnaemmee", brandName);

  const getBrand = () => {
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        // brandname = prop.name;

        setbrandName(prop.name);
      }
      return null;
    });
  };
  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    category: "",
    search: "",
  });

  const renderPhotos = (source) => {
    return <img src={source} alt="" width="200" height="200" />;
  };
  const { category } = inputs;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const handleFileSelect = (evt) => {
    if (evt.target.files) {
      const filesarray = Array.from(evt.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImg(filesarray[0]);
      // Array.from(evt.target.files).map((file) => URL.createObjectURL(file))
    }
    var files = evt.target.files;
    var file = files[0];
    setMyFiles(file);
  };

  const addCategory = (e) => {
    setOpen(true);
    const data = new FormData();
    data.append("image", myFiles);
    data.append("name", category);
    if (category !== "" && myFiles !== "") {
      axios
        .post(Environment.backendUrl + "/videoCategory/add", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setOpen(false);
          toast.success("Category Added", {
            position: "top-center",
            autoClose: 3000,
          });
          setInputs({
            category: "",
          });
          setSelectedImg("");
          window.$("#exampleModal34").modal("hide");
          window.location.reload();
        })
        .catch((err) => {
          setOpen(false);
          toast.error(err.response.data.msg, {
            position: "top-center",
            autoClose: 2000,
          });
        });
    } else {
      close();
      setOpen(false);
      toast.error("Category cannot be empty", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const close = () => {
    window.$("#exampleModal34").modal("hide");
  };
  const opeeennn = () => {
    window.$("#exampleModal34").modal("show");
  };
  useEffect(() => {
    getBrand();
  });

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const [selectedItem, setSelectedItem] = useState("");
  const items = ["Latest", "day", "week"];

  const [selectedItemfilter, setSelectedItemfilter] = useState("");
  const itemsfilter = ["Latest", "day", "week"];

  // console.log("brancd name", brandName)
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <div className="main-div-nav-head">
      <Backdrop className="loader" sx={{ color: "#fff" }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Navbar
        color={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : color
        }
        expand="md"
        className={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid className="main-header-top-change">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand>{brandName}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={isOpen}
            navbar
            className="justify-content-end text-center"
          >
            {/* <form>
              <InputGroup className="no-border">
                <Input name="search" value={search} onChange={handleChange} className={'form-control'} placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <button onClick={() => dispatch(inputAction(inputs.search))} type="button" className="nav-buttonss" > <i className="nc-icon nc-zoom-split" /></button>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form> */}

            {brandName === "Videos / Videos" && Acls?.videos?.create && (
              <Link to={`/admin/addvideos`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Video
                </button>
              </Link>
            )}
            {brandName === "Banner / Leaderboard Banners" && (
              <Link to={`/admin/addBanners/game`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Banner
                </button>
              </Link>
            )}
            {brandName === "Images & Banner" ? (
              <Link to={`/admin/addBanners/news`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Banner
                </button>
              </Link>
            ) : null}
            {brandName === "Other Banner" ? (
              <Link to={`/admin/addBanners/other`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Banner
                </button>
              </Link>
            ) : null}
            {/* {console.log("otherBanner", brandName)} */}
            {brandName === "Play To Earn" && Acls?.playToEarn?.create && (
              <Link to={`/admin/addplaytoearn`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Play to Earn
                </button>
              </Link>
            )}
            {brandName === "Upcoming" && Acls?.upComming?.create && (
              <Link to={`/admin/addupcoming`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Upcoming
                </button>
              </Link>
            )}
            {brandName === "Daily Challegne" &&
              Acls?.dailyChallenge?.create && (
                <Link to={`/admin/adddailychallange`}>
                  {" "}
                  <button type="button" className="blue-add-category">
                    {" "}
                    <img
                      src="\dashboard-assets\addddd.svg"
                      className="img-fluid pr-2"
                      alt=""
                    />{" "}
                    Add Challanges
                  </button>
                </Link>
              )}

            {brandName === "Tasks" && (
              <div className="newinputs">
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    This Week
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link to={`/admin/addtask`}>
                  <button type="button" className="blue-add-category">
                    {" "}
                    <img
                      src="\dashboard-assets\addddd.svg"
                      className="img-fluid pr-2"
                      alt=""
                    />{" "}
                    Add Task
                  </button>
                </Link>
              </div>
            )}

            {/* 
            {brandName === 'Task' &&

              <div className="newinputs">
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    This Week
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link to={`/admin/addtask/:id`}>
                  <button type="button" className="blue-add-category"> <img src="\dashboard-assets\addddd.svg" className="img-fluid pr-2" alt="" />   Add Task</button>
                </Link>
              </div>



            } */}

            {brandName === "Tasks Group" && (
              <div className="newinputs">
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">
                    This Week
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link to={`/admin/addgroup/:id`}>
                  <button type="button" className="blue-add-category">
                    {" "}
                    <img
                      src="\dashboard-assets\addddd.svg"
                      className="img-fluid pr-2"
                      alt=""
                    />{" "}
                    Add Groups
                  </button>
                </Link>
              </div>
            )}

            {brandName === "Landmarks" && Acls?.leaderBoard?.create && (
              <div className="custom-tab-bar d-flex justify-content-center p-0 border-0">
                <a
                  className="clanderdate"
                  onClick={() => setShowCalendar4(!showcalendar4)}
                >
                  <input
                    className="cutimixwclndr"
                    placeholder="12 Apr, 2023 -12 May, 2023"
                    type="date"
                  ></input>
                </a>
                {/* {showcalendar && ( */}
                {/* {showcalendar4 && (
                  <div className="cal set-custom-calendar-div">
                    <Calendar
                      numberOfMonths={2}
                      disableMonthPicker
                      disableYearPicker
                    />
                  </div>
                )} */}
                <Link to={`/admin/dailyleaderboard`}>
                  <button type="button" className="blue-add-category">
                    {" "}
                    Past Week
                  </button>
                </Link>
              </div>
            )}

            {/* {brandName === 'Rewards / LeaderBoard' && Acls?.leaderBoard?.create && <Link to={`/admin/dailyleaderboard`}>
              <button type="button" className="blue-add-category"> Past Week</button></Link>} */}

            {brandName === "On boarding" && (
              <Link to={`/admin/addonboarding`}>
                <button type="button" className="blue-add-category">
                  {" "}
                  <img src={user4} className="img-fluid pr-2" alt="img" /> Add
                  On Boarding
                </button>
              </Link>
            )}

            {brandName === "LeaderBoard / Detail" && <></>}
            {brandName === "Reward / This Week Reward" &&
              Acls?.weeksReward?.create && (
                <Link to={`/admin/thisweekrewardform`}>
                  {" "}
                  <button type="button" className="blue-add-category">
                    {" "}
                    <img
                      src="\dashboard-assets\addddd.svg"
                      className="img-fluid pr-2"
                      alt=""
                    />{" "}
                    Add Reward
                  </button>
                </Link>
              )}

            {brandName === "FAQs" && (
              <Link to={`/admin/AddFaq`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add FAQ
                </button>
              </Link>
            )}
            {brandName === "Coins" && Acls?.coins?.create && (
              <Link to={`/admin/AddCoins`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Coin
                </button>
              </Link>
            )}
            {brandName === "Meme Coins" && (
              <Link to={`/admin/AddMemeCoins`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add MemeCoin
                </button>
              </Link>
            )}
            {brandName === "Push Notifications" && (
              <Link to={`/admin/sendnotification`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  Send Notification
                </button>
              </Link>
            )}
            {/* {(window.location.href.indexOf(prop.layout + prop.path) == a} */}
            {brandName === "Videos / Course" && Acls?.course?.create && (
              <Link>
                {" "}
                <button
                  type="button"
                  className="blue-add-category"
                  data-toggle="modal"
                  onClick={opeeennn}
                >
                  {" "}
                  Add Course
                </button>
              </Link>
            )}
            {/* {brandName === 'add badges'  && <Link to={`/admin/sendbadges`}> <button type="button" className="blue-add-category">  ADD Badges</button></Link>} */}

            {brandName === "add badges" && (
              <Link to={`/admin/sendbadges`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Badges
                </button>
              </Link>
            )}

            {brandName === "Milestone" && (
              <Link to={`/admin/addmilestone`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Milestone
                </button>
              </Link>
            )}

            {brandName === "Editmilestone" && (
              <Link to={`/admin/editmilestone`}></Link>
            )}

            {brandName === "Social Links" && (
              <Link to={`/admin/addsociallinks`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Links
                </button>
              </Link>
            )}
            {brandName === "xBlue Management" && (
              <Link to={`/admin/addboltcategory`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add New
                </button>
              </Link>
            )}

            {brandName === "Subscription" && (
              <Link to={`/admin/addsubscription/4577432`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Subcription
                </button>
              </Link>
            )}
            {brandName === "Raffles" && (
              <Link to={`/admin/addraffles`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Raffles
                </button>
              </Link>
            )}

            {brandName === "Raffles categories" && (
              <Link to={`/admin/addcategoryraffle/4577432`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Categories
                </button>
              </Link>
            )}
            {brandName === "Categories" && (
              <Link to={`/admin/addproductraffle`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Products
                </button>
              </Link>
            )}
            {brandName === "Addnewcategorry" && (
              <Link to={`/admin/addnewcategorry:id`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Products
                </button>
              </Link>
            )}

            {brandName === "Products" && (
              <Link to={`/admin/addproducts/:id`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Products
                </button>
              </Link>
            )}
            {brandName === "Category" && (
              <Link to={`/admin/addnewcategory/:id`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Categories
                </button>
              </Link>
            )}
            {console.log(brandName, "brandName")}
            {brandName == "Product type" && (
              <Link to={`addproduct-type`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                 Add Product type
                </button>
              </Link>
            )}
            {brandName === "Add Category" && (
              <Link to={`/admin/addproducts`}> </Link>
            )}
                {brandName === "Product type/Detail" && (
              <Link to={`/admin/addproduct-type`}> </Link>
            )}
            {brandName === "Admins" && (
              <Link to={`/admin/AddAdminUser`}>
                {" "}
                <button
                  type="button"
                  className="blue-add-category"
                  data-toggle="modal"
                >
                  {" "}
                  Add User
                </button>
              </Link>
            )}
            {brandName === "Role Management" && (
              <Link to={`/admin/addrolemanagement`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Role
                </button>
              </Link>
            )}

            {brandName === "Ambassadors Applications" &&
              role === "super-admin" && (
                <div className="newinputs">
                  <div className="inputoutermain">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="ambrinputicon"
                    >
                      <path
                        d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                        fill="#A3A3A3"
                      />
                      <path
                        d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                        fill="#A3A3A3"
                      />
                    </svg>
                    <input
                      type="text"
                      name="full_name"
                      className="ambassadorinput"
                      placeholder="Search"
                    />
                  </div>

                  <Dropdown className="amer_dropdonfst">
                    <Dropdown.Toggle id="dropdown-basic">
                      Sort by
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mydropppp">
                      <Dropdown.Item href="#/action-1" className="namediv">
                        Name
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="namediv">
                        Join Date
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="namediv">
                        Verification Status
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="namediv">
                        Referrals
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="amer_dropdonfst">
                    <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                      >
                        <line
                          x1="1"
                          y1="1"
                          x2="17"
                          y2="1"
                          stroke="#4F4E69"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <line
                          x1="3"
                          y1="5"
                          x2="15"
                          y2="5"
                          stroke="#4F4E69"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <line
                          x1="5"
                          y1="9"
                          x2="13"
                          y2="9"
                          stroke="#4F4E69"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <line
                          x1="8"
                          y1="13"
                          x2="10"
                          y2="13"
                          stroke="#4F4E69"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                      Filters
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mydropppp">
                      <Dropdown.Item href="#/action-1" className="namediv">
                        Name
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="namediv">
                        Join Date
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="namediv">
                        Verification Status
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3" className="namediv">
                        Referrals
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            {brandName === "Support" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {brandName === "Randomizer" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "Support/Ticket Details" &&
              role === "super-admin" && <></>}
            {brandName === "Ambassadors Claims" && role === "super-admin" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {brandName === "Leaderboards" && role === "super-admin" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "Launchpad" && (
              <Link to={`/admin/raffles`}>
                {" "}
                <button type="button" className="blue-add-category">
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Add Raffles
                </button>
              </Link>
            )}

            {brandName === "Users" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst ">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "User / Detail" && (
              <div className="">
                <div className="custom-tab-bar  border-0 w-100 middivbut">
                  <div className="main-outer-p">
                    <div className="main-p">
                      <p>Verify user</p>
                    </div>
                    <div className="main-switch-nn">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitches"
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitches"
                        ></label>
                      </div>
                    </div>
                  </div>

                  <a
                    className="clanderdatee mycalender"
                    onClick={(handleButtonClick) =>
                      setShowCalendar4(!showcalendar4)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M20 12V18C20 20.21 18.21 22 16 22H8C5.79 22 4 20.21 4 18V12C4 11.45 4.45 11 5 11H6.97C7.52 11 7.97 11.45 7.97 12V15.14C7.97 15.88 8.38 16.56 9.03 16.91C9.32 17.07 9.64 17.15 9.97 17.15C10.35 17.15 10.73 17.04 11.06 16.82L12.01 16.2L12.89 16.79C13.5 17.2 14.28 17.25 14.93 16.9C15.59 16.55 16 15.88 16 15.13V12C16 11.45 16.45 11 17 11H19C19.55 11 20 11.45 20 12Z"
                        fill="#0F7EFE"
                      />
                      <path
                        d="M21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z"
                        fill="#0F7EFE"
                      />
                      <path
                        d="M11.6408 4.99945H6.12076C5.78076 4.62945 5.79076 4.05945 6.15076 3.69945L7.57076 2.27945C7.94076 1.90945 8.55076 1.90945 8.92076 2.27945L11.6408 4.99945Z"
                        fill="#0F7EFE"
                      />
                      <path
                        d="M17.8696 4.99945H12.3496L15.0696 2.27945C15.4396 1.90945 16.0496 1.90945 16.4196 2.27945L17.8396 3.69945C18.1996 4.05945 18.2096 4.62945 17.8696 4.99945Z"
                        fill="#0F7EFE"
                      />
                      <path
                        d="M13.9695 11C14.5195 11 14.9695 11.45 14.9695 12V15.13C14.9695 15.93 14.0795 16.41 13.4195 15.96L12.5195 15.36C12.1895 15.14 11.7595 15.14 11.4195 15.36L10.4795 15.98C9.81945 16.42 8.93945 15.94 8.93945 15.15V12C8.93945 11.45 9.38945 11 9.93945 11H13.9695Z"
                        fill="#0F7EFE"
                      />
                    </svg>
                  </a>
                  {/* {showcalendar && ( */}
                  {showcalendar4 && (
                    <div className="cal set-custom-calendar-div">
                      <Calendar
                        numberOfMonths={2}
                        disableMonthPicker
                        disableYearPicker
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {brandName === "User / Detail" && role === "super-admin" && <></>}
            {brandName === "Gifts" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  onClick={() => setShoww(true)}
                  type="button"
                  className="blue-add-category"
                >
                  {" "}
                  <img
                    src="\dashboard-assets\addddd.svg"
                    className="img-fluid pr-2"
                    alt=""
                  />{" "}
                  Send Gifts
                </button>
              </div>
            )}

            {/* orders navbar */}
            {brandName === "Orders" && (
              <div className="ordersnavbar">
                <div className="searchinput">
                  <input
                    type="text"
                    placeholder="search"
                    className="orderinput"
                  />
                  <img
                    src="\dashboard-assets\Search.svg"
                    alt="search"
                    className="searchimg"
                  />
                </div>
                <div class="dropdown droped">
                  <button
                    class="dropbtn dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedItem ? (
                      <>
                        <h6 style={{ color: "black" }}>{selectedItem}</h6>
                      </>
                    ) : (
                      "Sort by"
                    )}
                  </button>
                  <div class="dropdown-menu dropmain">
                    {items.map((item) => (
                      <a
                        className="dropdown-item drop"
                        onClick={() => setSelectedItem(item)}
                      >
                        {item}
                      </a>
                    ))}
                  </div>
                </div>
                <div class="dropdown droped">
                  <button
                    class="dropbtnfilter"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedItemfilter ? (
                      <>
                        <h6 style={{ color: "black" }}>{selectedItemfilter}</h6>
                      </>
                    ) : (
                      <p>
                        <img
                          src="\dashboard-assets\Group 17.svg"
                          alt="filters"
                        />
                        Filters
                      </p>
                    )}
                  </button>
                  <div class="dropdown-menu dropmain">
                    {itemsfilter.map((item) => (
                      <a
                        className="dropdown-item drop"
                        onClick={() => setSelectedItemfilter(item)}
                      >
                        {item}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {brandName === "staking Leaderboard" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {brandName === "Mystery Box Reward" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "Profile Images" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "Banner images" && (
              <div className="newinputs">
                <div className="inputoutermain">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="ambrinputicon"
                  >
                    <path
                      d="M7.66927 14.5026C3.9026 14.5026 0.835938 11.4359 0.835938 7.66927C0.835938 3.9026 3.9026 0.835938 7.66927 0.835938C11.4359 0.835938 14.5026 3.9026 14.5026 7.66927C14.5026 11.4359 11.4359 14.5026 7.66927 14.5026ZM7.66927 1.83594C4.44927 1.83594 1.83594 4.45594 1.83594 7.66927C1.83594 10.8826 4.44927 13.5026 7.66927 13.5026C10.8893 13.5026 13.5026 10.8826 13.5026 7.66927C13.5026 4.45594 10.8893 1.83594 7.66927 1.83594Z"
                      fill="#A3A3A3"
                    />
                    <path
                      d="M14.6676 15.1676C14.5409 15.1676 14.4143 15.1209 14.3143 15.0209L12.9809 13.6876C12.7876 13.4943 12.7876 13.1743 12.9809 12.9809C13.1743 12.7876 13.4943 12.7876 13.6876 12.9809L15.0209 14.3143C15.2143 14.5076 15.2143 14.8276 15.0209 15.0209C14.9209 15.1209 14.7943 15.1676 14.6676 15.1676Z"
                      fill="#A3A3A3"
                    />
                  </svg>
                  <input
                    type="text"
                    name="full_name"
                    className="ambassadorinput"
                    placeholder="Search"
                  />
                </div>

                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle id="dropdown-basic">Sort by</Dropdown.Toggle>

                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="amer_dropdonfst">
                  <Dropdown.Toggle className="filyerbyn" id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                    >
                      <line
                        x1="1"
                        y1="1"
                        x2="17"
                        y2="1"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="5"
                        x2="15"
                        y2="5"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="5"
                        y1="9"
                        x2="13"
                        y2="9"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <line
                        x1="8"
                        y1="13"
                        x2="10"
                        y2="13"
                        stroke="#4F4E69"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    Filters
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mydropppp">
                    <Dropdown.Item href="#/action-1" className="namediv">
                      Name
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="namediv">
                      Join Date
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Verification Status
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="namediv">
                      Referrals
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {brandName === "Probability" && (
              <div className="newinputs">
                {/* <button type="button" className="blue-add-category px-4">   Update</button> */}
              </div>
            )}
          </Collapse>
        </Container>
      </Navbar>
      {/* Add Category Modal */}
      <div className="main-modal-one">
        <div
          class="modal fade modal-zzz"
          id="exampleModal34"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog daily-profile-modal">
            <div class="modal-content daily-profile-modal-inner">
              <div class="modal-body modal-body-main">
                <div className="main-outter">
                  <div className="row main-cardssss">
                    <div className="col-md-12 col-12">
                      <div className="flux-b">
                        <h3>Add Course</h3>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Add Thumbnail</label>
                        <div className="main-image-div">
                          <div className="choose-filessssss">
                            <form>
                              <div className="sbhjsbd">
                                {selectedImg ? renderPhotos(selectedImg) : null}
                                <div className="layerss"></div>
                              </div>

                              <div className="nsjd">
                                <label className="" for="filess">
                                  <i class="fas fa-cloud-upload-alt"></i>
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  id="filess"
                                  onChange={handleFileSelect}
                                  className="form-control d-none"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Course Name</label>
                        <input
                          type="text"
                          name="category"
                          value={category}
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (submitted && !category ? " is-invalid" : "")
                          }
                          placeholder="Enter Category Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="button-modal-daily">
                        <button
                          type="button"
                          className="button-main-daily"
                          onClick={addCategory}
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="button-main-dailys"
                          data-dismiss="modal"
                          aria-label="Close"
                          onChange={close}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* send gift modal here......................... */}
      <Modal
        className="sendgift-modal"
        show={showw}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Modal.Title>Send Gift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-field">
            <h6>Select Item</h6>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Select Item
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
                <a class="dropdown-item" href="#">
                  Select Item
                </a>
              </div>
            </div>
          </div>
          <div className="option-field">
            <h6>Search with email address</h6>
            <input type="text" placeholder="Enter email address" />
          </div>
          <div className="twice-btn">
            <button onClick={handleClose} className="btn-cancel">
              Cancel
            </button>
            <button className="btn-send">Send</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
