import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import "./badges.scss";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";

function PushBadges(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [baseImage, setBaseImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [baseImage2, setBaseImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [baseImage3, setBaseImage3] = useState(null);
  const token = localStorage.getItem('mytoken')
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [myFiles, setMyFiles] = useState([]);
  const [selectedItems, setSelectedItems] = useState("");
  const history = useHistory();

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setImage(file);

      e.target.value = "";
    }
  };

  const uploadImages2 = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setBaseImage2(base64);
      setImage2(file);

      e.target.value = "";
    }
  };

  const uploadImages3 = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setBaseImage3(base64);
      setImage3(file);

      e.target.value = "";
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getTaskDetail(id);
    }
  }, [id]);

  const getTaskDetail = (id) => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + `/api1/admin/get/badgeById?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setDescription(response.data?.description);
        setName(response.data?.name);
        setBaseImage(response.data?.imageLink);
        setSelectedItems(response.data?.type);
        setBaseImage2(response.data?.name_imageLink);
        setBaseImage3(response.data?.otherImage);


      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };






  const addTask = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !description ||
      !image ||
      !image3 ||
      !selectedItems
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (description) {
      formData.append("description", description);
    }

    if (image) {
      formData.append("image", image);
    }
    if (selectedItems) {
      formData.append("type", selectedItems);
    }if (image2) {
      formData.append("nameImage", image2);
    }if (image3) {
      formData.append("otherImage", image3);
    }

    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/add/Badge",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        history.push("/admin/badges");
        toast.success(res?.data?.msg);
        baseImage();

        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        // handleClose();
      })
      .catch((err) => {
        console.log(err?.da, "aa");
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else if (err?.response?.statusCode == 413) {
          toast.error("Image size to large");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };


  const editTask = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !description ||
      !selectedItems
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (description) {
      formData.append("description", description);
    }

    if (image) {
      formData.append("image", image);
    }if (image2) {
      formData.append("nameImage", image2);
    }if (image3) {
      formData.append("otherImage", image3);
    }
    if (selectedItems) {
      formData.append("type", selectedItems);
    } if (id) {
      formData.append("badge_id", id);
    }

    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/edit/Badge",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        history.push("/admin/badges");
        toast.success(res?.data?.msg);
        baseImage();

        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        // handleClose();
      })
      .catch((err) => {
        console.log(err?.da, "aa");
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else if (err?.response?.statusCode == 413) {
          toast.error("Image size to large");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };


  return (
    <>
      <div className="content">
        <section className="addbanner1234 send-notifications card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-7  col-sm-12 order-md-0 order-1">
                  <div className="bbadges_flex">
                    <div className="form-group rightside-for-group">
                      <label htmlFor="badgeimgdivmark">Badge Image</label>
                      <div className="dashed-border-new upload">
                        <input
                          onChange={uploadImages}
                          type="file"
                          className="d-none"
                          id="upload"
                        />
                        <label htmlFor="upload">
                          <img
                            src={baseImage ? baseImage : "/dashboard-assets/upload.svg"}
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          {!baseImage && (
                            <p className="paraa1">
                              Drag & drop or <span className="paraa2">Browse</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="form-group rightside-for-group">
                      <label htmlFor="exampleInputsymbol">Name Image</label>
                      <div className="dashed-border-new upload">
                        <input
                          onChange={uploadImages2}
                          type="file"
                          className="d-none"
                          id="upload2"
                        />
                        <label htmlFor="upload2">
                          <img
                            src={baseImage2 ? baseImage2 : "/dashboard-assets/upload.svg"}
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          {!baseImage2 && (
                            <p className="paraa1">
                              Drag & drop or <span className="paraa2">Browse</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>


                    
                    <div className="form-group rightside-for-group">
                      <label htmlFor="exampleInputsymbol">Intial Badge Image</label>
                      <div className="dashed-border-new upload">
                        <input
                          onChange={uploadImages3}
                          type="file"
                          className="d-none"
                          id="upload3"
                        />
                        <label htmlFor="upload3">
                          <img
                            src={baseImage3 ? baseImage3 : "/dashboard-assets/upload.svg"}
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          {!baseImage3 && (
                            <p className="paraa1">
                              Drag & drop or <span className="paraa2">Browse</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 main-send-message">
                      {/* <h3>Add Badges</h3> */}
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Name
                        </label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          class="form-control conrtoldivvvv"
                          name="name"
                          id="exampleFormControlTextarea1"
                          placeholder="Title"
                          rows="2"
                        ></input>
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                      <div class="form-group ">
                        <label className="padd-top mytab1" for="example">
                          Type
                        </label>
                        <div class="dropdown">
                          <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55">
                                {
                                  selectedItems ? <><h6 style={{ color: "black", fontWeight: '400' }}>{selectedItems}</h6></> : "Select Type"}
                              </p>
                              <i class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                                  <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              
                                    <a onClick={() => {setSelectedItems("Task");}}  className="dropdown-item taskdiv minerdivvvv" >Tasks </a>
                                    <a onClick={() => {setSelectedItems("Invite");}}  className="dropdown-item taskdiv minerdivvvv" >Invite </a>
                                    <a onClick={() => {setSelectedItems("Asset");}}  className="dropdown-item taskdiv minerdivvvv" > Assets</a>
                                    <a onClick={() => {setSelectedItems("Xblue");}}  className="dropdown-item taskdiv minerdivvvv" > xBLUE</a>
                                    <a onClick={() => {setSelectedItems("Streak");}}  className="dropdown-item taskdiv minerdivvvv" >Streak </a>
                                    <a onClick={() => {setSelectedItems("Community");}}  className="dropdown-item taskdiv minerdivvvv" >Discord </a>
                                    <a onClick={() => {setSelectedItems("MoonTickets");}}  className="dropdown-item taskdiv minerdivvvv" >Moon Tickets</a>
                                    <a onClick={() => {setSelectedItems("MysteryPack");}}  className="dropdown-item taskdiv minerdivvvv" >Mystery Packs </a>
                                    <a onClick={() => {setSelectedItems("staking");}}  className="dropdown-item taskdiv minerdivvvv" >Staking </a>
                                
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Description
                        </label>
                        <input
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          class="form-control conrtoldivvvv"
                          id="exampleFormControlTextarea1"
                          name="description"
                          placeholder="Your message"
                          rows="5"
                        ></input>
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div class="form-group">
                      <div className="videopage">

                        <button onClick={id ? (e) => editTask(e) : (e) => addTask(e)} className="red-bybuy"> {id ? "Update" : "Publish"}</button>
                        <Link to="/admin/badges">
                          <button className="red-wy-y">Cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}

export default PushBadges;