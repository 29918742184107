
import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Environment from "utils/Environment";
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import './notification.scss';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { Dropdown } from "reactstrap";
// reactstrap components
function PushNotification() {





    // const images = importAll(require.context('assets/img/userflow', false, /\.(png|jpe?g|svg)$/));
    return (
        <>
        
            <div className="content">
                <section className="addbanner1234 send-notifications card">
                    <form>
                        <div className="inner-submit-lower-div">
                            <div class="row">
                                <div class="col-md-7 order-md-0 order-1">
                                    <div className="row">
                                        <div className="col-12 main-send-message">
                                            {/* <h3>Send Manual message</h3> */}
                                            <div class="form-group">
                                                <label className="newtitlee" for="example">Title</label>
                                                <input class="form-control myinputdivr" id="exampleFormControlTextarea1" name="title"  placeholder="Title" rows="2"></input>
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                            </div>
                                            <div class="form-group">
                                                <label className="newtitlee" for="example">Link 1</label>
                                                <input class="form-control myinputdivr" id="exampleFormControlTextarea1" name="link1"  placeholder="Enter Link 1" rows="2"></input>
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                            </div>
                                            <div class="form-group">
                                                <label className="newtitlee" for="example">Link 2</label>
                                                <input class="form-control myinputdivr" id="exampleFormControlTextarea2" name="link2"  placeholder="Enter Link 2" rows="2"></input>
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                            </div>
                                            <div class="form-group">
                                                <label className="newtitlee" for="example">Message</label>
                                                <input class="form-control myinputdivr" id="exampleFormControlTextarea1" name="message"  placeholder="Your message" rows="5"></input>
                                                {/* {Object.keys(projectNameError).map((key)=>{
                                                          console.log("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12">

                                            <div class="form-group">
                                                <label className="newtitlee" for="example">Send message to</label>
                                                <div className="dropdown buttons-list-all cshbshjbvch">
                                                    <button className="button-listing" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Verified Users
                                                        <i class=""><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                                                            <path d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z" fill="#3654D6" />
                                                        </svg></i>
                                                    </button>

                                                    <div className="dropdown-menu zscscsac" aria-labelledby="dropdownMenuButton">
                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div class="form-group choose-category">
                                                <label class="warrrryyy" for="exampleRadios1">
                                                    Send to Platform
                                                </label>
                                            </div>
                                        </div>
                                        <div className=" col-md-4 col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="description" value={'All'}  id="exampleRadios1" />
                                                <label class="form-check-label" for="exampleRadios1">
                                                    All
                                                </label>
                                            </div>
                                        </div>
                                        <div className=" col-md-4 col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="description" value={'Android Only'}  id="exampleRadios1" />
                                                <label class="form-check-label" for="exampleRadios1">
                                                    Android Only
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="description" value={'IOS only'} id="exampleRadios1" />
                                                <label class="form-check-label" for="exampleRadios1">
                                                    IOS only
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div class="form-group">
                                            <div className="videopage">
                                                <button className="red-bybuy" >Publish</button>
                                                <Link to="/admin/notification">
                                                    <button className="red-wy-y">Cancel</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                
                                </div>
                                <div class="col-md-4 order-md-1 order-0">
                                <div className="upload-img ">
                                <p className=" manydivbart">Add Thumbnail</p>
                      <p className="imgsize">Image size: 48x48</p>
                      <div className="upload sidedotsaa ">
                        <img src="" className="imgProfile" alt="" />
                        <label htmlFor="upload">
                          <img
                            src="\dashboard-assets\upload.svg"
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          <p className="paraa1">Drag & drop or <span className="paraa2"> Browse</span></p>
                        </label>
                      </div>

                      {/* <p className="text-danger">Image is missing!</p> */}
                      <input
                        type="file"
                        name="image"
                        className="d-none"
                        id="upload"
                      />
                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}

export default PushNotification;
