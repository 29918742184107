import React, { useEffect, useState } from "react";
import "./rewardlea.scss";
// reactstrap components
import { Nav, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Modal } from "react-bootstrap";
function Streakrewards() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop> */}
      <div className="content">
        <section className="thisweekrewards card">
        <div className="maintableauser">
          <div className="container-fluid">
          
          <div class="table-responsive">
              <table class="table ">
                <thead>
                  <tr>
                    <th>Prize</th>
                    <th>Title</th>
                    <th>Reward</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody className="main-t-body-text">
                  <tr>
                    <td>
                      <div className="tabdivvv">
                        <img src="\dashboard-assets\person.png" />
                        <p className="para5555">Metaverse Avatar - Rare</p>
                      </div>
                    </td>
                    <td className="main-image">Sign Up and get 20 Bolts</td>
                    <td className="">20 Bolts</td>
                    <td className="">
                      <svg
                       className="edit"
                        onClick={handleShow}
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_277_141271)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_277_141271">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="tabdivvv">
                        <img src="\dashboard-assets\person.png" />
                        <p className="para5555">Metaverse Avatar - Rare</p>
                      </div>
                    </td>
                    <td className="main-image">Sign Up and get 20 Bolts</td>
                    <td className="">20 Bolts</td>
                    <td className="">
                      <svg
                       className="edit"
                        onClick={handleShow}
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_277_141271)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_277_141271">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="tabdivvv">
                        <img src="\dashboard-assets\person.png" />
                        <p className="para5555">Metaverse Avatar - Rare</p>
                      </div>
                    </td>
                    <td className="main-image">Sign Up and get 20 Bolts</td>
                    <td className="">20 Bolts</td>
                    <td className="">
                      <svg className="edit"
                        onClick={handleShow}
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_277_141271)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_277_141271">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="tabdivvv">
                        <img src="\dashboard-assets\person.png" />
                        <p className="para5555">Metaverse Avatar - Rare</p>
                      </div>
                    </td>
                    <td className="main-image">Sign Up and get 20 Bolts</td>
                    <td className="">20 Bolts</td>
                    <td className="">
                      <svg
                       className="edit"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_277_141271)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_277_141271">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="tabdivvv">
                        <img src="\dashboard-assets\person.png" />
                        <p className="para5555">Metaverse Avatar - Rare</p>
                      </div>
                    </td>
                    <td className="main-image">Sign Up and get 20 Bolts</td>
                    <td className="">20 Bolts</td>
                    <td className="">
                      <svg
                       className="edit"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_277_141271)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 16V12C5 11.7348 5.10533 11.4804 5.29293 11.2929L16.2929 0.292933C16.4804 0.1054 16.7348 0 17 0C17.2652 0 17.5196 0.1054 17.707 0.292867L21.707 4.29287C22.0975 4.6834 22.0975 5.31653 21.707 5.70707L10.7071 16.7071C10.5195 16.8946 10.2652 17 10 17H6C5.44773 17 5 16.5523 5 16ZM9.17199 10.2422L7 12.4142V15H9.5858L11.7578 12.828L11.7929 12.7929L9.2071 10.2071L9.17199 10.2422Z"
                            fill="#D6D6D6"
                          />
                          <path
                            d="M21 10.0001C20.4477 10.0001 20 10.4478 20 11.0001V20H2V2H11C11.5523 2 12 1.55227 12 1C12 0.447733 11.5523 0 11 0H1C0.447733 0 0 0.447733 0 1V21C0 21.5523 0.447733 22 1 22H21C21.5523 22 22 21.5523 22 21V11.0001C22 10.4477 21.5523 10.0001 21 10.0001Z"
                            fill="#D6D6D6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_277_141271">
                            <rect width="22" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <ToastContainer style={{ fontSize: 20 }} /> */}
            </div>
          
          </div>
          <div className='Paginationlattable mytbalepage'>
              <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button>
              <Pagination>
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item active>{5}</Pagination.Item>
                <Pagination.Item>{6}</Pagination.Item>
              </Pagination>
              <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button>
            </div>
          </div>
        </section>
      </div>
      <Modal
        className="ambmodalmain userlastmodal  addrewads"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>User Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body className="userlastmodrewards">
          <div class="col-xl-8 col-md-12 order-md-1 order-0">
            <div class="form-group rightside-for-group">
              <label for="exampleInputsymbol" className="sdfdfsdfsdfdsfsdf">
                Prize Image
              </label>
              <p className="nnnnhhew ">Image size: 183x177</p>
              <div className="dashed-border-new">
                <div className="main-image-div">
                  {/* <img src={`${images['addvid2.png']['default']}`} alt="" /> */}

                  <div className="choose-filessss">
                    {/* <p>Drag & drop or <span></span></p> */}

                    <form>
                      <label htmlFor="upload">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="61"
                          height="61"
                          viewBox="0 0 61 61"
                          fill="none"
                        >
                          <path
                            d="M47.6633 52.6918H37.804H35.1492H34.5758V39.4672H38.9009C39.9978 39.4672 40.6459 38.2208 39.9978 37.3234L31.5844 25.6818C31.0484 24.9339 29.9391 24.9339 29.4031 25.6818L20.9898 37.3234C20.3416 38.2208 20.9773 39.4672 22.0866 39.4672H26.4117V52.6918H25.8384H23.1835H11.7538C5.21005 52.3303 0 46.2104 0 39.5794C0 35.005 2.48038 31.0165 6.15734 28.8602C5.8208 27.9503 5.6463 26.9781 5.6463 25.956C5.6463 21.2819 9.42297 17.5053 14.0971 17.5053C15.1067 17.5053 16.0789 17.6798 16.9888 18.0163C19.6935 12.2827 25.5268 8.30664 32.3073 8.30664C41.0821 8.3191 48.3114 15.0373 49.134 23.6003C55.8772 24.7594 61 31.004 61 38.0713C61 45.6246 55.1169 52.1683 47.6633 52.6918Z"
                            fill="#EDEDEE"
                          />
                        </svg>
                        <h6 className="drtuopssdsdsdsd">
                          Drag & Drop or{" "}
                          <span className="commocxcxcnfffr">Browse</span>
                        </h6>
                      </label>
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        className={"form-control d-none"}
                      />
                      {/* <h4 style={{ fontWeight: "bold" }}>Files</h4> */}
                      {/* {myFiles[0]} */}
                    </form>
                  </div>
                  {/* <img src={logo ? logo : require("../../static/images/submit-form/cloud.png")} alt="" /> */}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div class="form-group">
            <label className="rewardslable" for="example">
              Reward
            </label>
            <input
              type="text"
              name="rewards"
              className="rewardinput"
              placeholder="Enter Rewards"
            />
          </div>
          <div className="videopagerewards">
            <button className="red-bnew">Save</button>
            <Link to="/admin/thisweekreward" className="sttstctct">
              {" "}
              <button className="red-wneww w-100">Cancel</button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Streakrewards;
