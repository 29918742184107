import React from 'react'
import "./raffles.scss"

import { Link } from 'react-router-dom';
const RafflesCategories = () => {
    return (
        <>
            

            <div className="content">
                <div className="raffles">
                    <div class="table-responsive">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Total items</th>
                                   <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody className="main-t-body-text" >
                                        <tr>
                                            <td className='techdiv'>Tech</td>
                                            <td className='techdiv'>10 items</td>
                                         <td>
                                            <Link to={`/admin/addcategoryraffle/:id/`}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='techdiv'>Tech</td>
                                            <td className='techdiv'>10 items</td>
                                         <td>
                                            <Link to={`/admin/addcategoryraffle/:id/`}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='techdiv'>Tech</td>
                                            <td className='techdiv'>10 items</td>
                                         <td>
                                            <Link to={`/admin/addcategoryraffle/:id/`}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='techdiv'>Tech</td>
                                            <td className='techdiv'>10 items</td>
                                         <td>
                                            <Link to={`/admin/addcategoryraffle/:id/`}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                        </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RafflesCategories
