let Environment = {
  // backendUrl: "https://api-staging.bluemoon.io", //staging url
  backendUrl: "https://api-be.bluemoon.io", //Production url
  // backendUrl: "http://ec2-13-250-127-20.ap-southeast-1.compute.amazonaws.com:3000" //staging url
  // backendUrl: "https://api.legionnetwork.io/admin" //production url
  // backendUrl: "http://192.168.20.141:3027/admin"
  // backendUrl : "http://192.168.30.115:3000"
  // http://54.191.140.38:38451
};

export default Environment;
