import React, { useEffect, useState } from "react";
import "./coin1.scss";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// const items = ["Action", "Another action", "Something else"];
function AddAdminUser(props) {
  return (
    <>
      <div className="content">
        <section className="addbanner12345 card njdhbcdhbc">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-8 order-md-0 order-1">
                  <div className="row">
                    <div className="row">
                      <div className="col-xl-8 col-sm-12">
                        <div class="form-group">
                          <label for="example">Role Name</label>
                          <input
                            type="text"
                            name="full_name"
                            value=""
                            className={"form-control"}
                            placeholder="Name"
                          />
                          {/* {Object.keys(TitleError).map((key) => {
                                                        return <p className="inputErrors">{TitleError[key]}</p>
                                                    })} */}
                        </div>
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        {/* <div class="form-group">
                                                    <label for="example">Admin Role</label>
                                                    <input type="text" name="role" value={inputs.name} onChange={handleChange1} className={'form-control'} placeholder="Enter Role of Admin Eg reward-Admin" />
                                                    {Object.keys(TitleError).map((key) => {
                                                        return <p className="inputErrors">{TitleError[key]}</p>
                                                    })}
                                                </div> */}
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        <div class="form-group">
                          <label for="example">Description</label>
                          <input
                            type="text"
                            name="email"
                            value=""
                            className={"form-control"}
                            placeholder="Enter of Admin"
                          />
                        </div>
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        <h1 className="perdiv">Permissions</h1>
                        <div class="dropdownnn">
                          <a
                            class="btn   warrrr"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Permissions
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="6"
                              viewBox="0 0 9 6"
                              fill="none"
                            >
                              <path
                                d="M8.33877 0.427734H4.52466H1.09013C0.502401 0.427734 0.208538 1.20833 0.624845 1.66592L3.79613 5.15169C4.30427 5.71022 5.13076 5.71022 5.6389 5.15169L6.84496 3.82602L8.81018 1.66592C9.22036 1.20833 8.9265 0.427734 8.33877 0.427734Z"
                                fill="#3553D5"
                              />
                            </svg>
                          </a>
                          <div class="dropdown-menu">
                            <a class="dropdown-itemw" href="#">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M9.58464 18.1263C4.8763 18.1263 1.04297 14.293 1.04297 9.58464C1.04297 4.8763 4.8763 1.04297 9.58464 1.04297C14.293 1.04297 18.1263 4.8763 18.1263 9.58464C18.1263 14.293 14.293 18.1263 9.58464 18.1263ZM9.58464 2.29297C5.55964 2.29297 2.29297 5.56797 2.29297 9.58464C2.29297 13.6013 5.55964 16.8763 9.58464 16.8763C13.6096 16.8763 16.8763 13.6013 16.8763 9.58464C16.8763 5.56797 13.6096 2.29297 9.58464 2.29297Z"
                                  fill="#3553D5"
                                />
                                <path
                                  d="M18.3365 18.9595C18.1781 18.9595 18.0198 18.9012 17.8948 18.7762L16.2281 17.1095C15.9865 16.8678 15.9865 16.4678 16.2281 16.2262C16.4698 15.9845 16.8698 15.9845 17.1115 16.2262L18.7781 17.8928C19.0198 18.1345 19.0198 18.5345 18.7781 18.7762C18.6531 18.9012 18.4948 18.9595 18.3365 18.9595Z"
                                  fill="#3553D5"
                                />
                              </svg>
                              Search
                            </a>
                            <a class="dropdown-itemw" href="#">
                              <div class="formcheck_newwwwws">
                                <label class="checkBox m-0 warrrrw">
                                  <input
                                    type="checkbox"
                                    id="ch1"
                                    className="boxxxxx"
                                  />
                                  <p className="inputtext">Users</p>
                                </label>
                              </div>
                            </a>
                            <a class="dropdown-itemw" href="#">
                              <div class="formcheck_newwwwws">
                                <label class="checkBox m-0 warrrrw">
                                  <input
                                    type="checkbox"
                                    id="ch1"
                                    className="boxxxxx"
                                  />
                                  <p className="inputtext">Bolt Store</p>
                                </label>
                              </div>
                            </a>
                            <a class="dropdown-itemw" href="#">
                              <div class="formcheck_newwwwws">
                                <label class="checkBox m-0 warrrrw">
                                  <input
                                    type="checkbox"
                                    id="ch1"
                                    className="boxxxxx"
                                  />
                                  <p className="inputtext">Videos</p>
                                </label>
                              </div>
                            </a>
                            <a class="dropdown-itemw" href="#">
                              <button className="butttttt">Add Selected</button>
                            </a>
                          </div>
                        </div>
                        <div className="userdivv">
                          <h6 className="userfloooow">Users</h6>
                          <div className="mainshead">
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">View</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Create</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Create</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Delete</p>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="userdivv">
                          <h6 className="userfloooow">Courses</h6>
                          <div className="mainshead">
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">View</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Create</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Create</p>
                              </label>
                            </div>
                            <div class="formcheck_newwwwws">
                              <label class="checkBox m-0 warrrrw">
                                <input
                                  type="checkbox"
                                  id="ch1"
                                  className="boxxxxx"
                                />
                                <p className="inputtextt">Delete</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-8 col-sm-12">
                      <div class="form-group">
                        <div className="videopage">
                          <button className="red-wy-y">Cancel</button>
                          <button className=" red-bybuy">Publish</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}

export default AddAdminUser;
