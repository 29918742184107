import React, { useState } from 'react'
import "./subscription.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
const Addsubscription = (props) => {

    return (
        <>
            <div className="content">
                <div className="add-subscription">
                    <div className="upload-img">
                        <label>Image</label>
                        <p className='imgdiv3'>Image size: 382x382</p>
                        <div className="upload">
                            {/* <img src={imageD} className='imgProfile' alt="" /> */}
                            <label htmlFor="upload"><img src="\dashboard-assets\upload.svg" alt="img" className='img-fluid' /></label>
                            <h6>Drag & Drop or <label htmlFor='upload'>Browse</label></h6>
                        </div>
                        <input onChange="" type="file" className='d-none' id='upload' />
                    </div>
                    <div className="option-field">
                        <label>Name</label>
                        <input type="text" placeholder='Name' />
                    </div>
                    <div className="option-field mb-0 d-flex flex-column">
                        <label>Price</label>
                        <input className=''  name="value" type="text" placeholder='description' />
                    </div>
                    <div className="bottom-btnyyyyyy">
                        <button className='red-bybuy'>Publish</button>
                        <button className='red-wy-y'>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Addsubscription
