import React, { useEffect, useState } from "react";
import "./reset.css";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Environment from 'utils/Environment'

const ResetPassword = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [showPass, setShowPass] = useState("password");
  const [showCon, setShowCon] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const api_url = Environment.api_url;
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Check if the input contains only digits
      setPassword(value);
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Check if the input contains only digits
      setConfirmPassword(value);
      setConfirmPasswordValid(true);
    } else {
      setConfirmPasswordValid(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    setAccessToken(token);
    console.log(token, "token");
  }, [window.location.search]);

  useEffect(() => {
    const validatePassword = () => {
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      const isValid = passwordRegex.test(password);
      setPasswordValid(isValid);
      return isValid;
    };

    const validateConfirmPassword = () => {
      setConfirmPasswordValid(password === confirmPassword);
    };

    validatePassword();
    validateConfirmPassword();
  }, [password, confirmPassword]);

  const [success, setSuccess] = useState(false);
  const [create, setCreate] = useState(true);

  const CreateNewPassword = (e, token) => {
    e.preventDefault();
    if (!password) {
      toast.error("Enter pin");
    }else if (password.length != 6) {
      toast.error("Pin must be 6 digits");
    }  else if (!confirmPassword) {
      toast.error("Enter Confirm Pin");
    } else if (password != confirmPassword) {
      toast.error("Pin do not Match");
    }

   else 
    {

    axios
      .post(
        Environment.backendUrl + "/api1/user/resetPin",
        {
          token: accessToken,
          pinCode: password,
          //   confirmPassword: confirmPassword,
        }
      )
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
        setCreate(false);
        handleShow();
      })
      .catch((error) => {
        console.error("Error creating new password:", error);
      });
    }
  };

  return (
    <>
      <div class="mainsection">
        <div class="textparent">
          <img
            src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714638604/Bluemoon%20CloudData/2-updated_pzkreb.png"
            alt="img"
            class="img-fluid images"
           
          />
          <div class="maincard">
            <h3 class="password">Create New Pin</h3>
            <p class="paraa">
              Enter your new pass phrase. It must be a minimum of 8 characters
              and include capital numeric and special character
            </p>
            <h4 class="labelpara">New Pin</h4>
            <div class="parentssinput">
              <input
                value={password}
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                className={`maininputsss ${passwordValid ? 'valid' : 'invalid'}`}
                placeholder="Enter Pin"
              />
              <img
                src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714639769/Bluemoon%20CloudData/eye_ghwqlk.png"
                alt="img"
                class="img-fluid eyeimg"
                onClick={toggleShowPassword}
                style={{ cursor: 'pointer' }}
             
              />
            </div>

            <h4 class="labelpara">Confirm Pin</h4>
            <div class="parentssinput">
              <input
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                type={showConfirmPassword ? 'text' : 'password'}
                className={`maininputsss ${confirmPasswordValid ? 'valid' : 'invalid'}`}
                placeholder="Confirm Pin"
              />
              <img
                src="https://res.cloudinary.com/drt6vurtt/image/upload/v1714639769/Bluemoon%20CloudData/eye_ghwqlk.png"
                alt="img"
                class="img-fluid eyeimg"
                onClick={toggleShowConfirmPassword}
                style={{ cursor: 'pointer' }}
              />
            </div>

            <button onClick={CreateNewPassword} class="savebtns">
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        className="modal-zzzssss "
        centered
        show={show}
        onHide={handleClose}
      >
        {/* <Modal.Header >
          <Modal.Title>BLMN Purchased</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="maincard nnnneeeddddkk p-3">
          <div className="main-outter text-center">
            <div className="row main-cardssss">
              <div className="col-md-12 col-12">
                <div className="awesmss">
                  <img src="\login-assets\successfully.svg" className="" />
                </div>
                <div className="flux-b ">
                  <h3 className="adasdasdasdsaasd">Success !</h3>
                  <p className="czxcxzczxczx">
                    Your Pin has been successfuly changed
                  </p>
                </div>
              </div>

              <div className="col-md-12 col-12 ptb20">
                <button className="savebtns" onClick={handleClose}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetPassword;
