import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { toast } from "react-toastify";
const Addmilestone = (props) => {
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [milestone, setMilestone] = useState(1);
  const token = localStorage.getItem("mytoken");
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const [product_id, setProductId] = useState("");
  const history = useHistory();
  
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getDetail(id);
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(Environment.backendUrl + "/api1/admin/get/productList", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    const milestoneData = {
      description,
      type,
      product_id: product_id,
      milestone: milestone < 1 ? 1 : milestone,
    };

    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/add/milestone",
      data: milestoneData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        toast.success("Milestone added successfully:");

        setDescription("");
        setMilestone(1);
        setProductId("");
        setProducts([]);
        history.push("/admin/milestone");
      })
      .catch((error) => {
        console.error("Error adding milestone:", error);
        toast.error("Error adding milestone:", error);
      });
  };

  const getDetail = (id) => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + `/api1/admin/get/mileStoneById?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        console.log(response?.data,"aaabiii");
        setDescription(response?.data?.description);
        setType(response?.data?.type);
        setSelectedProduct(response?.data?.Product?.ProductType?.productName);
        setProductId(response?.data?.product_id);
        setMilestone(response?.data?.milestone);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };


  const editMilestone = (e) => {
    e.preventDefault();

    const milestoneData = {
      description,
      type,
      product_id: product_id,
      milestone: milestone < 1 ? 1 : milestone,
      milestoneId:id,
    };

    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/edit/milestone",
      data: milestoneData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        toast.success("Milestone Updated successfully:");

        setDescription("");
        setMilestone(1);
        setProductId("");
        setProducts([]);
        history.push("/admin/milestone");
      })
      .catch((error) => {
        console.error("Error adding milestone:", error);
        toast.error("Error adding milestone:", error);
      });
  };

  return (
    <div className="content">
      <section className="addtask card">
        <form onSubmit={id ? (e) => editMilestone(e) : (e) => handleSave(e)}>
          <div className="inner-submit-lower-div">
            <div className="row">
              <div className="col-md-8 order-md-0 order-1">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="padd-top" htmlFor="description">
                        Description
                      </label>
                      <textarea
                        name="description"
                        className="form-control myinputdivr"
                        placeholder="Enter the answer"
                        rows="5"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label className="padd-top mytab1" htmlFor="type">
                        Type
                      </label>
                      <div className="dropdown">
                        <button
                          className="button-listing"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                        {
                              type ? <><h6 style={{ color: "black", fontWeight: '400' }}>{type}</h6></> : "Select Type"}
                          
                  
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="6"
                              viewBox="0 0 9 6"
                              fill="none"
                            >
                              <path
                                d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                fill="#3654D6"
                              />
                            </svg>
                       
                        </button>
                        <div
                          className="dropdown-menu zscscsac"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item taskdiv"
                            onClick={() => setType("Streak")}
                          >
                            Streak
                          </a>
                          <a
                            className="dropdown-item taskdiv"
                            onClick={() => setType("Invite")}
                          >
                            Invite
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="padd-top mytab1" htmlFor="product">
                        Product
                      </label>
                      <div className="dropdown">
                        <button
                          className="button-listing"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <p className="para55  ">

                          {
                              selectedProduct ? <><h6 style={{ color: "black", fontWeight: '400' }}>{selectedProduct}</h6></> : "Select Product"}
                     
                          </p>
                          <i className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="6"
                              viewBox="0 0 9 6"
                              fill="none"
                            >
                              <path
                                d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                fill="#3654D6"
                              />
                            </svg>
                          </i>
                        </button>
                        <div
                          className="dropdown-menu zscscsac"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {products.map((product) => (
                            <a
                              key={product.id}
                              className="dropdown-item taskdiv  eleipiess mainydiskkk w-100 "
                              onClick={() => {
                                setSelectedProduct(product?.ProductType?.productName);
                                setProductId(product.id);
                              }}
                            >
                              {product?.ProductType?.productName}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="milestone">Milestone</label>
                      <input
                        type="number"
                        name="milestone"
                        className="form-control myinputdivr"
                        placeholder="1"
                        min="1"
                        value={milestone}
                        onChange={(e) => setMilestone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <div className="videopage">
                        <button type="submit" className="red-bybuy">
                          {id ? "Update" : "Save"}
                        </button>
                        <Link to="/admin/milestone">
                          <button type="button" className="red-wy-y">
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Addmilestone;
