import React from 'react'
import { Link } from "react-router-dom";
const Adduserprofiledetail = () => {
  return (
    <>
      <div className="content">
                <section className="addbanner12345 card bbbbbye">
                    <form>
                        <div className="inner-submit-lower-div">
                            <div class="row">
                                <div class="col-md-8 order-md-0 order-1">
                                    
                                        <div className="row">
                                            <div className="col-12">
                                                <div class="form-group">
                                                    <label for="example">Name</label>
                                                    <input type="text" name="name" className="myinputss w-100" placeholder="Enter name" />
                                             
                                                </div>
                                            </div>
                                            <div className="col-12 ">
                                                <div class="form-group">
                                                    <label className="padd-top" for="example"> Price</label>
                                                    <input name="description"  className="myinputsss"  placeholder="Enter price" rows="5"></input>
                                        
                                                </div>
                                            </div>
                                         
                                                
                               
                                            
                                           </div>
                                      
                                        <div className="col-12">
                                            <div class="form-group">
                                                <div className="videopage">
                                                 <button className="red-bybuy" >Publish</button>
                                                    {/* <button className="red-b" onClick={sendVideo}>Publish</button> */}
                                                    <Link to="/admin/dashboard">
                                                        <button className="red-wy-y">Cancel</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                               
                                </div>
                                <div class="col-md-4 order-md-1 order-0">
                                <div className="upload-img ">
                                <p className=" manydivbart">Upload profile Image</p>
                      <p className="imgsize">Image size: 200 x 200</p>
                      <div className="upload sidedotsaatyy ">
                        <img src="" className="imgProfile" alt="" />
                        <label htmlFor="upload">
                          <img
                            src="\dashboard-assets\upload.svg"
                            alt="img"
                            className="img-fluid dragimg"
                          />
                          <p className="paraa09091">Drag & drop or <span className="paraa23452"> Browse</span></p>
                        </label>
                      </div>

                      {/* <p className="text-danger">Image is missing!</p> */}
                      <input
                        type="file"
                        name="image"
                        className="d-none"
                        id="upload"
                      />
                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    
                    </form>
                </section>
            </div>
    </>
  )
}

export default Adduserprofiledetail
