import React, { useState, useCallback, useEffect } from "react";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Environment from "utils/Environment";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";
const Addonboarding = () => {
  return (
    <>
      <div className="content">
        <section className="addsocial">
          <div className="container-fluid">
            <div className="main-second-cards">
              {/* <h3>Heading</h3> */}
              <div className="row">
                <div className="col-lg-5 col-12 p-0">
                  <div className="upload-img ">
                    <label for="exampleInputsymbol " className="manydivbart">
                      {" "}
                      Background Image/Video
                    </label>
                    <p className="imgsize">Image size: 430x488</p>
                    <div className="upload sidedotsatta ">
                      <img src="" className="imgProfile" alt="" />
                      <label htmlFor="upload">
                        <img
                          src="\dashboard-assets\upload.svg"
                          alt="img"
                          className="img-fluid dragimg"
                        />
                        <p className="paraa1">
                          Drag & drop or <span className="paraa2"> Browse</span>
                        </p>
                      </label>
                    </div>

                    {/* <p className="text-danger">Image is missing!</p> */}
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="upload"
                    />
                  </div>
                </div>
                <div className="col-lg-12 pl-0 pr-0">
                  <div className="col-8 p-0">
                    <div class="form-group">
                      <label for="example">Title</label>
                      <input
                        name="heading"
                        type="text"
                        class="form-control"
                        id="example"
                        aria-describedby="text"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div className="col-8 p-0">
                    <div class="form-group">
                      <label className="padd-top" for="example">
                        Description
                      </label>
                      <textarea
                        name="bodyText"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Description."
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  {/* <div className="col-12 p-0">
                                        <div class="form-group">
                                            <div className="App">
                                                {inputList.map((x, i) => {
                                                    return (
                                                        <div className="box">
                                                            <div className="row ">
                                                                <div className="col-sm-12 pl-md-0">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label>Link Name</label>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="main-inout-nh"
                                                                                    name="link1Name"
                                                                                    placeholder="Social Link Name"
                                                                                    value={allData.form.link1Name}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label>Link URL</label>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="mai=n-inout-nh"
                                                                                    placeholder="Social Link URL"
                                                                                    name='link1Url'
                                                                                    value={allData.form.link1Url}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label>Icon </label>
                                                                            <div className="main-divs-image">
                                                                                <label className="main-label-m" for="filess1011">{allData.form?.image ? <img className='lolgo' src={localImg} alt="" /> : 'Upload Icon'} </label>
                                                                                <input name='image'

                                                                                    onChange={handleChange} className="d-none" type="file" id="filess1011" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="btn-box ">
                                                                    {inputList.length !== 1 && <button
                                                                        className=" button-removess"
                                                                        onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                    {inputList.length - 1 === i && <button className="buttonsss_attri ml-3" onClick={handleAddClick}><img src={`${images['addvid1.png']['default']}`} alt="" /> Add Questions</button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="col-12 p-0">
                    <div class="form-group">
                      <div className="videopage">
                        <button className="red-bybuy">Publish</button>
                        <button className="red-wy-y">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Addonboarding;
