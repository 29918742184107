
import React, { useEffect, useState } from "react";

import './user.scss';
// reactstrap components
import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';

import { Pagination } from "react-bootstrap";
const User = () => {

  return (
    <>

      <div className="content">
        <div className="container-fluid">

          <div className="maintableauser">

            <div className="innertable_user table-responsive">
              <table>
                <thead>
                  <th>Users Name</th>
                  <th>Joining Date</th>
                  <th>Wallet Address </th>
                  <th>Referrals</th>
                  <th>Email</th>
                  <th>Verification </th>
                  <th>Action</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td>
                      <Link to="/admin/userdetail">
                        <button className="detailbtn" >Detail</button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td><span className="greyish">
                      Complete </span></td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mainimgdiv">
                        <div className="inerimgd">
                          <img src="\users-assets\admin-img.png" className="tableimgginer">
                          </img>
                        </div>
                        <p className="tableimgtext">
                          Carolyn Wilson
                        </p>
                      </div>
                    </td>
                    <td>Aug 25, 2022</td>
                    <td>
                      <span className="eleipiess">
                        0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43... 0x0712775C43...
                      </span>
                    </td>
                    <td>100</td>
                    <td>carolyn@gmail.com</td>
                    <td>
                      <span className="orange">
                        Pending </span>
                    </td>
                    <td><button className="detailbtn" >Detail</button></td>
                  </tr>


                </tbody>

              </table>

            </div>
            <div className='Paginationlattable'>
              <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button>
              <Pagination>
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item active>{5}</Pagination.Item>
                <Pagination.Item>{6}</Pagination.Item>
              </Pagination>
              <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default User;
