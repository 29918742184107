import { Backdrop, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Environment from "utils/Environment";
import "./addnewcategory.scss";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Addnewcategory = (props) => {
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const location = useLocation();
  const [currentId, setCurrentId] = useState(null);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const [image, setImage] = useState("");
  const [priority, setPriority] = useState("");
  const [baseImage, setBaseImage] = useState("");


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };


  const handleCheckboxChange3 = (event) => {
    setIsChecked3(event.target.checked);
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };

  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const idParam = pathParts[pathParts.length - 1];
    const idValue = idParam.split("=")[1];

    if (idValue) {
      setCurrentId(idValue);
      localStorage.setItem("currentId", idValue);
      getTaskDetail(idValue);
    }
  }, [location]);

  const getTaskDetail = (idValue) => {
    axios
      .get(
        Environment.backendUrl +
        `/api1/admin/find/productCategorybyId?id=${idValue}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setName(response?.data?.productCategory?.categoryName);
        setAmount(response?.data?.productCategory?.totalProducts);
        setPriority(response?.data?.productCategory?.priority);
        setBaseImage(response?.data?.productCategory?.icon);
        setIsChecked(response?.data?.productCategory?.buyWithXblue);
        setIsChecked2(response?.data?.productCategory?.buyWithUsd);
        setIsChecked3(response?.data?.productCategory?.isToShowInStore);

      })
      .catch((err) => {
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const editCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (name) {
      formData.append("categoryName", name);
    }
    if (amount) {
      formData.append("totalProducts", amount);
    }

    if (image) {
      formData.append("icon", image);
    }
    if (priority) {
      formData.append("priority", priority);
    } if (isChecked || !isChecked) {
      formData.append("buyWithXblue", isChecked);
    }

    if (isChecked2 || !isChecked2) {
      formData.append("buyWithUsd", isChecked2);
    }
    if (isChecked3 || !isChecked3) {
      formData.append("isToShowInStore", isChecked3);
    }
    if (currentId) {
      formData.append("id", currentId);
    }
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/edit/productCategory`,
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/newcategory");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };

  const addCategory = async (e) => {
    e.preventDefault();
    if (!name || !amount || !image || !priority) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const formData = new FormData();
    if (name) {
      formData.append("categoryName", name);
    }
    if (amount) {
      formData.append("totalProducts", amount);
    }

    if (image) {
      formData.append("icon", image);
    }
    if (priority) {
      formData.append("priority", priority);
    } if (isChecked || !isChecked) {
      formData.append("buyWithXblue", isChecked);
    }

    if (isChecked2 || !isChecked2) {
      formData.append("buyWithUsd", isChecked2);
    }
    if (isChecked3 || !isChecked3) {
      formData.append("isToShowInStore", isChecked3);
    }
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/add/productCategory`,
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/newcategory");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
  };
  return (
    <>
      <div className="content">
        <section className="addnewcategory">
          <div className="row">
            <div className="col-lg-8">
              <p>Category</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Name"
              />
              <div className="upload-parent">
                <p className="head">Upload Icon</p>
                {/* <p className="imgyyyy">Image size: 382x382</p> */}
                <div className="upload">
                  <label htmlFor="upload"></label>{" "}
                  <label htmlFor="upload">
                    {baseImage ?
                      <>
                        <img
                          src={baseImage}
                          alt="img"
                          className="img-fluid"
                        />
                      </>
                      :
                      <>
                        <img
                          src="\dashboard-assets\cloud-computing 1.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>
                          Drag & Drop or <span>Browse</span>
                        </p>
                      </>
                    }
                  </label>
                  <input

                    type="file"
                    className="d-none"
                    id="upload"
                    onChange={uploadImages}
                  />
                </div>
              </div>

              <p>Total Products</p>
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                placeholder="Amount"
                min={0}
              />

              <p>Priority</p>
              <input
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                min={0}
                type="number"
                placeholder="1"
              />
              <div className="headofswitchhhneww mt-3 ">

                <p className="linktext">Buy with xBLUE</p>
                <div className="main-switch pl-4">
                  <div class="custom-control custom-switch myswitchhhhh ">
                    <input
                      type="checkbox"
                      class="custom-control-input "
                      id="customSwitche"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      class="custom-control-label "
                      for="customSwitche"
                    ></label>
                  </div>
                </div>

              </div>
              <div className="headofswitchhhneww   ">
                <p className="linktext">Buy with USD</p>
                <div className="main-switch pl-4 ml-3">
                  <div class="custom-control custom-switch myswitchhhhh ">
                    <input
                      type="checkbox"
                      class="custom-control-input "
                      id="customSwitchess"
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                    <label
                      class="custom-control-label "
                      for="customSwitchess"
                    ></label>
                  </div>
                </div>


              </div>

              <div className="headofswitchhhneww mb-5  ">
                <p className="linktext">Show in store</p>
                <div className="main-switch pl-4 ml-3">
                  <div class="custom-control custom-switch myswitchhhhh ">
                    <input
                      type="checkbox"
                      class="custom-control-input "
                      id="customSwitchesss"
                      checked={isChecked3}
                      onChange={handleCheckboxChange3}
                    />
                    <label
                      class="custom-control-label "
                      for="customSwitchesss"
                    ></label>
                  </div>
                </div>


              </div>

              <div className="btnslasts mt-5">
                <Link to={"/admin/newcategory"}>
                  <button className="red-wy-y">Cancel</button>
                </Link>

                <button
                  onClick={
                    currentId ? (e) => editCategory(e) : (e) => addCategory(e)
                  }
                  className="red-bybuy"
                >
                  {currentId ? "Update" : "Publish"}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Addnewcategory;
