
import React, { useEffect, useState } from "react";
import './badges.scss';
import Environment from "utils/Environment";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
// reactstrap components
function Badges() {
    const token = localStorage.getItem('mytoken')
    const [open, setOpen] = useState(false);
    const [myFiles, setMyFiles] = useState([]);

           // pagination ============

  const [limit] = useState(10);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);



  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage );
  };



  // pagination ============

    const getBadge = () => {
        setOpen(true);
        axios.get(
            Environment.backendUrl + `/api1/admin/get/all/Badge?limit=${limit}&offset=${page*limit}`,
            { headers: { "Authorization": `Bearer ${token}` } }
        )
            .then((response) => {
                setOpen(false);
                console.log(response?.data?.data, "abdullah");
                setMyFiles(response?.data?.data);
                setPageCount(response?.data?.count);

            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };



    useEffect(() => {
        getBadge()
    }, [page])

    const handleToggleChange = async (id, publish) => {
        try {
          // Toggle the verified value
          const newPublish = !publish;
      
          // Make the API call
          const response = await axios.get(
            `${Environment.backendUrl}/api1/admin/publish/unpublish?id=${id}&publish=${newPublish}`,
            { headers: { "Authorization": `Bearer ${token}` } }
          );
      
          // Handle the response as needed
          console.log(response.data?.msg);
          toast.success(response.data?.msg);
        } catch (error) {
          // Handle the error as needed
          console.error('Error verifying task proof:', error);
        }
      };
    
   
    return (
        <>
            <div className="content">
                <section className="videos notifications card">
                    <div className="container-fluid">
                        <div class="table-responsive">
                            <table class="table ">
                            <thead>
                                    <tr>
                                        <th>Badge Image</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Description</th>
                                        <th>Edit</th>
                                        <th>Publish</th>

                                    </tr>
                                </thead>
                                <tbody className="main-t-body-text" >
                                {myFiles?.map((item, index) => {
                                return (
                                  <>
                                  <tr key={index}>
                                    <td>
                                        <img className="img-fluid imgsize8" src={item?.imageLink}/>
                                    </td>
                                    <td className="tddataa">{item?.name}</td>
                                    <td className="tddataa">{item?.type}</td>
                                    <td className="tddataa">{item?.description}</td>
                                    
                                    <td className="tddataa"><Link to={"/admin/sendbadges?id=" +item?.id}><button className="edyybut">Edit</button> </Link></td>
                                    <td ><div className="main-switch-nn vvvvvv">
                          <div class="custom-control custom-switch">
                            <input defaultChecked={item?.publish}   onChange={ () => handleToggleChange(item?.id,item?.publish)} type="checkbox" class="custom-control-input" id={`customSwitches-${item?.id}`} />
                            <label class="custom-control-label"  htmlFor={`customSwitches-${item?.id}`}></label>
                          </div>
                        </div></td>
                                    
                                  </tr>
                                  </>
                                );
                              })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''}
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
                </section>
            </div>
        </>
    );
}

export default Badges;
