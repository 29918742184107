import React, { useState, useCallback, useEffect } from 'react';

const Addsociallinks = () => {

    return (
        <>

            <div className="content">
                <section className='addsocial'>
                    <div className='container-fluid'>
                        <div className="main-second-cards">
                       
                            <div className="row">
                                <div className="col-lg-12 pl-0 pr-0">
                                    <div className="col-8 p-0">
                                        <div class="form-group">
                                            <label for="example" className='headingtextbbb'>Heading</label>
                                            <input 
                                                name='heading' type="text" class="form-control" id="example" aria-describedby="text" placeholder="Community" />
                                        </div>
                                    </div>
                                    <div className="col-8 p-0">
                                        <div class="form-group">
                                            <label  for="example"className='headingtextbbb'>Body Text</label>
                                            <input 
                                                name='bodyText' class="form-control" id="exampleFormControlTextarea1" placeholder="Connect with thousand of other Legion users to disscuss and share anything about cryptocurrency knowledge." rows="5"></input>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <div class="form-group">
                                            <div className="App">
                                               
                                                
                                                        <div className="box">
                                                            <div className="row ">
                                                                <div className="col-sm-12 pl-md-0">
                                                                    <div className="row">
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label className='headingtextbbb'>Link Name</label>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="main-inout-nh noneeeeof"
                                                                                    name="link1Name"
                                                                                    placeholder="Social Link Name"
                                                                             
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label className='headingtextbbb'>Link URL</label>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    className="mai=n-inout-nh noneeeeof"
                                                                                    placeholder="Social Link URL"
                                                                                    name='link1Url'
                                                                                
                                                                               
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pl-md-0">
                                                                            <label className='headingtextbbb'>Icon </label>
                                                                            <div className="main-divs-image">
                                                                                <label className=" icondivv" for="filess1011">  Upload Icon </label>
                                                                                <input name='image'

                                                                                     className="d-none" type="file" id="filess1011" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="btn-box ">
                                                                    {inputList.length !== 1 && <button
                                                                        className=" button-removess"
                                                                        onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                    {inputList.length - 1 === i && <button className="buttonsss_attri ml-3" onClick={handleAddClick}><img src={`${images['addvid1.png']['default']}`} alt="" /> Add Questions</button>}
                                                                </div> */}
                                                            </div>
                                                        </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <div class="form-group">
                                            <div className="videopage">
                                                <button className="red-bybuy">Publish</button>
                                                <button className="red-wy-y">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Addsociallinks