import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Backdrop } from "@material-ui/core";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Environment from "utils/Environment";
import "./login.scss";
import swal from "sweetalert";
import { CircularProgress } from "@material-ui/core";
import Form from "react-bootstrap/Form";

const Login = () => {
  // const classes=useStyle();
  const [emailError, setEmailError] = useState({});
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [passwordError, setPasswordError] = useState({});
  const [showpassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [loginres, setLoginnRes] = useState({
    dataURL: "",
    secretKey: "",
  });
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const { email, password } = inputs;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleShowPassword = () => {
    setShowPassword(!showpassword);
  };
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setInputs((inputs) => ({ ...inputs, email: rememberedEmail }));
      setRememberMe(true);
    }
  }, []);
  const formValidation = () => {
    const emailError = {};
    const passwordError = {};

    let isValid = true;

    if (email === "") {
      emailError.emailError = "Email is Required";
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      emailError.emailNameError = "Invalid Email";
      isValid = false;
    }
    if (password === "") {
      passwordError.logoError = "Password is Required";
      isValid = false;
    }

    // if(messageInputData.)
    setEmailError(emailError);
    setPasswordError(passwordError);
    return isValid;
  };

  const forgot = () => {
    axios
      .post(Environment.backendUrl + "/user/forgetpasswordlink", { email })
      .then((response) => {
        toast.success("Please check your email to reset your password.", {
          position: "top-center",
          autoClose: 3000,
        });
        // setOpen(true)
        // const token = response.data.token
        // localStorage.setItem('mytoken', token)
        // history.push("admin/dashboard");
      })
      .catch((err) => {
        toast.error(err.response.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const Signin = (e) => {
    e.preventDefault();
    setSubmitted(true);
    formValidation();
    if (email && password) {
      setOpen(true);
      axios
        .post(Environment.backendUrl + "/api1/admin/Adminlogin", {
          email,
          password,
        })
        .then((response) => {
          setLoginnRes(response?.data);
          const token = response?.data?.token;
          const role = response?.data?.role?.role_name;
          const roleId = response?.data?.role?.id;
          const Acls = response?.data?.role?.Acls;
          let obj = {};
          let a1 = Acls?.map((e, i) => {
            console?.log("eeeee", e);
            obj[`${e?.selection}`] = { ...e };
            // obj2[`${e.selection}`] = e.id
          });
          localStorage.setItem("mytoken", token);
          localStorage.setItem("myrole", role);
          localStorage.setItem("myroleId", roleId);
          localStorage.setItem("acls", JSON.stringify(obj));
          if (rememberMe) {
            localStorage.setItem("rememberedEmail", email);
          }
          //   else {
          //     localStorage.removeItem("rememberedEmail");
          //   }
          setOpen(false);

          history.push("admin/dashboard");
          // window.$('#qrcode').modal('show')
        })
        .catch((err) => {
          // console.log("response in login", err.response?.data.doesSecretKeyFound)
          // window.$('#onlyinput').modal('show')
          setOpen(false);
          swal(
            "Invalid Credentials!!!",
            `Password must contain a Capital Letter, Small Letter, Number and a special Character(Minimun 8 characters)`,
            "error"
          );
          // toast.error(err.response?.data.msg, {
          //   position: "top-center",
          //   autoClose: 2000,
          // });
        });
    } else {
      swal("Invalid Credentials!", `Try Again`, "Error");
    }
  };

  const images = importAll(
    require.context("assets/img/", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <Backdrop className="loader" sx={{ color: "#fff" }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="main-login">
        <div className="container">
          <div className="mainouterdiv">
            <div className="inner-logo text-center">
              {/* <img src={`${images['logo.svg']['default']}`} alt="" className="img-fluid" /> */}
              <img
                src="\login-assets\logonew.svg"
                alt=""
                className="img-fluid"
              ></img>
            </div>
            <div className="cmn-tile-style">
              <div className="topinerdiv">
                <h2 className="">Sign In</h2>
                <h6 className="loginpara">
                  Enter your credentials to access your account
                </h6>
              </div>

              <form>
                <div className="butmdivmain">
                  <div className="material-textfield">
                    <input
                      placeholder="Username"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    {Object.keys(emailError).map((key) => {
                      return <p className="inputErrors">{emailError[key]}</p>;
                    })}
                    <label>Username</label>
                  </div>
                  <div className="material-textfield ">
                    <input
                      className="inputclasssy"
                      type={showpassword ? "text" : "password"}
                      name="password"
                      value={password}
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    {Object.keys(passwordError).map((key) => {
                      return (
                        <p className="inputErrors">{passwordError[key]}</p>
                      );
                    })}
                    <label for="exampleInputPassword1">Password</label>
                    {showpassword ?<svg  onClick={handleShowPassword} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="eyeimg">
                      <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.8198 5.77047C16.0698 4.45047 14.0698 3.73047 11.9998 3.73047C8.46984 3.73047 5.17984 5.81047 2.88984 9.41047C1.98984 10.8205 1.98984 13.1905 2.88984 14.6005C3.67984 15.8405 4.59984 16.9105 5.59984 17.7705" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.41992 19.5297C9.55992 20.0097 10.7699 20.2697 11.9999 20.2697C15.5299 20.2697 18.8199 18.1897 21.1099 14.5897C22.0099 13.1797 22.0099 10.8097 21.1099 9.39969C20.7799 8.87969 20.4199 8.38969 20.0499 7.92969" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15.5095 12.6992C15.2495 14.1092 14.0995 15.2592 12.6895 15.5192" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9.47 14.5293L2 21.9993" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21.9993 2L14.5293 9.47" stroke="#0F7EFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> :  <svg
                      onClick={handleShowPassword}
                      className="eyeimg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M15.5819 12.3407C15.5819 14.3207 13.9819 15.9207 12.0019 15.9207C10.0219 15.9207 8.42188 14.3207 8.42188 12.3407C8.42188 10.3607 10.0219 8.76074 12.0019 8.76074C13.9819 8.76074 15.5819 10.3607 15.5819 12.3407Z"
                        stroke="#BFC8D7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9998 20.6105C15.5298 20.6105 18.8198 18.5305 21.1098 14.9305C22.0098 13.5205 22.0098 11.1505 21.1098 9.74055C18.8198 6.14055 15.5298 4.06055 11.9998 4.06055C8.46984 4.06055 5.17984 6.14055 2.88984 9.74055C1.98984 11.1505 1.98984 13.5205 2.88984 14.9305C5.17984 18.5305 8.46984 20.6105 11.9998 20.6105Z"
                        stroke="#BFC8D7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>}
                  

                    
                  </div>
                  <div class="formcheck_newwwwws">
                    <label class="checkBox m-0">
                      <input
                        type="checkbox"
                        id="ch1"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      Remember me
                    </label>
                  </div>

                  <div className="ftrbtndiv">
                    <Link to="admin/dashboard">
                      <button
                        type="submit"
                        className="btn-common111"
                        onClick={Signin}
                      >
                        Sign In
                      </button>
                      {/* <ToastContainer style={{ fontSize: 20 }} /> */}
                    </Link>
                    <Link>
                      <p className="forgetpasssed  " onClick={forgot}>
                        Forgot Password?
                      </p>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="main-modal-one">
                <div class="modal fade" id="qrcode" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog daily-profile-modal modal-dialog-centered">
                        <div class="modal-content daily-profile-modal-inner">
                            <div class="modal-body modal-body-main">
                                <div className="main-outter">
                                    <div className="row main-cardssss">
                                        <section className="main-login-auth ptb30">
                                            <div className="col-md-12 mx-auto">
                                                <div className="inner-logo text-center">
                                                    <img src={`${images['logo.svg']['default']}`} alt="" className="img-fluid" />
                                                </div>
                                                <div className="cmn-tile-style text-center">
                                                    <h3 className="ptb20">Two Factor-Authentication</h3>
                                                    <p>Scan Your QR Code:</p>
                                                    <div className='text-center'>
                                                        <img src={loginres.dataURL} alt="" className="img-fluid" />
                                                    </div>

                                                    <h3 className="ptb20">Enter the six-digit code from the application</h3>
                                                    <form>
                                                        <div className="form-group  mt-4">
                                                            <input type="number" name="verifycode" value={verifycode} onChange={handleChange1} className='form-control ' placeholder="xxx-xxxx" />
                                                            {Object.keys(verifycodeError).map((key) => {
                                                                return <p className="inputErrors text-left mt-2">{verifycodeError[key]}</p>
                                                            })}
                                                        </div>
                                                        <div className='text-right'>
                                                            <ul className='list-inline'>
                                                                <li className='list-inline-item'><button type="submit" className="btn-common" onClick={Cancel} >Cancel</button></li>
                                                                <li className='list-inline-item'><button type="submit" className="btn-common" onClick={Signin1} >Enable</button></li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className="main-modal-one">
                <div class="modal fade" id="onlyinput" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog daily-profile-modal modal-dialog-centered">
                        <div class="modal-content daily-profile-modal-inner">
                            <div class="modal-body modal-body-main">
                                <div className="main-outter">
                                    <div className="row main-cardssss">
                                        <section className="main-login-auth ptb30">
                                            <div className="col-md-12 mx-auto">
                                                <div className="cmn-tile-style text-center">
                                                    <h3 className="ptb20">Enter the six-digit code from the application</h3>
                                                    <form>
                                                        <div className="form-group  mt-4">
                                                            <input type="number" name="verifycode" value={verifycode} onChange={handleChange1} className='form-control ' placeholder="xxx-xxxx" />
                                                            {Object.keys(verifycodeError).map((key) => {
                                                                return <p className="inputErrors text-left mt-2">{verifycodeError[key]}</p>
                                                            })}
                                                        </div>
                                                        <div className='text-right'>
                                                            <ul className='list-inline'>
                                                                <li className='list-inline-item'><button type="submit" className="btn-common" onClick={Cancel1} >Cancel</button></li>
                                                                <li className='list-inline-item'><button type="submit" className="btn-common" onClick={Signin2} >Submit</button></li>
                                                            </ul>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default Login;
