import React, { useEffect, useRef, useState } from "react";
import "./products.scss";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";

const Addproducts = (props) => {


  const [id, setId] = useState("");
  const [xBlue, setXblue] = useState([]);
  
  const [discription, setDescription] = useState("");

  const [selectedItems, setSelectedItems] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedItems2, setSelectedItems2] = useState("");
  const [selectedId2, setSelectedId2] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const [image, setImage] = useState("");
  const location = useLocation();
  const [currentId, setCurrentId] = useState(null);
  const [baseImage, setBaseImage] = useState("");
  const [catId, setCatID] = useState("");
  const [catName, setCatname] = useState("");
  const [productId, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [product, setProduct] = useState([]);
  const [price, setPrice] = useState("");
  const [dollar, setDollar] = useState("");
  const [htmlContent2, setHtmlContent2] = useState("");
  const [textContent2, setTextContent2] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  console.log(htmlContent,"htmlContent");
  const [textContent, setTextContent] = useState("");
  const editor = useRef(null);
  const editor2 = useRef(null);

  const handleChange = (newContent) => {
    setHtmlContent(newContent);
  };

 
 

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };

  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const idParam = pathParts[pathParts.length - 1];
    const idValue = idParam.split("=")[1];

    if (idValue) {
      setCurrentId(idValue);
      localStorage.setItem("currentId", idValue);
      getTaskDetail(idValue);
    }
  }, [location]);

  const getCategory = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/all/productCategory?limit=1000&offset=0", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setMyFiles(response?.data?.productCategory);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };





  const addProduct = async (e) => {
    e.preventDefault();
    if (
      !price ||
      !dollar ||
      !image ||
      !htmlContent ||
      !catId
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }

    const formData = new FormData();
    if (price) {
      formData.append("price", price);
    }
    if (dollar) {
      formData.append("valueDollar", dollar);
    }
    if (htmlContent) {
      formData.append("description", JSON.stringify(htmlContent));
    }
    if (image) {
      formData.append("image", image);
    }
    if (image) {
      formData.append("productImage", image);
    }
    if (catId) {
      formData.append("category_id", catId);
    }
    if (productId) {
      formData.append("productType_id", productId);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/add/product",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res, "mmm");
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/products");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const getTaskDetail = (idValue) => {
    setOpen(true);
    axios
      .get(
        Environment.backendUrl + `/api1/admin/get/productById?id=${idValue}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setOpen(false);
        setPrice(response.data?.product?.price);
        setDollar(response.data?.product?.valueDollar);
        setCatname(response.data?.product?.ProductCategory?.categoryName);
        setProductName(response.data?.product?.ProductType?.productName);
        setBaseImage(response.data?.product?.image);
        setCatID(response.data?.product?.ProductCategory?.id);
        setHtmlContent(response.data?.product?.description);
        // setHtmlContent(response.data?.product?.productBenifits);
        console.log(response.data, "bbb");
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const editProduct = async (e) => {
    // validateUserName();
    // if (!errors) {
    e.preventDefault();

    const formData = new FormData();
    if (price) {
      formData.append("price", price);
    }
    if (dollar) {
      formData.append("valueDollar", dollar);
    }
    if (htmlContent) {
      formData.append("description", htmlContent);
    }
    if (image) {
      formData.append("image", image);
    }
    if (image) {
      formData.append("productImage", image);
    }
    if (catId) {
      formData.append("category_id", catId);
    }
    if (productId) {
      formData.append("productType_id", productId);
    }
    if (currentId) {
      formData.append("id", currentId);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/edit/product",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res, "mmm");
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/products");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const getProduct = () => {
    if (catId) {
      setOpen(true);
      axios
        .get(
          Environment.backendUrl +
          `/api1/admin/get/productTypeList?category_id=${catId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setOpen(false);
          console.log(response?.data?.productTypeList, "response");
          setProduct(response?.data?.productTypeList);
        })
        .catch((err) => {
          setOpen(false);
          toast.error(err.response?.data.msg, {
            position: "top-center",
            autoClose: 2000,
          });
        });
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getProduct();
  }, [catId]);

  return (
    <>
      <div className="content">
        <section className="addproductform">
          <div className="row">
            <div className="col-sm-8 p-0">
              <div className="d-flex">
                <div className="upload-parent">
                  <p className="head">Image</p>
                  <p className="imgyyyy">Image size: 382x382</p>
                  <div className="upload">
                    <label htmlFor="upload"></label>{" "}
                    <label htmlFor="upload">
                      {baseImage ? (
                        <>
                          <img
                            src={baseImage}
                            alt="img"
                            className="img-fluid"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src="\dashboard-assets\cloud-computing 1.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <p>
                            Drag & Drop or <span>Browse</span>
                          </p>
                        </>
                      )}
                    </label>
                    <input
                      onChange={uploadImages}
                      type="file"
                      className="d-none"
                      id="upload"
                    />
                  </div>
                </div>
                {/* <div className="upload-parent ml-3">
                                    <p className="head">Product Image</p>
                                    <div className="upload">
                          <label htmlFor="upload2">
                                           
                                                <img
                                                    src=""
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </label> : <label htmlFor="upload2">
                                               
                                                <img
                                                    src="\dashboard-assets\cloud-computing 1.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <p>Drag & Drop or <span>Browse</span></p>
                                            </label>
                                        
                                        <input type="file" className="d-none" id="upload2" />
                                    </div>
                                </div> */}
              </div>

              <p className="head">
                Category <span className="text-danger">*</span>
              </p>
              <div class="dropdown droped">
                <button
                  class="dropbtn dropdown"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <p style={catName ? { color: "black" } : { color: "#c1c1c1" }}>{catName ? catName : "Select Category"}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                    <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                  </svg>
                </button>
                <div class="dropdown-menu dropmain mydropmainnnn">
                  {myFiles?.map((item, index) => {
                    return (
                      <>
                        <a
                          onClick={() => {
                            setCatname(item.categoryName);
                            setCatID(item.id);
                          }}
                          className="dropdown-item drop nnewwdrop"
                        >
                          <p className="eleipiess taskdivv w-100 ">{item?.categoryName}</p>
                        </a>
                      </>
                    );
                  })}
                </div>
              </div>
              <p className="head">Product </p>
              <div class="dropdown droped">
                <button class="dropbtn dropdown" type="button" data-toggle="dropdown" aria-expanded="false">
                  <p style={productName ? { color: "black" } : { color: "#c1c1c1" }}>

                    {productName ? productName : "Select Product"}
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                    <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                  </svg>
                </button>
                <div class="dropdown-menu dropmain">

                  {product && product.length > 0 ? (
                    product.map((item, index) => (
                      <a
                        key={index}
                        onClick={() => {
                          setProductName(item.productName);
                          setProductID(item.id);
                        }}
                        className="dropdown-item drop"
                      >
                        <p>{item?.productName}</p>
                      </a>
                    ))
                  ) : (
                    <p className="taskdivv w-100 ">No products available for this category.</p>

                  )}

                </div>
                <div class="dropdown-menu dropmain">

                  {product && product.length > 0 ? (
                    product.map((item, index) => (
                      <a
                        key={index}
                        onClick={() => {
                          setProductName(item.productName);
                          setProductID(item.id);
                        }}
                        className="dropdown-item drop"
                      >
                        <p>{item?.productName}</p>
                      </a>
                    ))
                  ) : (
                    <p className="taskdivv w-100 ">No products available for this category.</p>

                  )}

                </div>
              </div>
              {/* <p className="head">description</p>
              <input
                value={textContent2}
                onChange={(e) => setTextContent2(e.target.value)}
                type="text"
                placeholder="description"
              /> */}
              <p className="head">Price in Xblue</p>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                type="text"
                placeholder="100 xBlue"
              />

              <p className="head">Price in Dollar</p>
              <input
                value={dollar}
                min={0}
                onChange={(e) => setDollar(e.target.value)}
                type="text"
                placeholder="100 xBlue"
              />

              {/* <p className="head">Price in USDT</p>
                            <input  type="number" placeholder='100 USDT' /> */}
              <p className="head">What is it?</p>

              <JoditEditor
                ref={editor}
                value={htmlContent}
                tabIndex={1} 
                onChange={handleChange}
              />
              {/* <p className="head">What is inside?</p>

              <JoditEditor
                ref={editor}
                value={htmlContent}
                tabIndex={1}
                onChange={handleChange}
              /> */}
              <div className="lastbtns pt-5 mybutttts">
                <button
                  onClick={
                    currentId ? (e) => editProduct(e) : (e) => addProduct(e)
                  }
                  className="red-bybuy"
                >
                  {currentId ? "Update" : "Publish"}
                </button>
                <Link to="/admin/products">
                  <button className="red-wy-y">Cancel</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Addproducts;
