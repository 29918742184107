import React, { useEffect, useState } from 'react'
import "./raffles.scss"
import Environment from "utils/Environment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Addraffles = (props) => {
    const token = localStorage.getItem("mytoken");
    const history = useHistory();
    const [image, setImage] = useState("");
    const [baseImage, setBaseImage] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ticket, setTicket] = useState("");
    const [price, setPrice] = useState("");
    const [maxcap, setMaxcap] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [id, setId] = useState("");
    const [list, setList] = useState([]);
    const [sweep, setSweep] = useState([]);
    const [open, setOpen] = useState(false);


    console.log(startDate, "start");

    const uploadImages = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase645(file);
        setBaseImage(base64);
        setImage(file);
    };

    const convertBase645 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const addraffles = async (e) => {
        e.preventDefault();
        if (
            !price ||
            !name ||
            !image ||
            !description ||
            !startDate ||
            !endDate ||
            !maxcap ||
            !ticket
        ) {
            // Show a toast message if any field is empty
            toast.error("Please fill in all required fields");
            return;
        }

        const formData = new FormData();
        if (price) {
            formData.append("pricePerTicket", price);
        }
        if (name) {
            formData.append("name", name);
        }
        if (description) {
            formData.append("description", description);
        }
        if (ticket) {
            formData.append("totalTickets", ticket);
        }
        if (image) {
            formData.append("image", image);
        }
        if (maxcap) {
            formData.append("maxCap", maxcap);
        }
        if (startDate) {
            formData.append("startDate", startDate);
        }
        if (endDate) {
            formData.append("endDate", endDate);
        }
        const config = {
            method: "post",
            url: Environment.backendUrl + "/api1/admin/add/Raffles",
            data: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        };
        await axios(config)
            .then((res) => {
                console.log(res, "mmm");
                toast.success(res?.data?.msg);
                setBaseImage();
                setImage();
                // if (image) {
                //   localStorage.setItem("profilePicture", baseImage);
                //   setAccountImage(baseImage);
                // }
                history.push("/admin/raffles");
                // handleClose();
            })
            .catch((err) => {
                if (err?.response?.statusCode == 501) {
                    history.push("/");
                } else {
                    console.log("error meessage: ", err?.response?.data?.message);

                    toast.error(err?.response?.data?.message, {
                        position: "bottom-left",
                        autoClose: 2000,
                    });
                }
            });
        // }
    };

    useEffect(() => {
        var val = window.location.href;
        val = new URL(val);
        setId(val.searchParams.get("id"));
        localStorage.setItem("currentId", val.searchParams.get("id"));
        // window.scroll(0, 0);
        if (id) {
            getImageDetail(id);
        }
    }, [id]);

    const getSweep = () => {
        setOpen(true);
        axios
            .get(Environment.backendUrl + `/api1/admin/sweepFunction?raffles_id=${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setOpen(false);
                toast.success(response?.data?.msg);
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };

    const getImageDetail = (id) => {
        setOpen(true);
        axios
            .get(Environment.backendUrl + `/api1/admin/get/RaffleById?id=${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setOpen(false);
                console.log(response?.data?.imagesData, "data");

                const raffleData = response?.data?.rafflesData;


                const formatDate = (dateString) => {
                    if (!dateString) return '';
                    const date = new Date(dateString);
                    return date.toISOString().slice(0, 16);
                };

                setName(raffleData?.name);
                setDescription(raffleData?.description);
                setTicket(raffleData?.totalTickets);
                setPrice(raffleData?.pricePerTicket);
                setMaxcap(raffleData?.maxCap);
                setBaseImage(raffleData?.image);
                setStartDate(formatDate(raffleData?.startDate));
                setEndDate(formatDate(raffleData?.endDate));
                setList(raffleData?.RafflesTickets);
                setSweep(response?.data?.ticketNumbers);
            })
            .catch((err) => {
                setOpen(false);
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            });
    };


    const editRaffles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (price) {
            formData.append("pricePerTicket", price);
        }
        if (name) {
            formData.append("name", name);
        }
        if (description) {
            formData.append("description", description);
        }
        if (ticket) {
            formData.append("totalTickets", ticket);
        }
        if (image) {
            formData.append("image", image);
        }
        if (maxcap) {
            formData.append("maxCap", maxcap);
        }
        if (startDate) {
            formData.append("startDate", startDate);
        }
        if (endDate) {
            formData.append("endDate", endDate);
        }
        if (id) {
            formData.append("id", id);
        }
        const config = {
            method: "post",
            url: Environment.backendUrl + "/api1/admin/edit/Raffles",
            data: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        };
        await axios(config)
            .then((res) => {
                console.log(res, "mmm");
                toast.success(res?.data?.msg);
                setBaseImage();
                setImage();
                // if (image) {
                //   localStorage.setItem("profilePicture", baseImage);
                //   setAccountImage(baseImage);
                // }
                history.push("/admin/raffles");
                // handleClose();
            })
            .catch((err) => {
                if (err?.response?.statusCode == 501) {
                    history.push("/");
                } else {
                    console.log("error meessage: ", err?.response?.data?.message);

                    toast.error(err?.response?.data?.message, {
                        position: "bottom-left",
                        autoClose: 2000,
                    });
                }
            });
        // }
    };



    return (
        <>

            <div className="content">
                <div className="row bg-color-raffle">
                    <div className="col-xl-8 col-12">
                        <div className="addraffles">
                            <div className="upload-img">
                                <label> Image</label>
                                <p className='sixeimgtext'>Image size: 183x177</p>
                                <div className="upload">
                                    <label htmlFor="upload">
                                        {baseImage ? (
                                            <>
                                                <img
                                                    src={baseImage}
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img src="\dashboard-assets\upload.svg" alt="img" className='img-fluid' />
                                                <h6>Drag & Drop or <span >Browse</span></h6>

                                            </>
                                        )}

                                    </label>

                                </div>

                                {/* <p className="text-danger">Image is missing!</p> */}
                                <input type="file" name='image' className='d-none' id='upload' onChange={uploadImages} />
                            </div>
                            <div className="option-field">
                                <label>Name</label>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} name='name' type="text" placeholder='Name' />
                                {/* <p className="text-danger">name is missing!</p> */}
                            </div>
                            <div className="option-field">
                                <label>Description</label>
                                <textarea value={description}
                                    onChange={(e) => setDescription(e.target.value)} name='description' placeholder='Description' />
                                {/* <p className="text-danger">description is missing!</p> */}
                            </div>
                            <div className="option-field">
                                <label>Total No. of tickets</label>
                                <input value={ticket}
                                    onChange={(e) => setTicket(e.target.value)} name='totalTickets' type="number" min={0} placeholder='Total No. of tickets' />
                            </div>
                            <div className="option-field">
                                <label>Price per ticket</label>
                                <input value={price}
                                    onChange={(e) => setPrice(e.target.value)} name='pricePerTicket' type="text" placeholder='Price per ticket' />
                            </div>
                            <div className="option-field">
                                <label>MaxCap</label>
                                <input
                                    disabled={ticket?.length <= 0}
                                    value={maxcap}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value, 10);

                                        if (!isNaN(value) && value < ticket) {
                                            setMaxcap(value);
                                        }
                                        else if (isNaN(value) || value >= ticket) {
                                            setMaxcap('');
                                        }
                                    }}
                                    name='maxCap'
                                    type="number"
                                    min={0}
                                    placeholder={ticket?.length <= 0 ? "please enter No of Tickets" : "MaxCap must be smaller than Tickets"}
                                />
                            </div>
                            {/* <div className="option-field">
                                <label>Category</label>
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Category
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                     
                                                <p class="dropdown-item">Category items</p>
                                       
                                    </div>
                                </div>
                           
                            </div> */}
                            <div className="twice-field">
                                <div className="option-field">
                                    <label>Start Date/Time</label>
                                    <input
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        name="startDate"
                                        type="datetime-local"
                                        placeholder="Start Date/Time"
                                    />
                                </div>
                                <div className="option-field">
                                    <label>End Date/Time</label>
                                    <input
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        name="endDate"
                                        type="datetime-local"
                                        placeholder="End Date/Time"
                                    />
                                </div>
                            </div>

                            <div className="bottom-btnn">
                                <button onClick={
                                    id ? (e) => editRaffles(e) : (e) => addraffles(e)
                                } className='red-bybuy'>{id ? "Update" : "Publish"}</button>
                                <Link to="/admin/raffles">
                                <button className='red-wy-y'>Cancel</button></Link>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-12">
                        {id &&
                            <div className="topCard">
                                <span className='pH'>
                                    <p className='ticketcclass'>Ticket Booked</p>
                                    <h4 className='ticketcclass1'>{sweep?.length > 0 ? sweep?.length : 0}/{ticket}</h4>
                                </span>
                                <span>
                                    <button onClick={getSweep} className='buttimggg'>Sweep Raffle</button>

                                </span>
                            </div>
                        }
                        {id && list?.length > 0 &&
                            <div className="sidebar-raffle">

                                <div className="main-card">
                                    <label>Leaderboard</label>
                                    {list?.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index} className="ticket">
                                                    <div className="left">
                                                        <span className='spandivv'>{index + 1}</span>
                                                        <div className="profile">
                                                            <div className="inner-left">
                                                                <div className="profile-img">
                                                                    {item?.User?.image ?
                                                                        <img src={item?.User?.image?.link || item?.User?.image} alt="img" className='img-fluid' /> :
                                                                        <img src="/dashboard-assets/actor 2.png" alt="img" className='img-fluid' />

                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="inner-right">
                                                                <h6 className='tomdiv'>{item?.User?.user_name}</h6>
                                                                <p className='tomdiv1'>Tickets No: {item?.ticketbought}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="right">
                                        <button className='blue-btnww' data-toggle="modal" data-target="#viewticket">View Tickets</button>
                                    </div> */}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>


                                {/* <div className="main-card">
                                        <label>Leaderboard</label>
                                        
                                                <div className="ticket">
                                                    <div className="left">
                                                        <span></span>
                                                        <div className="profile">
                                                            <div className="inner-left">
                                                                <div className="profile-img">
                                                                    <img src="" alt="img" className='img-fluid' />
                                                                </div>
                                                            </div>
                                                            <div className="inner-right">
                                                                <h6></h6>
                                                                <p>Tickets: </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        {raffelCat?.ticketBooked?.length > 0 && <button className='blue-btn' data-toggle="modal" data-target="#viewticket">View Tickets</button>}
                                                    </div>
                                                </div>
                                        

                                    </div> */}


                            </div>
                        }
                    </div>

                </div>

                {/* modal view tickets ................... */}


                <div class="modal fade view-tickets" id="viewticket" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Tickets Number</h5>
                            </div>
                            <div class="modal-body">
                                <div className="countssss">
                                    <div>
                                        <div className='nummains1'>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                        </div>
                                        <div className='nummains1'>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                        </div>
                                        <div className='nummains1'>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                        </div>
                                        <div className='nummains1'>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                            <p className='nummains'>3</p>
                                        </div>
                                    </div>
                                    <button className='closebut'>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Addraffles
