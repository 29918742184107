import React, { useEffect, useState } from "react";
import "./rewardlea.scss";
import { Link } from "react-router-dom";
import { Nav, Pagination } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";

const Adddstasks = () => {
  const token = localStorage.getItem('mytoken')
  const [myFiles, setMyFiles] = useState([]);
  const [proof, setProof] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [id, setId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeTab, setActiveTab] = useState("link-1");
  const [open, setOpen] = useState(false);
  const [verified, setVerified] = useState("");
  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const [activeTab1, setActiveTab1] = useState("link-1");

  const handleSelect1 = (eventKey) => {
    setActiveTab1(eventKey);
  };
  const [activeTab11, setActiveTab11] = useState("link-1");

  const handleSelect11 = (eventKey) => {
    setActiveTab11(eventKey);
  };
  const [activeTabiner, setActiveTabiner] = useState("link-1");

  const handleSelectiner = (eventKey) => {
    setActiveTabiner(eventKey);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getTaskDetail(id);
    }
  }, [id]);


  const getTaskDetail = (idValue) => {
    setOpen(true);
    axios.get(
        Environment.backendUrl + `/api1/admin/getTaskCompletedUser?id=${idValue}`, 
        { headers: { "Authorization": `Bearer ${token}` } }
    )
    .then((response) => {
        setOpen(false);
        setMyFiles(response.data?.TaskTracks);
        setVerified(response.data?.TaskTracks?.verified);

        console.log(response.data?.TaskTracks,"bbb");
  
    })
    .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
            position: "top-center",
            autoClose: 2000,
        });
    });
  };

  const handleToggleChange = async (id, verified) => {
    try {
      // Toggle the verified value
      const newVerified = !verified;
  
      // Make the API call
      const response = await axios.get(
        `${Environment.backendUrl}/api1/admin/VerifyTaskProof?id=${id}&verified=${newVerified}`,
        { headers: { "Authorization": `Bearer ${token}` } }
      );
  
      // Handle the response as needed
      console.log(response.data?.msg);
      toast.success(response.data?.msg);
    } catch (error) {
      // Handle the error as needed
      console.error('Error verifying task proof:', error);
    }
  };

  const handleProof = async (id) => {
    try {
     
  
      const response = await axios.get(
        `${Environment.backendUrl}/api1/admin/getTaskTrackDtails?taskTrackId=${id}`,
        { headers: { "Authorization": `Bearer ${token}` } }
      );
  
      // Handle the response as needed
      setProof(response.data);
    } catch (error) {
      // Handle the error as needed
      console.error('Error verifying task proof:', error);
    }
  };
  

  return (
    <>
      <div className="content">
        <section className="main-tasks user-details maintablea_leader">
          <div className="lowertabsss">
            <Nav
              variant="pills"
              activeKey={activeTab1}
              onSelect={handleSelect1}
              className="amberpillsoutersssty newtabsstyl"
            >
              <Nav.Item className="amberitempilsssww  inertabtask">
                <Nav.Link className="inerambss" eventKey="link-1">
                  Verified
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="amberitempilsssww inertabtask">
                <Nav.Link className="inerambss" eventKey="link-2222">
                  Unverified
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          {activeTab1 === "link-1" && (
            <>
              <div className="maintablea_task">
                <div className="innertable">
                  <table>
                    <thead>
                      <th>Users Name</th>
                      <th>Email </th>
                      <th>Proof</th>
                      <th>Status </th>
                      <th>Verified</th>
                    </thead>
                    <tbody>
                    {myFiles?.map((item, index) => {
                                return (
                                  <>
                      <tr key={index}>
                        <td>
                          <div className="newbarrrr">
                            <img
                              src={item?.User?.image?.link}
                              className="taskimg"
                            ></img>
                            <p>{item?.User?.image?.name}</p>
                          </div>
                        </td>
                        <td>{item?.User?.email}</td>
                        <td>
                          <span className="eleipiess" onClick={() => {handleShow(); handleProof(item?.id)}}>
                            <svg className="edit"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M12.5 5.04883C7.72348 5.04883 3.39189 7.6621 0.195612 11.9068C-0.0652041 12.2545 -0.0652041 12.7403 0.195612 13.0881C3.39189 17.3379 7.72348 19.9511 12.5 19.9511C17.2765 19.9511 21.6081 17.3379 24.8044 13.0932C25.0652 12.7455 25.0652 12.2596 24.8044 11.9119C21.6081 7.6621 17.2765 5.04883 12.5 5.04883ZM12.8426 17.747C9.67193 17.9464 7.05354 15.3332 7.25299 12.1573C7.41664 9.53896 9.53897 7.41663 12.1574 7.25298C15.3281 7.05353 17.9465 9.66681 17.747 12.8426C17.5782 15.4559 15.4559 17.5782 12.8426 17.747ZM12.6841 15.3229C10.976 15.4303 9.56454 14.024 9.67705 12.3159C9.76399 10.9044 10.9095 9.76398 12.321 9.67192C14.0291 9.56453 15.4406 10.9709 15.3281 12.679C15.236 14.0956 14.0905 15.236 12.6841 15.3229Z"
                                fill="url(#paint0_linear_362_5212)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_362_5212"
                                  x1="12.5373"
                                  y1="12.5"
                                  x2="54.3282"
                                  y2="12.4184"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#3553D5" />
                                  <stop offset="1" stop-color="#0C194E" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                        </td>
                        <td><p className="newtdclassss">{item?.status}</p></td>


                        <td ><div className="main-switch-nn vvvvvv">
                          <div class="custom-control custom-switch">
                            <input defaultChecked={item?.verified}   onChange={ () => handleToggleChange(item?.id,item?.verified)} type="checkbox" class="custom-control-input" id={`customSwitches-${item?.id}`} />
                            <label class="custom-control-label"  htmlFor={`customSwitches-${item?.id}`}></label>
                          </div>
                        </div></td>

                      </tr>
                      </>
                                );
                              })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {activeTab1 === "link-2222" && (
            <>
              <div className="maintablea_task">
                <div className="innertable">
                  <table>
                    <thead>
                      <th>Users Name</th>
                      <th>Email </th>
                      <th>Proof</th>
                      <th>Status </th>
                      <th>Verified</th>
                    </thead>
                    <tbody>
                    {myFiles?.map((item, index) => {
                                return (
                                  <>
                      <tr key={index}>
                        <td>
                          <div className="newbarrrr">
                            <img
                              src={item?.User?.image?.link}
                              className="taskimg"
                            ></img>
                            <p>{item?.User?.image?.name}</p>
                          </div>
                        </td>
                        <td>{item?.User?.email}</td>
                        <td>
                          <span className="eleipiess" onClick={handleShow}>
                            <svg className="edit"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M12.5 5.04883C7.72348 5.04883 3.39189 7.6621 0.195612 11.9068C-0.0652041 12.2545 -0.0652041 12.7403 0.195612 13.0881C3.39189 17.3379 7.72348 19.9511 12.5 19.9511C17.2765 19.9511 21.6081 17.3379 24.8044 13.0932C25.0652 12.7455 25.0652 12.2596 24.8044 11.9119C21.6081 7.6621 17.2765 5.04883 12.5 5.04883ZM12.8426 17.747C9.67193 17.9464 7.05354 15.3332 7.25299 12.1573C7.41664 9.53896 9.53897 7.41663 12.1574 7.25298C15.3281 7.05353 17.9465 9.66681 17.747 12.8426C17.5782 15.4559 15.4559 17.5782 12.8426 17.747ZM12.6841 15.3229C10.976 15.4303 9.56454 14.024 9.67705 12.3159C9.76399 10.9044 10.9095 9.76398 12.321 9.67192C14.0291 9.56453 15.4406 10.9709 15.3281 12.679C15.236 14.0956 14.0905 15.236 12.6841 15.3229Z"
                                fill="url(#paint0_linear_362_5212)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_362_5212"
                                  x1="12.5373"
                                  y1="12.5"
                                  x2="54.3282"
                                  y2="12.4184"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#3553D5" />
                                  <stop offset="1" stop-color="#0C194E" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                        </td>
                        <td><p className="newtdclassss">{item?.status}</p></td>


                        <td ><div className="main-switch-nn vvvvvv">
                          <div class="custom-control custom-switch">
                            <input defaultChecked={item?.verified}   onChange={ () => handleToggleChange(item?.id,item?.verified)} type="checkbox" class="custom-control-input" id={`customSwitches-${item?.id}`} />
                            <label class="custom-control-label"  htmlFor={`customSwitches-${item?.id}`}></label>
                          </div>
                        </div></td>

                      </tr>
                      </>
                                );
                              })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </section>
        <Modal
          className="ambmodalmain userlastmodal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              details
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M12.0008 13.9008L7.10078 18.8008C6.91745 18.9841 6.68411 19.0758 6.40078 19.0758C6.11745 19.0758 5.88411 18.9841 5.70078 18.8008C5.51745 18.6174 5.42578 18.3841 5.42578 18.1008C5.42578 17.8174 5.51745 17.5841 5.70078 17.4008L10.6008 12.5008L5.70078 7.60078C5.51745 7.41745 5.42578 7.18411 5.42578 6.90078C5.42578 6.61745 5.51745 6.38411 5.70078 6.20078C5.88411 6.01745 6.11745 5.92578 6.40078 5.92578C6.68411 5.92578 6.91745 6.01745 7.10078 6.20078L12.0008 11.1008L16.9008 6.20078C17.0841 6.01745 17.3174 5.92578 17.6008 5.92578C17.8841 5.92578 18.1174 6.01745 18.3008 6.20078C18.4841 6.38411 18.5758 6.61745 18.5758 6.90078C18.5758 7.18411 18.4841 7.41745 18.3008 7.60078L13.4008 12.5008L18.3008 17.4008C18.4841 17.5841 18.5758 17.8174 18.5758 18.1008C18.5758 18.3841 18.4841 18.6174 18.3008 18.8008C18.1174 18.9841 17.8841 19.0758 17.6008 19.0758C17.3174 19.0758 17.0841 18.9841 16.9008 18.8008L12.0008 13.9008Z"
                  fill="white"
                />
              </svg>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="userlastmod">
            <div className="userdtalcntrmod">
              <div className="imginggh">
                <p className="videhumbnail">Proof of work</p>
                <div className="mainimgdivmod">
                  {/* <div className="inerimgdmod">
                    <img
                      src={proof?.proofImage1}
                      className="tableimgginermod"
                    ></img>
                  </div> */}
                   <div className="nameddiv">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active inerimgdmod">
                    <img class="d-block" src={proof?.proofImage1} alt="First slide" className="tableimgginermod" />
                  </div>
                  {proof?.proofImage2 && (
                  <div class="carousel-item inerimgdmod">
                    <img class="d-block " src={proof?.proofImage2} alt="Second slide"  className="tableimgginermod" />
                  </div>
                     )}
                     {proof?.proofImage3 && (
                  <div class="carousel-item inerimgdmod">
                    <img class="d-block" src={proof?.proofImage3} alt="Third slide"  className="tableimgginermod" />
                  </div>
                     )}
                     {proof?.proofImage4 && (
                  <div class="carousel-item inerimgdmod">
                    <img class="d-block" src={proof?.proofImage4} alt="Fourth slide"  className="tableimgginermod" />
                  </div>)}
                  {proof?.proofImage5 && (
                  <div class="carousel-item inerimgdmod">
                    <img class="d-block" src={proof?.proofImage5} alt="Fifth slide"  className="tableimgginermod" />
                  </div>)}
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
              {/* <img src="\dashboard-assets\insta.png"/> */}
            </div>
                </div>
              </div>
              <div className="imginggh">
                <p className="videhumbnail">Proof of link:</p>
                <h5 className="videhumbnailss">
                  {proof?.taskProofLink}
                </h5>
              </div>
              <div className="imginggh">
                <p className="videhumbnail">Task number </p>
                <h5 className="videhumbnailss">
                  {proof?.task_id}
                </h5>
              </div>
              <div className="imginggh">
                <p className="videhumbnail">Task Name</p>
                <h5 className="videhumbnailss">
                 {proof?.Task?.name}
                </h5>
              </div>
              <div className="imginggh">
                <p className="videhumbnail">Description</p>
                <h5 className="videhumbnailss">
                {proof?.Task?.description}
                </h5>
              </div>
              <div className="imginggh">
                <p className="videhumbnail">Date/Time</p>
                <h5 className="videhumbnailss"> {moment(proof?.createdAt).format(
                                        "DD-MMM-YYYY"
                                      )}</h5>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* <Modal className='ambmodalmain userlastmodal  addrewads' show={show} onHide={handleClose} centered>
          <Modal.Body className='userlastmodrewards3 heydiv'>
            <div className="nameddiv">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class="d-block" src="\dashboard-assets\insta.png" alt="First slide" />
                  </div>
                  <div class="carousel-item">
                    <img class="d-block " src="\dashboard-assets\insta.png" alt="Second slide" />
                  </div>
                  <div class="carousel-item">
                    <img class="d-block" src="\dashboard-assets\insta.png" alt="Third slide" />
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
              <img src="\dashboard-assets\insta.png"/>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};

export default Adddstasks;
