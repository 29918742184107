import { Backdrop, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Environment from "utils/Environment";
import "./productstype.scss";
import ReactPaginate from "react-paginate";


const Productstype = () => {
  const token = localStorage.getItem('mytoken')
  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);

         // pagination ============

         const [limit] = useState(10);
         const [page, setPage] = useState(0);
         const [pageCount, setPageCount] = useState([]);
       
         const handlePageChange = (e) => {
           const selectedPage = e.selected;
           setPage(selectedPage);
         };
       
         // pagination ============

  const getProducts = () => {
    setOpen(true);
    axios.get(
        Environment.backendUrl + `/api1/admin/get/AllproductTypes?limit=${limit}&offset=${page*limit}`,
        { headers: { "Authorization": `Bearer ${token}` } }
    )
        .then((response) => {
            setOpen(false);
            console.log('Text', response?.data);
            setMyFiles(response?.data?.data);
            setPageCount(response?.data?.count);

        })
        .catch((err) => {
            setOpen(false);
            toast.error(err.response?.data.msg, {
                position: "top-center",
                autoClose: 2000,
            });
        });
};



useEffect(() => {
  getProducts()
}, [page])

  return (
    <>
      <div className="content">
      <div className="maintableauser warrrrty newtraee">
      <section className="products myproductss lastproddd">
          <div className="productstable">
            <table>
              <thead  className="trrrdata">
                <th><p className="produstbbbb rareboxxxx">Product Name</p></th>
                <th><p className="produstbbbb rareboxxxx">Product description</p></th>
                <th><p className="produstbbbb rareboxxxx">Total quantity</p></th>
                <th><p className="produstbbbb rareboxxxx">
                Quantity left
                </p>
                 
                </th>
                <th>
                <p className="produstbbbb rareboxxxx">
                Category
               </p>
                </th>
              
                <th><p className="produstbbbb rareboxxxx">Actions</p></th>
              </thead>
              <tbody>
              {myFiles?.map((item, index) => {
                      return (
                        <>
                <tr key={index} className="trrrdata">
                  <td> <p className="rareboxxxx eleipiesst produstbbbb"> {item?.productName}</p></td>
                  <td>
                    <p className="rareboxxxx eleipiesst produstbbbb">{item?.productDescription}</p>
                  </td>
                  <td><p className="rareboxxxx eleipiesst produstbbbb">{item?.productQuantity}</p></td>
                  <td><p className="rareboxxxx eleipiesst produstbbbb" >{item?.quantityLeft}</p></td>
                  <td><p className="rareboxxxx eleipiesst produstbbbb">{item?.ProductCategory?.categoryName}</p></td>
                  <td className="editbutttr">
                    <Link to={"addproduct-type?id=" + item?.id}>
                    <div className="produstbbbb">
                    <button className="bluebttttn ">Edit</button>
                    </div>
                      
                    </Link>
                  </td>
                </tr>
                </>
                      );
                    })}
              </tbody>
            </table>
            {/* <div className="Paginationlattable">
              <button className="leftpigbtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15"
                    stroke="#5F6D7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                Prev
              </button>
              <Pagination>
                <Pagination.Item className="warrrrru">{1}</Pagination.Item>
                <Pagination.Item className="warrrrru">{2}</Pagination.Item>
                <Pagination.Ellipsis className="warrrrru" />
                <Pagination.Item active>{5}</Pagination.Item>
                <Pagination.Item className="warrrrru">{6}</Pagination.Item>
              </Pagination>
              <button className="leftpigbtn">
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15"
                    stroke="#5F6D7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div> */}
          </div>
          <div className='Paginationlattable'>
              {/* <button className='leftpigbtn' >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                Prev
              </button> */}
              {page >= 0 ?
                                                    <ReactPaginate
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        pageCount={Math.ceil(pageCount / limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={page}
                                                    />
                                                    : ''} 
              {/* <button className='leftpigbtn' >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15" stroke="#5F6D7E" stroke-width="1.5" stroke-linecap="round" />
                </svg>

              </button> */}
            </div>
        </section>
          </div>
       
      </div>
    </>
  );
};

export default Productstype;
