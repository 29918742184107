import React, { useEffect, useState } from "react";
import './userprofile.scss';
import { Link } from "react-router-dom";
import { Nav, Pagination } from "react-bootstrap";

const Profileimages = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [activeTab1, setActiveTab1] = useState('link-1');

    const handleSelect1 = (eventKey) => {
        setActiveTab1(eventKey);
    }
    const [activeTab11, setActiveTab11] = useState('link-1');

    const handleSelect11 = (eventKey) => {
        setActiveTab11(eventKey);
    }
    const [activeTabiner, setActiveTabiner] = useState('link-1');

    const handleSelectiner = (eventKey) => {
        setActiveTabiner(eventKey);
    }

    return (
        <>
            <div className="content">
            <div className="container-fluid">
                <section className="main-tasks user-details maintablea_leader">
       <div className="lowertabsss myhome">
                                <Nav variant="pills" activeKey={activeTab1} onSelect={handleSelect1} className='amberpillsoutersssrt  newtabsstyl mytabsdiv'>
                                    <Nav.Item className='amberitempilsss  inertabtask '>
                                        <Nav.Link className='inerambss newtabslink ' eventKey="link-1">Default Profile images </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='amberitempilsss inertabtask'>
                                        <Nav.Link className='inerambss newtabslink' eventKey="link-2222">Premium Profile images  </Nav.Link>
                                    </Nav.Item>


                        </Nav>

                    </div>
                    {activeTab1 === 'link-1' && (
                        <>
                            <div className='maintablea_task'>
                                <div className="innertable">
                                    <table>
                                        <thead>
                                            <th>
                                                Profile
                                            </th>
                                            <th> Holdings </th>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="taskinertablesecroww">
                                                        <img src="\rewards\fimg.svg" className="taskimg" />
                                                        <p className="Synergy">
                                                            Carolyn Wilson
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    25K Holders

                                                </td>


                                            </tr>


                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </>
                    )}
                    {activeTab1 === 'link-2222' && (
                        <>
                            <div className='maintablea_task'>
                                <div className="productstable">
                                    <table>
                                        <thead>
                                            <th>Profile</th>
                                            <th>
                                                Price

                                            </th>
                                            <th>
                                                Holdings
                                            </th>
                                            <th>Upload Date</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="productimg">
                                                        <img
                                                            src="\dashboard-assets\boxxx.png"
                                                            alt="innerimg"
                                                            className="innerimg"
                                                        />
                                                        <p className="rareboxxxx">Carolyn Wilson</p>
                                                    </div>
                                                </td>
                                                <td> <p className="rareboxxxx">50 xBlue</p></td>
                                                <td>
                                                    <p className="rareboxxxx">80K Holders</p>
                                                </td>
                                                <td>
                                                    <p className="rareboxxxx">Aug 25, 2023</p>

                                                </td>
                                                <td>
                                                    <Link to={`/admin/adduserprofiledetail`}>
                                                        <button className="bluebttttn">Edit</button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="Paginationlattable">
                                        <button className="leftpigbtn">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                            >
                                                <path
                                                    d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15"
                                                    stroke="#5F6D7E"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                            Prev
                                        </button>
                                        <Pagination>
                                            <Pagination.Item className="warrrrru">{1}</Pagination.Item>
                                            <Pagination.Item className="warrrrru">{2}</Pagination.Item>
                                            <Pagination.Ellipsis className="warrrrru" />
                                            <Pagination.Item active>{5}</Pagination.Item>
                                            <Pagination.Item className="warrrrru">{6}</Pagination.Item>
                                        </Pagination>
                                        <button className="leftpigbtn">
                                            Next
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                            >
                                                <path
                                                    d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15"
                                                    stroke="#5F6D7E"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}
                </section>
                </div>
            </div>
        </>
    )
}

export default Profileimages
