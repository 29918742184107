import React, { useEffect, useState } from 'react'
import './coin1.scss'
import { toast } from 'react-toastify';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import Environment from "utils/Environment";
import { useHistory } from 'react-router';

const Addrolemanagement = (props) => {
 

    return (
        <>
            <div className='content'>
                <section className='addrole'>
                    <div className='container-fluid p-0'>
                        <div className="row">
                            <div className="col-xl-6 col-sm-12  p-0">
                                <div className="outermain">
                                    <p className='py-3 imhggggg'>Image</p>

                                    <div className="main-image-div">
                                        <div className="choose-filessssssdds">
                                            <form>


                                                <div className="nsjddddd">
                                                    <label className="text-centerr" for="filess"><svg xmlns="http://www.w3.org/2000/svg" width="62" height="45" viewBox="0 0 62 45" fill="none">
                                                        <path d="M48.1633 44.6938H38.304H35.6492H35.0758V31.4692H39.4009C40.4978 31.4692 41.1459 30.2228 40.4978 29.3254L32.0844 17.6837C31.5484 16.9359 30.4391 16.9359 29.9031 17.6837L21.4898 29.3254C20.8416 30.2228 21.4773 31.4692 22.5866 31.4692H26.9117V44.6938H26.3384H23.6835H12.2538C5.71005 44.3323 0.5 38.2124 0.5 31.5814C0.5 27.007 2.98038 23.0184 6.65734 20.8621C6.3208 19.9522 6.1463 18.98 6.1463 17.958C6.1463 13.2839 9.92297 9.50721 14.5971 9.50721C15.6067 9.50721 16.5789 9.6817 17.4888 10.0182C20.1935 4.28469 26.0268 0.308594 32.8073 0.308594C41.5821 0.321058 48.8114 7.03929 49.634 15.6022C56.3772 16.7614 61.5 23.006 61.5 30.0732C61.5 37.6265 55.6169 44.1703 48.1633 44.6938Z" fill="#EDEDEE" />
                                                    </svg>
                                                        <h6 className="drtuop">
                                                            Drag & Drop or <span className="commonfffr">Browse</span>
                                                        </h6>
                                                    </label>
                                                    <input type="file" name="image" id="filess" className='form-control d-none' />
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label className="adminheadings" for="example">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="role"
                                            
                                            value=""
                                            className={"form-control"}
                                            placeholder="Name"
                                        />
                                        {/* {Object.keys(TitleError).map((key) => {
                                            return <p className="inputErrors">{TitleError[key]}</p>
                                        })} */}
                                    </div>
                                    <div class="form-group">
                                        <label className="adminheadings" for="example">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="role"
                                            
                                            value=""
                                            className={"form-control "}
                                            placeholder="Email"
                                        />
                                        {/* {Object.keys(TitleError).map((key) => {
                                            return <p className="inputErrors">{TitleError[key]}</p>
                                        })} */}
                                    </div>
                                </div>
                           
                            </div>
                      
                        </div>
                        <div className="main">
                                    <div className="col-12">
                                        <div className="userdivv">
                                            <h6 className="userfloooow">Users</h6>
                                            <div className="mainshead">
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userdivv">
                                            <div className="mainshead">
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                                <div class="formcheck_newwwwws">
                                                    <label class="checkBox m-0 warrrrw">
                                                        <input
                                                            type="checkbox"
                                                            id="ch1"
                                                            className="boxxxxx"
                                                        />
                                                        <p className="inputtextt">Role #01</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="form-group">
                                            <div className="videopage">
                                            <button className="red-wy-y">
                                                    Cancel
                                                </button>
                                                   
                                                    <button className="red-bybuy" >
                                                        Publish
                                                    </button>
                         
                                               
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                   
                             
                    </div>
                </section>
            </div>
        </>
    )
}

export default Addrolemanagement