
import React, { useEffect, useState } from "react";
import './notification.scss';

import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
function Notificationss() {





    return (
        <>

            <div className="content">

                <section className="videos notifications card">
                    <div className="">
                        <div class="table-responsive">
                            <div className="innertable-notificationss">
                                <table>
                                    <thead>
                                        <th>Message</th>
                                        <th > Creation Date <img src="/dashboard-assets/Arrow - Down 3.svg" className="pl-1" alt="" /></th>
                                        <th > Platform <img src="/dashboard-assets/Arrow - Down 3.svg" className="pl-1" alt="" /></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="onlyboldtext">Sed ut perspiciatis unde omnis iste natus error</td>
                                            <td className="onlyboldtext">Aug 18, 2021</td>
                                            <td className="onlyboldtext">All</td>
                                        </tr>
                                        <tr>
                                            <td className="onlyboldtext">Sed ut perspiciatis unde omnis iste natus error</td>
                                            <td className="onlyboldtext">Aug 18, 2021</td>
                                            <td className="onlyboldtext">All</td>
                                        </tr>
                                        <tr>
                                            <td className="onlyboldtext">Sed ut perspiciatis unde omnis iste natus error</td>
                                            <td className="onlyboldtext">Aug 18, 2021</td>
                                            <td className="onlyboldtext">Android</td>
                                        </tr>
                                        <tr>
                                            <td className="onlyboldtext">Sed ut perspiciatis unde omnis iste natus error</td>
                                            <td className="onlyboldtext">Aug 18, 2021</td>
                                            <td className="onlyboldtext">IOS</td>
                                        </tr>
                                        <tr>
                                            <td className="onlyboldtext">Sed ut perspiciatis unde omnis iste natus error</td>
                                            <td className="onlyboldtext">Aug 18, 2021</td>
                                            <td className="onlyboldtext">IOS</td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
}

export default Notificationss;
