import React from 'react'
import "./subscription.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Subscription = () => {
 
    return (
        <>
            <div className="content">
                <div className="subscription">
                    <div class="table-responsive">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th>Banner Image</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                 <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="main-t-body-text" >
  
                                        <tr>
                                            <td >
                                                <div className="sub-img">
                                                    <img src="\dashboard-assets\frame.png" alt="img" className='img-fluid' />
                                                </div>
                                            </td>
                                            <td className='predivv'>Premium Subscription</td>
                                            <td className='predivv'>$80.00</td>
                                           <td>
                                                <Link to={"/admin/addsubscription/:id"}> <button className='blue-btn'>Edit</button></Link>
                                            </td>
                                          
                                        </tr>
                  
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Subscription
