import React, { useState, useEffect } from 'react'
import "./boltmanagement.scss"
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Environment from 'utils/Environment';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import axios from 'axios';
const Addboltcategory = () => {
  const token = localStorage.getItem('mytoken')
  const history = useHistory();
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [xBlue, setXBlue] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getTaskDetail(id);
    }
  }, [id]);


  const getTaskDetail = (id) => {

    axios.get(
      Environment.backendUrl + `/api1/admin/get/XblueDetailsById?id=${id}`,
      { headers: { "Authorization": `Bearer ${token}` } }
    )
      .then((response) => {

        console.log(response.data, "details");
        setType(response.data?.type);
        setDescription(response.data?.description);
        setXBlue(response.data?.xblueRewardGold);
        setCategory(response.data?.xblueRewardVerified);



      })
      .catch((err) => {

        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const addXBlue = async (e) => {
    e.preventDefault();
    if (
      !type ||
      !description ||
      !xBlue ||
      !category
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/add/xblue`,
      data: {
        type: type,
        description: description,
        xblueRewardGold: xBlue,
        xblueRewardVerified: category,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/boltmanagement");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });

  }


  const editXBlue = async (e) => {
    e.preventDefault();
    const config = {
      method: "post",
      url: `${Environment.backendUrl}/api1/admin/edit/xblue`,
      data: {
        type: type,
        description: description,
        xblueRewardGold: xBlue,
        xblueRewardVerified: category,
        id: id,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        history.push("/admin/boltmanagement");
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });

  }
  return (
    <>
      <div className="content mutabbb">
        <section className="boltcategories">
          <div className="container-fluid p-0">
            <div className='col-sm-12 col-lg-8 p-0'>
              <p>Type</p>
              <input value={type} onChange={(e) => setType(e.target.value)} type="text" placeholder='Verified Referral' />
              <p>Description</p>
              <input value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder='Description' />
              <p>Reward GoldVerified</p>
              <input value={xBlue} onChange={(e) => setXBlue(e.target.value)} type="number" min={0} placeholder='10' />
              <p>Reward Verified </p>
              <input value={category} onChange={(e) => setCategory(e.target.value)} type="number" min={0} placeholder='10' />
              {/* <div class="form-group ">
                                                <label className="padd-top mytab1 bluetabbbb" for="example">Category</label>
                                                <div class="dropdown">
                                                <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55666">{category ? category : "select category"}</p>
                              <i class="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="6"
                                  viewBox="0 0 9 6"
                                  fill="none"
                                >
                                  <path
                                    d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                    fill="#3654D6"
                                  />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a onClick={() => setCategory("Verified")} className="dropdown-item taskdiv ">Verified</a>
                              <a  onClick={() => setCategory("GoldVerified")} className="dropdown-item taskdiv">GoldVerified</a>
                              
                            </div>
                          </div>
                                                </div>
                                            </div> */}
            </div>
          </div>
          <div className='mainofbuttonss'>
            <button onClick={id ? (e) => editXBlue(e) : (e) => addXBlue(e)} className='red-bybuy'>{id ? "Update" : "Save"}</button>
            <button className='red-wy-y' >Cancel</button>
          </div>
        </section>
      </div>


    </>
  )
}

export default Addboltcategory