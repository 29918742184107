import React, { useState, useEffect } from "react";
import "./banner.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
// reactstrap components
const Addbanners = (props) => {
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const [baseImage, setBaseImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [image, setImage] = useState("");
  const [select, setSelect] = useState("");
  const [show, setShow] = useState("");
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };

  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addImage = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !select ||
      !image
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }

    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (select) {
      formData.append("type", select);
    }
    if (image) {
      formData.append("image", image);
    }
    if (description) {
      formData.append("Description", description);
    }
    if (Quantity) {
      formData.append("quantity", Quantity);
    }
    if (Quantity) {
      formData.append("quantityLeft", Quantity);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/add/images",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res, "mmm");
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/newsbanner");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };


  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getImageDetail(id);
    }
  }, [id]);

  const getImageDetail = (id) => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + `/api1/admin/get/imageById?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        console.log(response?.data?.imagesData, "data");
        setName(response?.data?.imagesData?.name);
        setDescription(response?.data?.imagesData?.Description);
        setQuantity(response?.data?.imagesData?.quantity);
        setSelect(response?.data?.imagesData?.type);
        setShow(response?.data?.imagesData?.type);

        setBaseImage(response?.data?.imagesData?.link);

      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const editImage = async (e) => {
    e.preventDefault();
     
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (select) {
      formData.append("type", select);
    }
    if (image) {
      formData.append("image", image);
    } if (id) {
      formData.append("id", id);
    }
    if (description) {
      formData.append("Description", description);
    }
    if (Quantity) {
      formData.append("quantity", Quantity);
    }
    if (Quantity) {
      formData.append("quantityLeft", Quantity);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/edit/images",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res, "mmm");
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/newsbanner");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };
  return (
    <>
      <div className="content">
        <section className="addbanner12345 card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-8 order-md-0 order-1">

                  <div className="row">
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Name</label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          name="name"
                          className="myinputss"
                          placeholder="New App Release"
                        />
                      </div>
                      <div class="form-group">
                        <label for="example">Description</label>
                        <input
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          type="text"
                          name="name"
                          className="myinputss"
                          placeholder="Descriptione"
                        />
                      </div>
                      <div class="form-group">
                        <label for="example">Quantity</label>
                        <input
                          value={Quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          type="text"
                          name="name"
                          className="myinputss"
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                    {/* <div className="col-12 ">
                        <div class="form-group">
                          <label className="padd-top " for="example">
                            {" "}
                            Description
                          </label>
                          <input
                            name="description"
                            className="myinputsss widthofdis"
                            placeholder=""
                            
                          ></input>
                        </div>
                      </div> */}

                    {/* <div className="col-12 ">
                        <div class="form-group">
                          <label for="example">Category</label>
                          <input
                            type="text"
                            name="category"
                            className="myinputss"
                            placeholder="Enter title of the game"
                          />
                        </div>
                      </div> */}

                    {/* <div className="col-md-12 col-12">
                        <div class="form-group">
                          <label className="padd-top" for="example">
                            Choose Banner Link
                          </label>
                          <input
                            type="text"
                            name="routeLink"
                            className="myinputss"
                            placeholder="Enter link"
                          />
                        </div>
                      </div> */}
                    <div className="col col-12">
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Select Type
                        </label>
                        <div className="dropdown buttons-list-all cshbshjbvch">
                          <button
                            className="button-listing"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <p>{show ? show : "select Type"}</p>
                            <i class="">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="6"
                                viewBox="0 0 9 6"
                                fill="none"
                              >
                                <path
                                  d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                  fill="#3654D6"
                                />
                              </svg>
                            </i>
                          </button>

                          <div
                            className="dropdown-menu zscscsac"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a onClick={() => {
                              setSelect("profile");
                              setShow("Profile");
                            }} className="dropdown-item taskdiv">
                              Profile
                            </a>
                            <a onClick={() => {
                              setSelect("banner");
                              setShow("Banner");
                            }} className="dropdown-item taskdiv">Banner </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div class="form-group">
                      <div className="videopage">
                        <button onClick={
                          id ? (e) => editImage(e) : (e) => addImage(e)
                        } className="red-bybuy">{id ? "Update" : "Publish"}</button>
                        {/* <button className="red-b" onClick={sendVideo}>Publish</button> */}
                        <Link to="/admin/newsbanner">
                          <button className="red-wy-y">Cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-4 order-md-1 order-0">
                  <div class="form-group rightside-for-group">
                    <div className="upload-img ">
                      <label className="uploaddivv">Upload Banner Image</label>
                      <p className="imgsize">Image size: 381x138</p>
                      <div className="upload sidedots">
                        <img src="" className="imgProfile" alt="" />
                        <label htmlFor="upload">
                          {baseImage ? (
                            <>
                              <img
                                src={baseImage}
                                alt="img"
                                className="img-fluid"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src="\dashboard-assets\cloud-computing 1.svg"
                                alt="img"
                                className="img-fluid"
                              />

                            </>
                          )}
                        </label>
                      </div>

                      {/* <p className="text-danger">Image is missing!</p> */}
                      <input
                        type="file"
                        name="image"
                        className="d-none"
                        id="upload"
                        onChange={uploadImages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default Addbanners;
