import React, { useState, useEffect } from "react";
import { Modal, Nav, Pagination } from "react-bootstrap";
import Environment from "utils/Environment";
import moment from "moment/moment";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// import './leaderboards.scss'
import { Link } from "react-router-dom";

const Probability = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("Bronze");
  const [updatedValues, setUpdatedValues] = useState([]);
  const [updatedValuesSilver, setUpdatedValuesSilver] = useState([]);
  const [updatedValuesGold, setUpdatedValuesGold] = useState([]);

  const token = localStorage.getItem("mytoken");
  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [percentage, setPercentage] = useState("");

  const handleChange = (e, rewardName, id) => {
    setUpdatedValuesSilver([]);
    setUpdatedValuesGold([]);

    let newValue = e.target.value;
    if (!newValue.includes("%")) {
      newValue += "%";
    }

    setUpdatedValues((prevValues) => {
      const existingIndex = prevValues.findIndex((item) => item.id === id);

      if (newValue === "%") {
        // Remove the object if the input is empty or just %
        return prevValues.filter((item) => item.id !== id);
      } else if (existingIndex >= 0) {
        // Update the existing object
        const updatedItem = {
          ...prevValues[existingIndex],
          probability: newValue,
        };
        return [
          ...prevValues.slice(0, existingIndex),
          updatedItem,
          ...prevValues.slice(existingIndex + 1),
        ];
      } else {
        // Add a new object
        return [...prevValues, { rewardName, id, probability: newValue }];
      }
    });
  };

  const handleChangeSilver = (e, rewardName, id) => {
    setUpdatedValues([]);
    setUpdatedValuesGold([]);
    let newValue = e.target.value;
    if (!newValue.includes("%")) {
      newValue += "%";
    }
    setUpdatedValuesSilver((prevValues) => {
      const existingIndex = prevValues.findIndex((item) => item.id === id);

      if (newValue === "%") {
        // Remove the object if the input is empty
        return prevValues.filter((item) => item.id !== id);
      } else if (existingIndex >= 0) {
        // Update the existing object
        const updatedItem = {
          ...prevValues[existingIndex],
          probability: newValue,
        };
        return [
          ...prevValues.slice(0, existingIndex),
          updatedItem,
          ...prevValues.slice(existingIndex + 1),
        ];
      } else {
        // Add a new object
        return [...prevValues, { rewardName, id, probability: newValue }];
      }
    });
  };

  const handleChangeGold = (e, rewardName, id) => {
    setUpdatedValuesSilver([]);
    setUpdatedValues([]);
    let newValue = e.target.value;
    if (!newValue.includes("%")) {
      newValue += "%";
    }
    setUpdatedValuesGold((prevValues) => {
      const existingIndex = prevValues.findIndex((item) => item.id === id);

      if (newValue === "%") {
        // Remove the object if the input is empty
        return prevValues.filter((item) => item.id !== id);
      } else if (existingIndex >= 0) {
        // Update the existing object
        const updatedItem = {
          ...prevValues[existingIndex],
          probability: newValue,
        };
        return [
          ...prevValues.slice(0, existingIndex),
          updatedItem,
          ...prevValues.slice(existingIndex + 1),
        ];
      } else {
        // Add a new object
        return [...prevValues, { rewardName, id, probability: newValue }];
      }
    });
  };
  console.log(
    updatedValues,
    updatedValuesSilver,
    updatedValuesGold,
    "updatedValues"
  );

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const [activeTab1, setActiveTab1] = useState("link-1");

  const handleSelect1 = (eventKey) => {
    setActiveTab1(eventKey);
  };

  const getProbability = () => {
    setOpen(true);
    axios
      .get(
        Environment.backendUrl +
          `/api1/admin/get/mysterypackProbability?type=${activeTab}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setOpen(false);
        setMyFiles(response?.data);
        console.log(response?.data, "what");
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {});
      });
  };

  useEffect(() => {
    getProbability();
  }, [activeTab]);

  const editProbability = async (e) => {
    if (
      updatedValues.length > 0 ||
      updatedValuesSilver.length > 0 ||
      updatedValuesGold.length > 0
    ) {
      e.preventDefault();
      const config = {
        method: "post",
        url: `${Environment.backendUrl}/api1/admin/edit/mysterypackProbability`,
        data: {
          type:
            updatedValues.length > 0
              ? "Bronze"
              : updatedValuesSilver.length > 0
              ? "Silver"
              : "Gold",
          data:
            updatedValues.length > 0
              ? updatedValues
              : updatedValuesSilver.length > 0
              ? updatedValuesSilver
              : updatedValuesGold,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await axios(config)
        .then((res) => {
          toast.success("Probability % updated successfully!");
          window.location.reload();

          //   toast.success(res?.data?.msg);
          // window.location.reload();
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.msg);
          }
        });
    }
  };

  return (
    <>
      <div className="content   forpositononly">
        <div className="onlyforbttns">
          <button
            onClick={editProbability}
            type="button"
            className="blueaddcategory px-4"
          >
            {" "}
            Update
          </button>
        </div>
        <div className="maintablea_leader">
          <div className="maintablepills">
            <Nav
              variant="pills"
              activeKey={activeTab}
              onSelect={handleSelect}
              className="amberpillsouter_leader"
            >
              <Nav.Item className="amberitempils">
                <Nav.Link className="ineramb" eventKey="Bronze">
                  Bronze
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="amberitempils">
                <Nav.Link className="ineramb" eventKey="Silver">
                  Silver
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="amberitempils">
                <Nav.Link className="ineramb" eventKey="Gold">
                  Gold
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          {activeTab === "Bronze" && (
            <>
              <div className="innertable  table-responsive">
                <table>
                  <thead>
                    <th>Reward Name</th>
                    <th>Probability</th>
                    <th> Date</th>
                  </thead>
                  <tbody>
                    {myFiles?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.rewardName}</td>
                            <td>
                              <div class="form-group onlyfortableinput">
                                <input
                                  min={0}
                                  type="number"
                                  name=""
                                  onChange={(e) =>
                                    handleChange(e, item?.rewardName, item?.id)
                                  }
                                  class="onlyfortableinput"
                                  placeholder={item?.probability}
                                />
                              </div>
                            </td>
                            <td>
                              {" "}
                              {moment(item?.createdAt).format("DD-MMM-YYYY")}
                            </td>
                            {/* <td>
                                                <span className="eleipiess">
                                                    187,254
                                                </span>
                                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {activeTab === "Silver" && (
            <>
              <div className="innertable  table-responsive">
                <table>
                  <thead>
                    <th>Reward Name</th>
                    <th>Probability</th>
                    <th> Date</th>
                  </thead>
                  <tbody>
                    {myFiles?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.rewardName}</td>
                            <td>
                              <div class="form-group onlyfortableinput">
                                {/* <input
                                  type="text"
                                  name=""
                                  pattern="[0-9]*"
                                  inputMode="numeric"
                                  onChange={(e) =>
                                    handleChangeSilver(
                                      e,
                                      item?.rewardName,
                                      item?.id
                                    )
                                  }
                                  class="onlyfortableinput"
                                  placeholder={item?.probability}
                                /> */}
                                <input
                                  type="number"
                                  min={0}
                                  name=""
                                  onChange={(e) =>
                                    handleChangeSilver(
                                      e,
                                      item?.rewardName,
                                      item?.id
                                    )
                                  }
                                  className="onlyfortableinput"
                                  placeholder={item?.probability}
                                />
                              </div>
                            </td>
                            <td>
                              {" "}
                              {moment(item?.createdAt).format("DD-MMM-YYYY")}
                            </td>
                            {/* <td>
                                                <span className="eleipiess">
                                                    187,254
                                                </span>
                                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {activeTab === "Gold" && (
            <>
              <div className="innertable  table-responsive">
                <table>
                  <thead>
                    <th>Reward Name</th>
                    <th>Probability</th>
                    <th> Date</th>
                  </thead>
                  <tbody>
                    {myFiles?.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{item?.rewardName}</td>
                            <td>
                              <div class="form-group onlyfortableinput">
                                <input
                                  type="number"
                                  min={0}
                                  name=""
                                  onChange={(e) =>
                                    handleChangeGold(
                                      e,
                                      item?.rewardName,
                                      item?.id
                                    )
                                  }
                                  class="onlyfortableinput"
                                  placeholder={item?.probability}
                                />
                              </div>
                            </td>
                            <td>
                              {" "}
                              {moment(item?.createdAt).format("DD-MMM-YYYY")}
                            </td>
                            {/* <td>
                                                <span className="eleipiess">
                                                    187,254
                                                </span>
                                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Probability;
