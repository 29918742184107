import React, { useRef, useState, useEffect } from "react";
import "./rewardlea.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/Environment";
// reactstrap components
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import { createLogicalAnd } from "typescript";
const AddTask = (props) => {
  const token = localStorage.getItem("mytoken");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [id, setId] = useState("");
  const [xBlue, setXblue] = useState([]);
  const [name, setName] = useState("");
  const [discription, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedItems2, setSelectedItems2] = useState("");
  const [selectedId2, setSelectedId2] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [instructions, setInstructions] = useState([{ instruction: '' }]);

  const [inputs, setInputs] = useState([{ instruction: '' }]);
  

  const handleAddInput = (event) => {
    event.preventDefault();
    setInputs([...inputs, { instruction: '' }]);
  };


  const handleDeleteInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };
  const handleInstructionChange = (index, event) => {
    const newInstructions = [...instructions];
    newInstructions[index].instruction = event.target.value;
    setInstructions(newInstructions);
  };
  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index].instruction = event.target.value;
    setInputs(newInputs);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getTaskDetail(id);
    }
  }, [id]);

  const getTaskDetail = (id) => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + `/api1/admin/get/taskById?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setDescription(response.data?.description);
        setName(response.data?.name);
        setBaseImage(response.data?.image);
        setSelectedId(response.data?.group_id);
        setSelectedId2(response.data?.Xblue_id);
        setSelectedItems2(response.data?.XBlueReward?.description);
        setSelectedItems(response.data?.TaskGroup?.name);
        setLink(response.data?.link);
        setType(response.data?.taskType);
        setIsChecked(response.data?.isLinkRequired);
        const instructionsArray = JSON.parse(response.data?.instructions);
        setInstructions(instructionsArray);

      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const editGroup = async (e) => {
    // validateUserName();
    // if (!errors) {
    e.preventDefault();

    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (discription) {
      formData.append("description", discription);
    }

    if (image) {
      formData.append("image", image);
    }
    if (selectedId) {
      formData.append("group_id", selectedId);
    }
    if (selectedId2) {
      formData.append("Xblue_id", selectedId2);
    }
    if (type) {
      formData.append("taskType", type);
    }
    if (link) {
      formData.append("link", link);
    }
    if (isChecked || !isChecked) {
      formData.append("isLinkRequired", isChecked);
    } if (instructions) {
      formData.append("instructions", JSON.stringify(instructions));
    }
    if (id) {
      formData.append("id", id);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/edit/task",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/task");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };

  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getTask = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/get/groups?limit=1000&offset=0", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setMyFiles(response.data?.groups);

      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  const getXblue = () => {
    setOpen(true);
    axios
      .get(Environment.backendUrl + "/api1/admin/get/AllXblueDropdown", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpen(false);
        setXblue(response.data?.data);
      })
      .catch((err) => {
        setOpen(false);
        toast.error(err.response?.data.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getTask();
    getXblue();
  }, []);

  const addTask = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !discription ||
      !image ||
      !selectedId ||
      !selectedId2 ||
      !inputs ||
      !link
    ) {
      // Show a toast message if any field is empty
      toast.error("Please fill in all required fields");
      return;
    }
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (discription) {
      formData.append("description", discription);
    }

    if (image) {
      formData.append("image", image);
    }
    if (selectedId) {
      formData.append("group_id", selectedId);
    }
    if (selectedId2) {
      formData.append("Xblue_id", selectedId2);
    }
    if (type) {
      formData.append("taskType", type);
    }
    if (link) {
      formData.append("link", link);
    }
    if (inputs) {
      formData.append("instructions", JSON.stringify(inputs));
    }
    if (isChecked || !isChecked) {
      formData.append("isLinkRequired", isChecked);
    }
    const config = {
      method: "post",
      url: Environment.backendUrl + "/api1/admin/add/task",
      data: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios(config)
      .then((res) => {
        toast.success(res?.data?.msg);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        history.push("/admin/task");
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else if (err?.response?.statusCode == 413) {
          toast.error("Image size to large");
        } else {
        

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  return (
    <>
      {/* <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop> */}
      <div className="content">
        <section className="addtask card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-8 order-md-0 order-1 ">
                  <div className="row">
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Task Title</label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          name="name"
                          className="form-control myinputdivr"
                          placeholder="Enter title of the reward"
                        />
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                         ("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Task Description
                        </label>
                        <textarea
                          value={discription}
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                          className="form-control myinputdivr"
                          placeholder="Enter description of the reward"
                          rows="5"
                        ></textarea>
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                         ("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group">
                        <label for="example">Link</label>
                        <input
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                          type="text"
                          name="name"
                          className="form-control myinputdivr"
                          placeholder="link"
                        />
                        {/* {Object.keys(projectNameError).map((key)=>{
                                                         ("key",key);
                                                         return <p className="inputErrors">{projectNameError[key]}</p>
                                                          })} */}
                      </div>
                    </div>
                    <div className=" col-12 ">
                      <div class="form-group ">
                        <label className="padd-top mytab1" for="example">
                          Choose Group
                        </label>
                        <div class="dropdown">
                          <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55">
                                {
                                  selectedItems ? <><h6 style={{ color: "black", fontWeight: '400' }}>{selectedItems}</h6></> : "Select Group"}
                              </p>
                              <i class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                                  <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {myFiles?.map((item, index) => {
                                return (
                                  <>
                                    <a
                                      key={index}
                                      onClick={() => {
                                        setSelectedItems(item.name);
                                        setSelectedId(item.id);
                                      }}
                                      className="dropdown-item taskdiv minerdivvvv"
                                    >
                                      {item?.name}
                                    </a>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group ">
                        <label className="padd-top mytab1" for="example">
                          Choose xBlue Reward
                        </label>
                        <div class="dropdown">
                          <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55">
                                {
                                  selectedItems2 ? <><h6 style={{ color: "black", fontWeight: '400' }}>{selectedItems2}</h6></> : "Select xBlue Reward"}

                              </p>
                              <i class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                                  <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {xBlue?.map((item, index) => {
                                return (
                                  <>
                                    <a
                                      onClick={() => {
                                        setSelectedItems2(item.description);
                                        setSelectedId2(item.id);
                                      }}
                                      className="dropdown-item taskdiv "
                                    >
                                      {item?.description}
                                    </a>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label className="padd-top" for="example">
                          Tasks{" "}
                        </label>
                        <div className="dropdown buttons-list-all cshbshjbvch">
                          <button
                            className="button-listing"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {
                              type ? <><h6 style={{ color: "black", fontWeight: '400' }}>{type}</h6></> : "Select Type"}
                            {/* <p className="manddiv">
                              {type ? type : "Select Type"}
                            </p> */}
                            <i class="">
                              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                                <path d="M8.33779 0.428711H4.52369H1.08915C0.501425 0.428711 0.207561 1.20931 0.623868 1.6669L3.79515 5.15267C4.30329 5.7112 5.12978 5.7112 5.63792 5.15267L6.84399 3.827L8.8092 1.6669C9.21939 1.20931 8.92552 0.428711 8.33779 0.428711Z" fill="#0F7EFE" />
                              </svg>
                            </i>
                          </button>

                          <div
                            className="dropdown-menu zscscsac"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              onClick={() => {
                                setType("MainTask");
                              }}
                              className="dropdown-item taskdiv mainydiskkk"
                            >
                              Main Task
                            </a>
                            <a
                              onClick={() => {
                                setType("DailyTask");
                              }}
                              className="dropdown-item taskdiv mainydiskkk"
                            >
                              Daily Task
                            </a>
                            {/* <a className="dropdown-item taskdiv">Invite Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group </a>
                              <a className="dropdown-item taskdiv">legion Group</a>
                              <a className="dropdown-item taskdiv">Instagram Group</a>
                              <a className="dropdown-item taskdiv">Submit Group</a> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          {id ? <div>

                            {inputs.map((input, index) => (
                              <div key={index} className="form-group">
                                {/* <label>Instructions</label> */}
                                {/* <input
                                  type="text"
                                  className="form-control myinputdivr"
                                  placeholder="Write task instructions"
                                  value={input}
                                  onChange={(event) => handleInputChange(index, event)}
                                /> */}
                                {instructions.map((instructionObj, index) => (
                                  <div key={index} className="form-group">
                                    <label>Instruction {index + 1}</label>
                                    <input
                                      type="text"
                                      className="form-control myinputdivr"
                                      placeholder={`Write task instruction ${index + 1}`}
                                      value={instructionObj.instruction} // Populate value from state
                                      onChange={(event) => handleInstructionChange(index, event)}
                                    />
                                  </div>
                                ))}
                              

                                {/* {index > 0 && (
                                  <button
                                    className="addddbuttton2 mt-2"
                                    onClick={() => handleDeleteInput(index)}
                                    type="button" // Ensure button type is 'button' to prevent form submission
                                  >
                                    Delete
                                  </button>
                                )} */}
                              </div>
                            ))}
                            {/* <div className="butttmainparent">
                              <button className="addddbuttton" onClick={handleAddInput}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                  <path d="M8.25016 14.6663C11.9168 14.6663 14.9168 11.6663 14.9168 7.99967C14.9168 4.33301 11.9168 1.33301 8.25016 1.33301C4.5835 1.33301 1.5835 4.33301 1.5835 7.99967C1.5835 11.6663 4.5835 14.6663 8.25016 14.6663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M5.5835 8H10.9168" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M8.25 10.6663V5.33301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Add
                              </button>
                            </div> */}
                          </div> : <div>

                            {inputs.map((input, index) => (
                              <div key={index} className="form-group">
                                <label>Instructions</label>
                                <input
                                  type="text"
                                  className="form-control myinputdivr"
                                  placeholder="Write task instructions"
                                  value={input.instruction}
                                  onChange={(event) => handleInputChange(index, event)}
                                />
                                {index > 0 && (
                                  <button
                                    className="addddbuttton2 mt-2"
                                    onClick={() => handleDeleteInput(index)}
                                    type="button" // Ensure button type is 'button' to prevent form submission
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            ))}
                            <div className="butttmainparent">
                              <button className="addddbuttton" onClick={handleAddInput}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                  <path d="M8.25016 14.6663C11.9168 14.6663 14.9168 11.6663 14.9168 7.99967C14.9168 4.33301 11.9168 1.33301 8.25016 1.33301C4.5835 1.33301 1.5835 4.33301 1.5835 7.99967C1.5835 11.6663 4.5835 14.6663 8.25016 14.6663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M5.5835 8H10.9168" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M8.25 10.6663V5.33301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Add
                              </button>
                            </div>
                          </div>}



                        </div>

                      </div>
                      {/* <div class="form-group nyyyyydea">
                        <label for="example">Instructions</label>
                        <input
                          value=""
                          type="text"
                          name="name"
                          className="form-control myinputdivr"
                          placeholder="Write task instructions"
                        />
                       
                      </div>
                      <div className="butttmainparent">
                        <button className="addddbuttton"> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M8.25016 14.6663C11.9168 14.6663 14.9168 11.6663 14.9168 7.99967C14.9168 4.33301 11.9168 1.33301 8.25016 1.33301C4.5835 1.33301 1.5835 4.33301 1.5835 7.99967C1.5835 11.6663 4.5835 14.6663 8.25016 14.6663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5.5835 8H10.9168" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.25 10.6663V5.33301" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>Add </button>
                        <button className="addddbuttton2">Delete </button>
                      </div> */}
                      <div className="headofswitchhh">
                        <p className="linktext">Required Link</p>
                        <div className="main-switch">
                          <div class="custom-control custom-switch myswitchhhhh ">
                            <input
                              type="checkbox"
                              class="custom-control-input "
                              id="customSwitches"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              class="custom-control-label "
                              for="customSwitches"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="radioouterdiv">
                                            <div className="col-4 ">
                                                <div class="form-group">
                                                    <div className="main-switch">
                                                        <label class="switch">
                                                            <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                                            <span class="slider round">  <h5>Featured Task</h5></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 ">
                                                <div class="form-group">
                                                    <div className="main-switch">
                                                        <label class="switch">
                                                            <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                                            <span class="slider round">  <h5>Require Proof</h5></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                  <div className="col-12">
                    <div class="form-group">
                      <div className="videopage">
                        <button
                          onClick={id ? (e) => editGroup(e) : (e) => addTask(e)}
                          className="red-bybuy"
                        >
                          {id ? "Update" : "Publish"}
                        </button>
                        <Link to="/admin/task">
                          <button className="red-wy-y">Cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 order-md-1 order-0 ">
                  <div className="upload-img  mypointttt">
                    <label for="exampleInputsymbol " className="manydivbart">
                      {" "}
                      Upload Task Image
                    </label>
                    <p className="imgsize">Image size: 381x138</p>
                    <div className="upload sidedotsaa ">
                      <img src="" className="imgProfile" alt="" />
                      <div>
                        <label htmlFor="upload">
                          {baseImage ? (
                            <img
                              src={baseImage}
                              alt="img"
                              className="img-fluid dragimg"
                            />
                          ) : (
                            <>
                              <img
                                src="\dashboard-assets\upload.svg"
                                alt="img"
                                className="img-fluid dragimg"
                              />
                              <p className="paraa1 mypointttt">
                                Drag & drop or{" "}
                                <span className="paraa2"> Browse</span>
                              </p>
                            </>
                          )}
                        </label>
                      </div>
                    </div>

                    {/* <p className="text-danger">Image is missing!</p> */}
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="upload"
                      onChange={uploadImages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default AddTask;
