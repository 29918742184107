import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./orders.scss";
import axios from "axios";
import { toast } from "react-toastify";
import Environment from "utils/Environment";
import { Pagination } from "react-bootstrap";
const Orders = () => {
  return (
    <>
      <div className="content">
        <div className="divparenttt">
          <div className="divfirst">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M9.46 3H5.20667C3.98796 3 3 3.98796 3 5.20667V9.46C3 10.6787 3.98796 11.6667 5.20667 11.6667H9.46C10.6787 11.6667 11.6667 10.6787 11.6667 9.46V5.20667C11.6667 3.98796 10.6787 3 9.46 3Z"
                fill="#F04914"
              />
              <path
                d="M20.793 3H16.5397C15.321 3 14.333 3.98796 14.333 5.20667V9.46C14.333 10.6787 15.321 11.6667 16.5397 11.6667H20.793C22.0117 11.6667 22.9997 10.6787 22.9997 9.46V5.20667C22.9997 3.98796 22.0117 3 20.793 3Z"
                fill="#F04914"
              />
              <path
                d="M9.46 14.333H5.20667C3.98796 14.333 3 15.321 3 16.5397V20.793C3 22.0117 3.98796 22.9997 5.20667 22.9997H9.46C10.6787 22.9997 11.6667 22.0117 11.6667 20.793V16.5397C11.6667 15.321 10.6787 14.333 9.46 14.333Z"
                fill="#F04914"
              />
              <path
                d="M18.6663 22.9997C21.0596 22.9997 22.9997 21.0596 22.9997 18.6663C22.9997 16.2731 21.0596 14.333 18.6663 14.333C16.2731 14.333 14.333 16.2731 14.333 18.6663C14.333 21.0596 16.2731 22.9997 18.6663 22.9997Z"
                fill="#F04914"
              />
            </svg>
            <p className="totaldiv">Total item purchased</p>
            <p className="totaldiv1">178K+</p>
          </div>
          <div className="divfirst">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M9.46 3H5.20667C3.98796 3 3 3.98796 3 5.20667V9.46C3 10.6787 3.98796 11.6667 5.20667 11.6667H9.46C10.6787 11.6667 11.6667 10.6787 11.6667 9.46V5.20667C11.6667 3.98796 10.6787 3 9.46 3Z"
                fill="#F04914"
              />
              <path
                d="M20.793 3H16.5397C15.321 3 14.333 3.98796 14.333 5.20667V9.46C14.333 10.6787 15.321 11.6667 16.5397 11.6667H20.793C22.0117 11.6667 22.9997 10.6787 22.9997 9.46V5.20667C22.9997 3.98796 22.0117 3 20.793 3Z"
                fill="#F04914"
              />
              <path
                d="M9.46 14.333H5.20667C3.98796 14.333 3 15.321 3 16.5397V20.793C3 22.0117 3.98796 22.9997 5.20667 22.9997H9.46C10.6787 22.9997 11.6667 22.0117 11.6667 20.793V16.5397C11.6667 15.321 10.6787 14.333 9.46 14.333Z"
                fill="#F04914"
              />
              <path
                d="M18.6663 22.9997C21.0596 22.9997 22.9997 21.0596 22.9997 18.6663C22.9997 16.2731 21.0596 14.333 18.6663 14.333C16.2731 14.333 14.333 16.2731 14.333 18.6663C14.333 21.0596 16.2731 22.9997 18.6663 22.9997Z"
                fill="#F04914"
              />
            </svg>
            <p className="totaldiv">Total item purchased</p>
            <p className="totaldiv1">178K+</p>
          </div>
          <div className="divfirst">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M9.46 3H5.20667C3.98796 3 3 3.98796 3 5.20667V9.46C3 10.6787 3.98796 11.6667 5.20667 11.6667H9.46C10.6787 11.6667 11.6667 10.6787 11.6667 9.46V5.20667C11.6667 3.98796 10.6787 3 9.46 3Z"
                fill="#F04914"
              />
              <path
                d="M20.793 3H16.5397C15.321 3 14.333 3.98796 14.333 5.20667V9.46C14.333 10.6787 15.321 11.6667 16.5397 11.6667H20.793C22.0117 11.6667 22.9997 10.6787 22.9997 9.46V5.20667C22.9997 3.98796 22.0117 3 20.793 3Z"
                fill="#F04914"
              />
              <path
                d="M9.46 14.333H5.20667C3.98796 14.333 3 15.321 3 16.5397V20.793C3 22.0117 3.98796 22.9997 5.20667 22.9997H9.46C10.6787 22.9997 11.6667 22.0117 11.6667 20.793V16.5397C11.6667 15.321 10.6787 14.333 9.46 14.333Z"
                fill="#F04914"
              />
              <path
                d="M18.6663 22.9997C21.0596 22.9997 22.9997 21.0596 22.9997 18.6663C22.9997 16.2731 21.0596 14.333 18.6663 14.333C16.2731 14.333 14.333 16.2731 14.333 18.6663C14.333 21.0596 16.2731 22.9997 18.6663 22.9997Z"
                fill="#F04914"
              />
            </svg>
            <p className="totaldiv">Total item purchased</p>
            <p className="totaldiv1">178K+</p>
          </div>
        </div>
        <section className="products">
          <div className="productstable">
            <table>
              <thead>
                <th>Product Name</th>
                <th>
                  Price{" "}
                  <img
                    src="\dashboard-assets\Arrow - Down 3.svg"
                    alt="arrow"
                    className="arrowdown"
                  />
                </th>
                <th>User </th>
                <th>Buyer Wallet</th>
                <th>Date Order</th>
                <th>Wallet ID</th>
                <th>Status</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="outtttterdiv">
                      <img
                        src="\dashboard-assets\boxxx.png"
                        alt="innerimg"
                        className="innerimg"
                      />

                      <p className="warrri">Rare Box</p>
                    </div>
                  </td>
                  <td className="warrri">xBlue and USDT</td>
                  <td className="warrri">John Doe</td>
                  <td className="warrri">0x896DFB29...3E1</td>
                  <td className="warrri">14-Nov-2023</td>
                  <td className="warrri">589746</td>
                  <td>
                  <div class="dropdown droped">
                      <button
                        class="dropbtn dropdown-toggle pndingggg"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Pending{" "}
                      </button>
                      <div class="dropdown-menu vvvvv">
                        <a class="dropdown-item dropmain" href="#">
                          Processing
                        </a>
                        <a class="dropdown-item dropmain" href="#">
                          Completed
                        </a>
                      </div>
                    </div>
                    {/* <div class="dropdown droped">
                      <button
                        class="dropbtn dropdown-toggle pndingggg"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >Pending</button>
                      <div class="dropdown-menu dropmain">
                        <p className="dropdown-item drop">Processing</p>
                      </div>
                      <div class="dropdown-menu dropmain">
                        <p className="dropdown-item drop">Completed</p>
                      </div>
                    </div> */}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="Paginationlattable">
              <button className="leftpigbtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.8332 10H4.99987M9.16654 5L4.7558 9.41074C4.43036 9.73618 4.43036 10.2638 4.7558 10.5893L9.16654 15"
                    stroke="#5F6D7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                Prev
              </button>
              <Pagination>
                <Pagination.Item className="warrrrru">{1}</Pagination.Item>
                <Pagination.Item className="warrrrru">{2}</Pagination.Item>
                <Pagination.Ellipsis className="warrrrru" />
                <Pagination.Item active>{5}</Pagination.Item>
                <Pagination.Item className="warrrrru">{6}</Pagination.Item>
              </Pagination>
              <button className="leftpigbtn">
                Next
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.16797 10H15.0013M10.8346 5L15.2454 9.41074C15.5708 9.73618 15.5708 10.2638 15.2454 10.5893L10.8346 15"
                    stroke="#5F6D7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Orders;
