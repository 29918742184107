import React, { useRef, useState, useEffect } from "react";
// import './rewardlea.scss';

import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import { Link } from "react-router-dom";

const Editmilestone = (props) => {


  return (
    <>

  
<div className="content">
        <section className="addtask card">
          <form>
            <div className="inner-submit-lower-div">
              <div class="row">
                <div class="col-md-8 order-md-0 order-1 ">
                  <div className="row">

                    <div className="col-12 ">
                      <div class="form-group">
                        <label className="padd-top" for="example">Description</label>
                        <textarea name="description" className='form-control myinputdivr' placeholder="Enter the answer" rows="5"></textarea>

                      </div>


                      <div class="form-group ">
                        <label className="padd-top mytab1" for="example">Type</label>
                        <div class="dropdown">
                          <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55">Select Group</p>
                              <i class="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="6"
                                  viewBox="0 0 9 6"
                                  fill="none"
                                >
                                  <path
                                    d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                    fill="#3654D6"
                                  />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a className="dropdown-item taskdiv">
                                Tasks Page
                              </a>
                              <a className="dropdown-item taskdiv">Invite</a>
                              <a className="dropdown-item taskdiv">Product</a>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group ">
                        <label className="padd-top mytab1" for="example">Product</label>
                        <div class="dropdown">
                          <div className="dropdown buttons-list-all cshbshjbvch">
                            <button
                              className="button-listing"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <p className="para55">Select Product</p>
                              <i class="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="6"
                                  viewBox="0 0 9 6"
                                  fill="none"
                                >
                                  <path
                                    d="M8.33877 0.428711H4.52466H1.09013C0.502402 0.428711 0.208538 1.20931 0.624845 1.6669L3.79613 5.15267C4.30427 5.7112 5.13076 5.7112 5.6389 5.15267L6.84496 3.827L8.81018 1.6669C9.22036 1.20931 8.9265 0.428711 8.33877 0.428711Z"
                                    fill="#3654D6"
                                  />
                                </svg>
                              </i>
                            </button>

                            <div
                              className="dropdown-menu zscscsac"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a className="dropdown-item taskdiv ">
                                Select Product
                              </a>
                              <a className="dropdown-item taskdiv">Avatar</a>
                              <a className="dropdown-item taskdiv">Metaverse</a>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="example">Milestone</label>
                        <input type="text" name="name" className='form-control myinputdivr' placeholder="20" />

                      </div>
                    </div>



                    <div className="radioouterdiv">
                      <div className="col-4 ">
                        <div class="form-group">
                          <div className="main-switch">
                            <label class="switch">
                              <input type="checkbox" class="custom-control-input" id="customSwitches" />
                              <span class="slider round">  <h5>Active/ Inactive</h5></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 ">
                        {/* <div class="form-group">
                          <div className="main-switch">
                            <label class="switch">
                              <input type="checkbox" class="custom-control-input" id="customSwitches" />
                              <span class="slider round">  <h5>Require Proof</h5></span>
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div class="form-group">
                      <div className="videopage">
                        <button className="red-bybuy">Save</button>
                        <Link to="/admin/milestone">
                          <button className="red-wy-y">Cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-4 order-md-1 order-0">
                  <div className="upload-img ">
                    <label for="exampleInputsymbol " className="manydivbart"> Upload Task Image</label>
                    <p className="imgsize">Image size: 381x138</p>
                    <div className="upload sidedotsaa ">
                      <img src="" className="imgProfile" alt="" />
                      <label htmlFor="upload">
                        <img
                          src="\dashboard-assets\upload.svg"
                          alt="img"
                          className="img-fluid dragimg"
                        />
                        <p className="paraa1">Drag & drop or <span className="paraa2"> Browse</span></p>
                      </label>
                    </div>

           
                    <input
                      type="file"
                      name="image"
                      className="d-none"
                      id="upload"
                    />
                  </div>

                </div> */}
              </div>
            </div>
          </form>
        </section>

      </div>
    </>
  );
}

export default Editmilestone;
